import React, { useState, useEffect, useRef } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCard,
  MDBIcon,
  MDBBtn,
  MDBSwitch,
  MDBSpinner,
  MDBInput,
  MDBContainer,
  MDBModalFooter,
  MDBCollapse,
} from 'mdbreact';
import InputMask from 'react-input-mask';
import { getPermission } from '../../../services/auth';
import { toast } from 'react-toastify';
import CsvDownloader from 'react-csv-downloader';
import api from '../../../services/api';
import { getData } from '../../../services/auth';
import NavBar from '../../../components/NavBar';
import Select from 'react-select';
import { format } from 'date-fns';
import { Line } from './styles';
// Fazer roteamento com history (assumindo que você esteja usando react-router-dom)
import { useHistory, Link } from 'react-router-dom';

const AddUsuario = () => {
  const idReq = getData();
  // const user_id =  props.match.params.vtrid
  const [loading, setLoading] = useState(false);
  const [collapseID, setCollapseID] = useState(false);
  const [searchCollapseID, setSearchCollapseID] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [levelAccess, setLevelAccess] = useState('');
  const [sort, setSort] = useState({ value: 'program', label: 'Program' });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });
  const [quantity, setQuantity] = useState('20');
  const [paramsDownload, setParamsDownload] = useState({});
  const [responseDownload, setResponseDownload] = useState([]);
  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  const [modalResetSenha,setModalResetSenha] = useState(false)
  const [dadosModal, setDadosModal] = useState({});
  const [editDadosModal, setEditDadosModal] = useState({});
  const [page, setPage] = useState('1');
  const [rows, setRows] = useState('');
  const [dataUsers, setDataUsers] = useState([]);

  const [qtdPage, setQtdPage] = useState(1);
  const [typeDownload, setTypeDownload] = useState();

  const [listProfile, setListProfile] = useState([]);
  const [selectProfile, setSelectProfile] = useState(null);
  const [currentProfile, setCurrentProfile] = useState(null);

  const [listPrograms, setListPrograms] = useState([]);
  const [selectProgram, setSelectProgram] = useState(null);

  const [listAccess, setListAccess] = useState([]);
  const [modalEditUser, setModalEditUser] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [listSelectUsers, setListSelectUsers] = useState([]);

  const [selectAddListInventory,setSelectAddListInventory]=useState([])
  const [selectedAddInventory,setSelectAddInventory]=useState(null)
  const [selectedListInventory,setSelectedListInventory]=useState([])

  const history = useHistory();
  const selectDataUsers = useRef([]);
  const searchCollapse = () => {
    setSearchCollapseID(!searchCollapseID);

    setDadosModal({});
  };

  const carregaSelectInventory = async () => {
    const response = await api.get(`/inventory-supplier`)
    const select = []

    response.data.rows.forEach(res => {
      select.push({label: res.supplier, value:res.id})
    })

    setSelectAddListInventory(select)
  }

  const resetPassword = async () => {
    try {
    
      const response = await api.put(`/reset-password/${modalEditUser.id}`);
      
      if (response.data.ok) {
        toast.success(response.data.ok);
        setModalResetSenha(false)
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error('Error in resetPassword:', error);
      toast.error('The password could not be changed!');
    }
  };


  const insertAddSupplier = async () => {
   
    if (selectedAddInventory === null) {
      toast.error('Select Empty');
      return; // Não insere o item na lista
    }

    // Verifica se o item já existe na lista
    const isDuplicate = selectedListInventory.some(
      (item) => item.id === selectedListInventory.value
    );

    // Se o item já existe, exibe uma mensagem no console
    if (isDuplicate) {
      toast.error('Duplicate');
      return; // Não insere o item na lista
    }

    // Se o item não existe na lista, insere-o
    setSelectedListInventory([
      ...selectedListInventory,
      { id: selectedAddInventory.value, supplier: selectedAddInventory.label },
    ]);

    // Limpa os campos após a inserção

    setSelectAddInventory(null);
  }

  const enviaDados = async (e) => {
  
    if (!email || !firstName || !lastName || !phone ) {
      toast.error('Fill in the fields');
      return;
    }
    if (selectProfile === null) {
      toast.error('Select Profile');
      return;
    }

    if (
      selectProfile.label === 'Admin' ||
      selectProfile.label === 'Viewer' ||
      selectProfile.label === 'Super Admin'
    ) {
    } else {
      if (listAccess.length === 0 && listSelectUsers.length === 0) {
        toast.error('Fill in the access list');
        return;
      }
    }

    try {
      const result = await api.post('/user', {
        email,
        firstName,
        lastName,
        phone,
        password: email,
        profile: selectProfile.value,
        listAccess,
        listSelectUsers,
        // suppliers:selectedListInventory,
      });
      if (result.data.ok) {
        setEmail('');
        setFirstName('');
        setLastName('');
        setPhone('');
        setPassword('');
        setSelectProfile(null);
        setListAccess([]);
        setSelectedListInventory([]);
        setListSelectUsers([]);
        setSelectedListInventory([]);
        toast.success(result.data.ok);
        list()
      }
      result.data.error && toast.error(result.data.error);

      // Redirect or handle success
    } catch (error) {
      console.log(error)
      toast.error('Error');
    }
  };

  const alteraDados = async (e) => {
    if (
      modalEditUser.email === '' ||
      modalEditUser.firstName === '' ||
      modalEditUser.lastName === '' ||
      modalEditUser.phone === ''
    ) {
      toast.error('Fill in the fields');
      return;
    }
    if (selectProfile === null) {
      toast.error('Select Profile');
      return;
    }

    if (
      selectProfile.label === 'Admin' ||
      selectProfile.label === 'Viewer' ||
      selectProfile.label === 'Super Admin'
    ) {

    } else {
      if (listAccess.length === 0 && listSelectUsers.length === 0 && selectedListInventory.length === 0 ) {
        toast.error('Fill in the access list');
        return;
      }
      // if (selectedListInventory.length === 0) {
      //   toast.error('Fill in the supplier list');
      //   return;
      // }
    }
    try {
      const result = await api.put(`/user/${modalEditUser.id}`, {
        ...modalEditUser,
        profile: selectProfile.value,
        listAccess,
        listSelectUsers,
        // suppliers:selectedListInventory
      });
      if (result.data.ok) {
        setModalEditUser(null);
        setSelectProfile(null);
        setListAccess([]);
        setListSelectUsers([]);
        setSelectedListInventory([])
        list(1);
        toast.success(result.data.ok);
      }
      result.data.error && toast.error(result.data.error);

      // Redirect or handle success
    } catch (error) {
      toast.error('Error');
    }
  };

  const carregaNewUsers = async () => {
    const result = await api.get('/profile');
    const select = [];
    result.data.forEach((pro) => {
      select.push({ value: pro.id, label: pro.name });
    });
    setListProfile(select);
  };

  const carregaPrograms = async () => {
    const responseProgram = await api.get(`/customer?p=99999999`);

    const select = [];
    responseProgram.data.rows.forEach((pro) => {
      select.push({ label: pro.program, value: pro.id });
    });
    setListPrograms(select);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    setPage(nextP);
    list(nextP);
  };

  const list = async (npage = page) => {
    setLoading(true);
    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
    window.scrollTo(0, 0);

    try {
      const response = await api.get(`/user`, {
        params: {
          pag: npage,
          p: quantity,
          ...dadosModal,
          sort: sort.value,
          typeSort: typeSort.value,
          // branch_name: dadosModal.branch_name,
          // program: dadosModal.program,
          // customer_account_number: dadosModal.customer_account_number,
          // user_id: dadosModal.user_id,
          // customer_group_id: dadosModal.customer_group_id,
          // replan_days:dadosModal.replan_days,
        },
      });
   

      setDataUsers(response.data.rows);

      const select = [];
      response.data.rows.forEach((user) => {
        select.push({
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        });
      });

      selectDataUsers.current = select;

      setRows(response.data.count);
      setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
      setLoading(false);
      setParamsDownload({
        pag: npage,
        p: 999999999999999,
        ...dadosModal,
        sort: sort.value,
        typeSort: typeSort.value,
      });
      setEditDadosModal({});
      setTypeDownload('advanced');
      // setLoadDownload(response.data.rows);
    } catch (error) {
      console.error(error);
      toast.error('Erro Database');
    }
  };

  const insertProgram = () => {
    if (selectProgram === null) {
      toast.error('Select Empty');
      return; // Não insere o item na lista
    }

    // Verifica se o item já existe na lista
    const isDuplicate = listAccess.some(
      (item) => item.id === selectProgram.value
    );

    // Se o item já existe, exibe uma mensagem no console
    if (isDuplicate) {
      toast.error('Duplicate');
      return; // Não insere o item na lista
    }

    // Se o item não existe na lista, insere-o
    setListAccess([
      ...listAccess,
      { id: selectProgram.value, program: selectProgram.label },
    ]);

    // Limpa os campos após a inserção

    setSelectProgram(null);
  };

  const insertUsers = () => {
    if (selectedUser === null) {
      toast.error('Select Empty');
      return; // Não insere o item na lista
    }

    // Verifica se o item já existe na lista
    const isDuplicate = listSelectUsers.some(
      (item) => item.id === selectedUser.value
    );

    // Se o item já existe, exibe uma mensagem no console
    if (isDuplicate) {
      toast.error('Duplicate');
      return; // Não insere o item na lista
    }

    // Se o item não existe na lista, insere-o
    setListSelectUsers([
      ...listSelectUsers,
      { id: selectedUser.value, name: selectedUser.label },
    ]);

    // Limpa os campos após a inserção

    setSelectedUser(null);
  };

  function deleteProgram(valueToRemove) {
    setListAccess(listAccess.filter((item) => item.id !== valueToRemove.id));
  }
  
  function deleteAddInventory(valueToRemove) {
    setSelectedListInventory(selectedListInventory.filter((item) => item.id !== valueToRemove.id));
  }

  function deleteListSelectUser(valueToRemove) {
    setListSelectUsers(
      listSelectUsers.filter((item) => item.id !== valueToRemove.id)
    );
  }

  const buscaProfileUser = async (user_id) => {
    const result = await api.get(`/profile_user/${user_id}`);

    selectDataUsers.current = selectDataUsers.current.filter(
      (item) => item.value !== user_id
    );
   
    setListAccess(result.data.programs);
    let profile = null;
    if (result.data.profile !== null) {
      const select = [];
      result.data.profile.forEach((res) => {
        if (res.manager_id === user_id) {
          select.push({
            name: `${res.user.first_name} ${res.user.last_name}`,
            id: res.user.id,
          });
        }
        if (res.user_id === user_id) {
          profile = { value: res.TB_profile.id, label: res.TB_profile.name };
        }
      });

      setListSelectUsers(select);
      setSelectProfile(profile);
    }
  };

  const buscaInventoryUser = async (user_id) => {
    const result = await api.get(`/inventory_user/${user_id}`);
    const suppliers =[]
    result.data.length > 0 &&
    result.data.forEach(sup => {
      suppliers.push({id:sup.TB_inventory_supplier.id,supplier:sup.TB_inventory_supplier.supplier})
    })
    
    setSelectedListInventory(suppliers )
  };

  const insertSelectUser = () => {
    const select = [];
    dataUsers.forEach((user) => {
      select.push({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      });
    });
    return select;
  };

  useEffect(() => {
    if (collapseID !== false) {
      carregaNewUsers();
      carregaPrograms();
      carregaSelectInventory()
    }
  }, [collapseID]);

  useEffect(() => {
    setListAccess([]);
    if (modalEditUser !== null) {
      carregaNewUsers();
      carregaPrograms();
      carregaSelectInventory()

      buscaProfileUser(modalEditUser.id);
      buscaInventoryUser(modalEditUser.id)
    }
  }, [modalEditUser]);

  useEffect(() => {
    if (selectProfile === null) return;
    if (selectProfile.label === 'Rep') {
      setListSelectUsers([]);
      return;
    }

    if (selectProfile.label === 'Manager') {
      setListAccess([]);
      return;
    }
    setListSelectUsers([]);
    setListAccess([]);
    // se select profile for manager , precisa abrir lista de usuarios sem mostrar ele mesmo
    // se for viewer , admin ou super admin , nao precisa abrir nada
  }, [selectProfile]);

  useEffect(() => {
    list(1);
  }, [quantity, sort, typeSort]);

  return (
    <NavBar>
      {/* ADD BUTTON */}
      <MDBContainer fluid>
        <MDBRow between>
          <MDBCol middle className="h2 text-vmi">
            Users
            {getPermission('create_new_users') && (
              <div
                className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                size="sm"
                onClick={() => toggleCollapse()}
              >
                <span className="m-0 font-weight-bold text-white">
                  <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                </span>
              </div>
            )}
          </MDBCol>
          <MDBCol middle>
            <MDBRow>
              <MDBCol className="text-right" size="12">
                <Link
                  to="/app"
                  className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                  Back
                </Link>
              </MDBCol>
              <MDBCol
                size="12"
                className="d-flex d-md-none justify-content-end"
              >
                <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBCollapse isOpen={collapseID}>
          <div
            style={{
              borderRadius: '5px',
              backgroundColor: 'rgba(255,255,255,0.9)',
            }}
            className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center align-items-center"
          >
            <br />
            <MDBRow>
              <MDBCol size="12" md="3" middle>
                <MDBInput
                  label="Email"
                  type="email"
                  group
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBCol>
              <MDBCol size="12" md="2" middle>
                <MDBInput
                  label="First Name"
                  value={firstName}
                  group
                  size="lg"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </MDBCol>
              <MDBCol size="12" md="2" middle>
                <MDBInput
                  label="Last Name"
                  value={lastName}
                  group
                  size="lg"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </MDBCol>

              <MDBCol size="12" md="2" middle>
                <div className="d-flex align-items-center">
                  <InputMask
                    style={{
                      border: 0,
                      borderBottom: '1px solid #ced4da',
                      fontSize: '1.25rem',
                    }}
                    className="d-flex w-100 font-weight-light mt-3"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    mask="(99) 9999-99999"
                    maskChar=" "
                  />
                </div>
              </MDBCol>
              <MDBCol size="12" md="2" middle>
                {/* <MDBInput
                  type="password"
                  label="Password"
                  autoComplete="new-password"
                  value={password}
                  group
                  size="lg"
                  onChange={(e) => setPassword(e.target.value)}
                /> */}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol size="12" md="5">
                <MDBRow>
                  <MDBCol size="12" md="4" middle>
                    <Select
                      onChange={(e) => {
                        if (e === selectProfile) return;

                        setSelectProfile(e);
                      }}
                      placeholder="Perfil"
                      options={listProfile}
                      value={selectProfile}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="8" middle>
                    {selectProfile?.label === 'Manager' && (
                      <MDBRow>
                        <MDBCol className="p-0" middle size="12" md="8">
                          <Select
                            // isClearable
                            placeholder="Users"
                            onChange={(e) => setSelectedUser(e)}
                            options={selectDataUsers.current}
                            value={selectedUser}
                          />
                        </MDBCol>
                        <MDBCol className="p-0" size="12" md="4">
                          <div
                            className={`ml-3 btn btn-sm shadow-5  ${
                              searchCollapseID
                                ? 'btn-outline-grey grey-text'
                                : 'bg-vmi text-white'
                            }  `}
                            size="sm"
                            onClick={() => {
                              insertUsers();
                            }}
                          >
                            <span className="m-0 font-weight-bold ">
                              {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                              <MDBIcon icon="plus" size="2x" />
                            </span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    )}
                    {selectProfile?.label === 'Rep' && (
                      <MDBRow>
                        <MDBCol size="12" className="p-0" md="8" middle>
                          <Select
                            // isClearable
                            placeholder="Program"
                            onChange={(e) => setSelectProgram(e)}
                            options={listPrograms}
                            value={selectProgram}
                          />
                        </MDBCol>
                        <MDBCol size="12" md="4" className="p-0" middle>
                          <div
                            className={`ml-3 btn btn-sm shadow-5 ${
                              searchCollapseID
                                ? 'btn-outline-grey grey-text'
                                : 'bg-vmi text-white'
                            }  `}
                            size="sm"
                            onClick={() => {
                              insertProgram();
                            }}
                          >
                            <span className="m-0 font-weight-bold ">
                              {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                              <MDBIcon icon="plus" size="2x" />
                            </span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          

            <MDBRow>
              {listSelectUsers.length > 0 &&
                listSelectUsers.map((user) => (
                  <MDBCol middle size="6">
                    <MDBCard className="m-1 p-2 h5 ">
                      <MDBRow between>
                        <MDBCol size="9">{user?.name}</MDBCol>
                        <MDBCol size="3" middle className="text-right">
                          <MDBIcon
                            fas
                            icon="times"
                            onClick={(e) => {
                              deleteListSelectUser(user);
                            }}
                            className="cursor-pointer mr-2"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
            <MDBRow>
              {listAccess.length > 0 &&
                listAccess.map((access) => (
                  <MDBCol middle size="3">
                    <MDBCard className="m-1 p-2 h5 ">
                      <MDBRow between>
                        <MDBCol>{access?.program}</MDBCol>
                        <MDBCol className="text-right mr-2">
                          <MDBIcon
                            fas
                            icon="times"
                            onClick={(e) => {
                              deleteProgram(access);
                            }}
                            className="cursor-pointer"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
            

            {/* {selectProfile?.label === 'Rep' && (
                    <MDBRow className='mt-4'>
 <MDBCol size="12" md="4" middle>
                    <Select
                      onChange={(e) => {
                        if (e === selectedAddInventory) return;

                        setSelectAddInventory(e);
                      }}
                      placeholder="Inventory Supplier"
                      options={selectAddListInventory}
                      value={selectedAddInventory}
                    />
                  </MDBCol>
                  <MDBCol className="p-0" size="12" md="4">
                          <div
                            className={`ml-3 btn btn-sm shadow-5  ${
                              searchCollapseID
                                ? 'btn-outline-grey grey-text'
                                : 'bg-vmi text-white'
                            }  `}
                            size="sm"
                            onClick={() => {
                              insertAddSupplier();
                            }}
                          >
                            <span className="m-0 font-weight-bold ">
                            
                              <MDBIcon icon="plus" size="2x" />
                            </span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    )} */}
                 
               
                  <MDBRow>
              {/* {selectedListInventory.length > 0 &&
                selectedListInventory.map((access) => (
                  <MDBCol middle size="3">
                    <MDBCard className="m-1 p-2 h5 ">
                      <MDBRow between>
                        <MDBCol>{access?.supplier}</MDBCol>
                        <MDBCol className="text-right mr-2">
                          <MDBIcon
                            fas
                            icon="times"
                            onClick={(e) => {
                              deleteAddInventory(access);
                            }}
                            className="cursor-pointer"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                ))} */}
            </MDBRow>
            <MDBRow center>
              <div
                className={`ml-3 btn btn-sm shadow-5  bg-vmi text-white`}
                size="sm"
                onClick={() => enviaDados()}
              >
                <span className="m-0 font-weight-bold ">
                  {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                  <MDBIcon className="mr-1" icon="plus" /> Save
                </span>
              </div>
            </MDBRow>
          </div>
        </MDBCollapse>
      </MDBContainer>

      {/* SEARCH E SEARCH ADVANCED */}
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol className="d-flex align-items-center justify-content-center">
            <MDBInput
              className={`${
                searchCollapseID ? 'text-danger' : ''
              }   form-control ml-3`}
              disabled={searchCollapseID}
              // options={options}
              // placeholder="teste"
              type="text"
              placeholder={searchCollapseID ? 'Disabled' : 'Search'}
              // value={dadosModal.search || ''}

              onChange={(e) => setDadosModal({ first_name: e.target.value })}
            />

            <MDBIcon
              onClick={() => {
                // list com a pagina 1 NO BACKEND
                // simpleSearch(1);
                list(1);
                // // seta o valor da pagina NO FRONTEND
                // setPage('1');
              }}
              icon="search"
              size="2x"
              className="text-muted cursor-pointer ml-4"
            />
            {/* <div
              className={`ml-3 btn btn-sm shadow-5  ${searchCollapseID ? 'btn-outline-grey grey-text' : 'bg-vmi text-white'}  `}
              size="sm"

              onClick={() => searchCollapse()}
            >
              <span className="m-0 font-weight-bold ">

                Advanced Search<MDBIcon className="ml-3" icon="filter" />
              </span>
            </div> */}
          </MDBCol>
        </MDBRow>
        {/* SEARCH COMUNS   */}
      </MDBContainer>

      {/* HEAD FORM TO MD VISUALIZATION */}
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol size="12" md="6">
            {/* <MDBRow>
              <MDBCol size="12" md="4" className="my-1">
                <Select
                  placeholder="Select Sort"
                  options={[
                    { value: 'program', label: 'Program' },
                    {
                      value: 'customer_account_number',
                      label: 'Customer Account No',
                    },
                  ]}
                  // value={sort}
                  // onChange={(t) => {
                  //   setSort(t);
                  // }}
                />
              </MDBCol>
              <MDBCol size="12" md="4" className="my-1">
                <Select
                  options={[
                    { value: 'ASC', label: 'Ascending' },
                    { value: 'DESC', label: 'Descending' },
                  ]}
                  placeholder="Select Type Sort"
                  // value={typeSort}
                  // onChange={(t) => {
                  //   setTypeSort(t);
                  // }}
                />
              </MDBCol>
            </MDBRow> */}
          </MDBCol>
          <MDBCol size="12" md="6" className="p-2 mt-1 px-3 ">
            <MDBRow end>
              {/* <MDBCol size="6" md="4">
                <Select
                  className="w-100"
                  placeholder="Select Download"
                  options={[
                    { value: 0, label: 'Export Current Page' },
                    { value: 1, label: 'Export All' },
                  ]}
                  onChange={async (c) => {
                    let rightDownload = [];
                    let resultValues = [];
                    if (c.value == 0) {
                      setLoading(true);
                      setResponseDownload([]);
                      const listing = await api.get(`/user`, {
                        params: {
                          pag: page,
                          p: quantity,
                          ...dadosModal,
                          // sort,
                          // typeSort,
                        },
                      });
                      resultValues = listing.data.rows;
                    }
                    if (c.value == 1) {
                      setLoading(true);
                      setResponseDownload([]);
                      const listing = await api.get(`/user`, {
                        params: paramsDownload,
                      });

                      resultValues = listing.data.rows;
                    }
                    const result = [];
                    resultValues.forEach((element) => {
                      result.push({
                        Email: element.email,
                        First_name: element.first_name,
                        Lasta_name: element.last_name,
                        Phone: element.phone,
                        Active: element.active === null ? 'No' : element.active,
                        Status: element.active === true ? 'Active' : 'Inactive',
                      });
                    });

                    // resultValues.map((t) => {
                    //   rightDownload.push({
                    //     program_group: t.program_group,

                    //     supplier: t.TB_inventory_supplier.supplier,
                    //   });
                    // });

                    setResponseDownload(result);
                    setLoading(false);
                  }}
                />
              </MDBCol> */}
{/* 
              {responseDownload.length !== 0 ? (
                <MDBCol size="6" md="3">
                  <CsvDownloader
                    style={{ cursor: 'pointer', width: '200px' }}
                    className="ml-2"
                    // datas={responseDownload}
                    wrapColumnChar=""
                    separator={','}
                    filename={'APIVMI.csv'}
                  >
                    <span
                      style={{ color: '#0e4e9e' }}
                      className="mr-2 font-weight-bold"
                    >
                      Download
                    </span>

                    <MDBIcon
                      style={{ color: '#0e4e9e' }}
                      icon="download"
                      size="2x"
                    />
                  </CsvDownloader>
                </MDBCol>
              ) : (
                ''
              )} */}
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow
          between
          style={{
            backgroundColor: 'rgba(250, 250, 250, 0.6)',
            borderBottom: '2px solid silver',
          }}
          className="d-none d-md-flex rounded-top"
        >
          <MDBCol size="12" md="2" className="d-sm-inline font-weight-bold">
            Name
          </MDBCol>
          <MDBCol size="12" md="2" className="d-sm-inline font-weight-bold">
            Phone
          </MDBCol>
          <MDBCol size="12" md="3" className="d-sm-inline font-weight-bold">
            Email
          </MDBCol>
      
        
          <MDBCol size="12" md="1" className="d-sm-inline font-weight-bold">
            Status
          </MDBCol>
          {/* <div className="col-12 col-md-2 d-flex justify-content-between">
            <span className="d-sm-inline font-weight-bold mr-3">

            </span>
          </div> */}

          {/* <div className="col-12 col-md-1 d-flex justify-content-between">
            <span className="d-sm-inline font-weight-bold mr-3">
              Phone
            </span>
          </div> */}
          {/* <div className="col-12 col-md-1 d-flex justify-content-between">
            <span className="d-sm-inline font-weight-bold mr-3">
              Email
            </span>
          </div>
          <div className="col-12 col-md-1 d-flex justify-content-between">
            <span className="d-sm-inline font-weight-bold mr-3">
              Perfil
            </span>
          </div> */}

          {/* <div className="col-12 col-md-2 d-flex justify-content-between">
            <span className="d-sm-inline font-weight-bold mr-3">
              Access
            </span>
          </div>
          <div className="col-12 col-md-1 d-flex justify-content-between">
            <span className="d-sm-inline font-weight-bold mr-3">Status</span>
          </div>

          <div className="col-12 col-md-1 d-flex justify-content-center"></div> */}
        </MDBRow>
      </MDBContainer>

      {loading === true ? (
        <MDBRow className="mt-5" center>
          <MDBSpinner size="lg" role="status" tag="span" />
        </MDBRow>
      ) : (
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {dataUsers == ''
            ? ''
            : dataUsers.map((t) => (
                <Line key={t.id}>
                  {/* ALTER VALUE SETDADOS FOR DB */}

                  <MDBRow
                    onClick={() => setModalEditUser(t)}
                    between
                    className=" py-2 cursor-pointer "
                    style={{ borderBottom: '1px solid silver' }}
                  >
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">Name:</span>
                      <span>{`${t.first_name} ${t.last_name}`}</span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">Phone:</span>
                      <span>{`${t.phone}`}</span>
                    </div>

                    <div className="col-12 col-md-3 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">Email:</span>
                      <span>{`${t.email}`}</span>
                    </div>
                  
                   
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Status:
                      </span>
                      <span>{t.active === true ? 'Active' : 'Inactive'}</span>
                    </div>
                  </MDBRow>
                </Line>
              ))}

          <div className="font-weight-bold">
            Rows: <span className="ml-1">{rows}</span>
          </div>

          {/* <div className="font-weight-bold">
            Rows per page:
            <select
              className="muted-text ml-2 mr-5"
              style={{
                width: '100px',
                height: '25px',
                border: '1px solid muted',
              }}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
            >
              <option disabled>Per Page</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="1000">1000</option>
            </select>
            <span className="mr-4">
              {page} of {Math.ceil(rows / quantity)} pages
            </span>
            <MDBIcon
              onClick={prevPage}
              style={{ cursor: 'pointer', fontSize: '14px' }}
              className="grey-text mr-3"
              icon="chevron-left"
              // size="2x"
            />
            <MDBIcon
              onClick={nextPage}
              style={{ cursor: 'pointer', fontSize: '14px' }}
              className="grey-text ml-2"
              icon="chevron-right"
              // size="1x"
            />
          </div> */}
        </MDBContainer>
      )}
      <MDBModal
        className="text-left"
        size="md"
        isOpen={modalEditUser !== null}
        toggle={() => setModalEditUser(null)}
      >
        <MDBModalHeader toggle={() => setModalEditUser(null)}>
          User Details
        </MDBModalHeader>
        <MDBModalBody>
        <MDBRow center>
        <MDBSwitch 
  checked={modalEditUser?.active}
  onChange={(e) => setModalEditUser({
    ...modalEditUser,
    active: e.target.checked, // Use e.target.checked para obter o valor booleano
  })}
  labelLeft='Inactive' 
  labelRight='Active' 
  defaultChecked 
  id='flexSwitchCheckChecked' 
  label='Checked switch checkbox input' 
/>  </MDBRow>
          <MDBRow>
            <MDBCol size="12" md="6" middle>
              <MDBInput
                label="Email"
                type="email"
                disabled={!getPermission('edit_users')}
                group
                size="lg"
                value={modalEditUser?.email}
                onChange={(e) =>
                  setModalEditUser({
                    ...modalEditUser,
                    email: e.target.value,
                  })
                }
              />
            </MDBCol>
            <MDBCol size="12" md="6" middle>
              <MDBInput
                label="First Name"
                disabled={!getPermission('edit_users')}
                group
                size="lg"
                value={modalEditUser?.first_name}
                onChange={(e) =>
                  setModalEditUser({
                    ...modalEditUser,
                    first_name: e.target.value,
                  })
                }
              />
            </MDBCol>
            <MDBCol size="12" md="6" middle>
              <MDBInput
                label="Last Name"
                disabled={!getPermission('edit_users')}
                contrast
                group
                size="lg"
                value={modalEditUser?.last_name}
                onChange={(e) =>
                  setModalEditUser({
                    ...modalEditUser,
                    last_name: e.target.value,
                  })
                }
              />
            </MDBCol>

            <MDBCol size="12" md="6" middle>
              <div className="d-flex align-items-center">
                <InputMask
                  disabled={!getPermission('edit_users')}
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1.25rem',
                  }}
                  className="d-flex w-100 font-weight-light mt-3"
                  placeholder="Phone"
                  value={modalEditUser?.phone}
                  onChange={(e) =>
                    setModalEditUser({
                      ...modalEditUser,
                      phone: e.target.value,
                    })
                  }
                  mask="(99) 9999-99999"
                  maskChar=" "
                />
              </div>
            </MDBCol>
            {/* <MDBCol size="12" md="6" middle>
              {getPermission('edit_users') &&
                 <MDBInput
                 disabled={!getPermission('edit_users')}
                 type="password"
                 label="Password"
                 autoComplete="new-password"
                 group
                 size="lg"
                 onChange={(e) =>
                   setModalEditUser({
                     ...modalEditUser,
                     password: e.target.value,
                   })
                 }
                />}
            </MDBCol> */}
          </MDBRow>
          
          <MDBRow>
            <MDBCol size="12" md="4" middle>
              <Select
                onChange={(e) => {
                  if (e === selectProfile) return;
                  setSelectProfile(e);
                }}
                placeholder="Perfil"
                options={listProfile}
                value={selectProfile}
              />
            </MDBCol>
            <MDBCol size="12" md="8" middle>
              {selectProfile?.label === 'Manager' && (
                <MDBRow>
                  <MDBCol className="p-0" middle size="12" md="8">
                    <Select
                      // isClearable
                      placeholder="Users"
                      onChange={(e) => setSelectedUser(e)}
                      options={selectDataUsers.current}
                      value={selectedUser}
                    />
                  </MDBCol>
                  <MDBCol className="p-0" size="12" md="4">
                    <div
                      className={`ml-3 btn btn-sm shadow-5  ${
                        searchCollapseID
                          ? 'btn-outline-grey grey-text'
                          : 'bg-vmi text-white'
                      }  `}
                      size="sm"
                      onClick={() => {
                        insertUsers();
                      }}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        <MDBIcon icon="plus" size="2x" />
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
              {selectProfile?.label === 'Rep' && (
                <MDBRow>
                  <MDBCol size="12" className="p-0" md="8" middle>
                    <Select
                      // isClearable
                      placeholder="Program"
                      onChange={(e) => setSelectProgram(e)}
                      options={listPrograms}
                      value={selectProgram}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4" className="p-0" middle>
                    <div
                      className={`ml-3 btn btn-sm shadow-5 ${
                        searchCollapseID
                          ? 'btn-outline-grey grey-text'
                          : 'bg-vmi text-white'
                      }  `}
                      size="sm"
                      onClick={() => {
                        insertProgram();
                      }}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        <MDBIcon icon="plus" size="2x" />
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>

            <MDBCol>
              <MDBRow>
                {listAccess.length > 0 &&
                  listAccess.map((access) => (
                    <MDBCol middle size="6">
                      <MDBCard className="m-1 p-2 h5 ">
                        <MDBRow between>
                          <MDBCol>{access?.program}</MDBCol>
                          <MDBCol className="text-right mr-2">
                            <MDBIcon
                              fas
                              icon="times"
                              onClick={(e) => deleteProgram(access)}
                              className="cursor-pointer"
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </MDBCol>
                  ))}
              </MDBRow>
              <MDBRow>
                {listSelectUsers.length > 0 &&
                  listSelectUsers.map((user) => (
                    <MDBCol middle size="6">
                      <MDBCard className="m-1 p-2 h5 ">
                        <MDBRow between>
                          <MDBCol size="9">{user?.name}</MDBCol>
                          <MDBCol size="3" middle className="text-right">
                            <MDBIcon
                              fas
                              icon="times"
                              onClick={(e) => {
                                deleteListSelectUser(user);
                              }}
                              className="cursor-pointer mr-2"
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </MDBCol>
                  ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          {/* {selectProfile?.label === 'Rep' && (
          <MDBRow className='mt-4'>
                  <MDBCol size="12" md="6" middle>
                    <Select
                      onChange={(e) => {
                        if (e === selectedAddInventory) return;

                        setSelectAddInventory(e);
                      }}
                      placeholder="Inventory Supplier"
                      options={selectAddListInventory}
                      value={selectedAddInventory}
                    />
                  </MDBCol>
                  <MDBCol className="p-0" size="12" md="4">
                          <div
                            className={`ml-3 btn btn-sm shadow-5  ${
                              searchCollapseID
                                ? 'btn-outline-grey grey-text'
                                : 'bg-vmi text-white'
                            }  `}
                            size="sm"
                            onClick={() => {
                              insertAddSupplier();
                            }}
                          >
                            <span className="m-0 font-weight-bold ">
                           
                              <MDBIcon icon="plus" size="2x" />
                            </span>
                          </div>
                        </MDBCol>
                  </MDBRow>
          )}
          <MDBRow>
                {selectedListInventory.length > 0 &&
                  selectedListInventory.map((access) => (
                    <MDBCol middle size="6">
              
                      <MDBCard className="m-1 p-2 h5 ">
                        <MDBRow between>
                     
                          <MDBCol>{access?.supplier}</MDBCol>
                          <MDBCol className="text-right mr-2">
                            <MDBIcon
                              fas
                              icon="times"
                              onClick={(e) => deleteAddInventory(access)}
                              className="cursor-pointer"
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </MDBCol>
                  ))} */}
              {/* </MDBRow> */}
          {/* <MDBRow center>
            <div
              className={`ml-3 btn btn-sm shadow-5  bg-vmi text-white`}
              size="sm"
              onClick={() => alteraDados()}
            >
              <span className="m-0 font-weight-bold ">

                <MDBIcon className="mr-1" icon="plus" /> Save
              </span>
            </div>
          </MDBRow> */}
        </MDBModalBody>
        <MDBModalFooter>
     
                        <div
            className="btn btn-sm shadow-5 bg-warning d-none d-md-inline"
            size="sm"
               onClick={()=>setModalResetSenha(true)}
          >
            <span className="m-0 font-weight-bold text-white">Reset Password</span>
          </div>
          <div
            className="btn btn-sm shadow-5 bg-danger d-none d-md-inline"
            size="sm"
            onClick={() => setModalEditUser(null)}
          >
            <span className="m-0 font-weight-bold text-white">Close</span>
          </div>
          <div
            className="btn btn-sm shadow-5 bg-vmi  d-none d-md-inline"
            size="sm"
            onClick={alteraDados}
            // onClick={handlerSubmit}
          >
            <span className="m-0 font-weight-bold text-white">
              Save changes
            </span>
          </div>
        </MDBModalFooter>
        <MDBModal
            isOpen={modalResetSenha}
            toggle={()=>setModalResetSenha(false)
            }
          >
            <MDBModalHeader toggle={()=>setModalResetSenha(false)}>
            Reset the user's password?
            </MDBModalHeader>
            <MDBModalBody>
            After changing the user's password, the process cannot be undone.
              <br />
              <br />
              <div className="text-danger">
              Note: The user's password will be reset to the registered email address.
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn size='sm' color="danger" onClick={()=>setModalResetSenha(false)}>
                Close
              </MDBBtn>
              <div
            className="btn btn-sm shadow-5 bg-vmi  d-none d-md-inline"
            size="sm"
            onClick={resetPassword}
            // onClick={handlerSubmit}
          >
            <span className="m-0 font-weight-bold text-white">
            Reset Now
            </span>
          </div>

            
            </MDBModalFooter>
          </MDBModal>
      </MDBModal>
      
    </NavBar>
  );
};

export default AddUsuario;
