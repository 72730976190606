
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBSideNavItem,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBFooter,
  MDBContainer,
  MDBSideNavLink,
} from 'mdbreact';
import Logo from '../../assets/logo.jpg';
import logoHorizontal from '../../assets/logohorizontal2.png';
import backgroundImage from '../../assets/neural.jpg';
import backgroundMenu from '../../assets/backgroundMenupng.png';
import {getData,getPerfil} from '../../services/auth'



function DoubleNavigationPage(props) {
  const [toggleStateA, setToggleStateA] = useState(true);
  const [breakWidth, setBreakWidth] = useState(130000);
  const [windowWidth, setWindowWidth] = useState(0);
  const [card, setCard] = useState(null);
  const perfil = getPerfil();
  const handleResize = () => {
    if (window.innerWidth > 765) {
      return 'w-25';
    } else {
      return ' w-100';
    }
    // setWindowWidth(window.innerWidth);
    // return () => window.removeEventListener('resize', handleResize);
  };
  useEffect(() => {
    const fetchedCard = getData() ? getData().card : null;
    setCard(fetchedCard);
  }, []);

 
  
  
  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };
// useEffect(()=>{console.log(teste)},[teste])
  //   handleResize(); // Call once initially to set the initial width
  //   window.addEventListener('resize', handleResize);

  //   // Cleanup function to remove the event listener
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);
  window.addEventListener('resize', handleResize);

  const handleToggleClickA = () => {
    setToggleStateA(!toggleStateA);
  };

  const navStyle = {
    // paddingLeft: windowWidth > breakWidth ? '190px' : '16px',
  };

  const mainStyle = {
    margin: '0 0%',
    paddingTop: '5.5rem',
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed' // Adicionado para manter a imagem fixa
  };

  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row',
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <MDBSideNav
        triggerOpening={toggleStateA}
        breakWidth={breakWidth}
        // bg={backgroundMenu}
        className={`${handleResize()} bg-vmi`}
        mask="strong"
        fixed
      >
        <div className="my-3 w-100 d-flex justify-content-center">
          <Link
            to="/app"
            className="w-100 h-100 d-flex justify-content-center p-0"
          >
            <img
              className="h-50"
              src={Logo}
              style={{
                width: '150px',
                borderRadius: '5px',
              }}
            />
          </Link>
          
        </div>
     
        <MDBSideNavNav className="p-3 px-5 ">
        {!card ?<p>Loading cards...</p>
        :
       
        card.map(card => (
          <MDBSideNavCat
            key={card.id}
            className="bg-white black-text rounded mb-0 mt-3 font-weight-bold"
            style={{ fontSize: '15px' }}
            name={card.name}
            id={`${card.id}`}
          >
            {card.funcao.map(func => (
              func.card === false ? '':
              <MDBSideNavLink
              key={func.id}
              style={{ fontSize: '14px' }}
              to={`${func.url}`}
            >
              {func.name} 
            </MDBSideNavLink>
            ))}
          </MDBSideNavCat>
        ))
     
        }
        </MDBSideNavNav>
      </MDBSideNav>
      <MDBNavbar
        style={{ backgroundColor: '#00346A' }}
        className="p-2"
        double
        expand="md"
        fixed="top"
        scrolling
      >
        <MDBNavbarNav left>
          <MDBNavItem>
            <div
              onClick={() => handleToggleClickA()}
              key="sideNavToggleA"
              style={{
                lineHeight: '32px',
                marginRight: '1em',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              <MDBIcon icon="bars" className="text-white ml-3" size="2x" />
            </div>
          </MDBNavItem>

          <Link
            to="/app"
            className="d-none d-md-flex w-100 h-100 justify-content-center"
            style={{ paddingTop: 5 }}
          >
            <img
              src={logoHorizontal}
              style={{
                width: '170px',
                borderRadius: '5px',
              }}
            />
          </Link>
        </MDBNavbarNav>
        <MDBNavbarNav right style={specialCaseNavbarStyles}>
          <MDBNavItem>
            <MDBNavLink to="/administration/perfil" >
              <div style={{ fontSize: '12px' }} className="d-none d-md-inline">
              {perfil.first_name} {perfil.last_name}
              </div>
              <MDBIcon
                style={{ fontSize: '20px' }}
                icon="user"
                className="ml-3"
              />
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <div
                    style={{ fontSize: '12px' }}
                    className="d-none d-md-inline"
                  >
                    Configs
                  </div>
                </MDBDropdownToggle>

                <MDBDropdownMenu right>
                  <MDBRow center>
                    <MDBSideNavLink className="w-75 text-center" to="/perfil">
                      Perfil
                    </MDBSideNavLink>
                  </MDBRow>
                  <MDBRow center>
                    <MDBSideNavLink
                      className="w-75 text-center"
                      onClick={() => {
                        logout();
                      }}
                      to="/"
                    >
                      Logout
                    </MDBSideNavLink>
                  </MDBRow>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem> */}
        </MDBNavbarNav>
      </MDBNavbar>
      <main style={{ ...mainStyle, flex: '1 0 auto' }}>
        {' '}
        {/* Ajuste para fazer o conteúdo principal crescer */}
        <MDBContainer fluid style={{ minHeight: '75vh' }}>
          {props.children}
        </MDBContainer>
      </main>
      <MDBFooter
        style={{ backgroundColor: '#00346A', flexShrink: '0' }}
        className="font-small py-3 "
      >
        <MDBContainer fluid>
          <MDBRow className="flex-nowrap">
            <MDBCol md="4">
              <MDBRow className="ml-2">
                <MDBIcon style={{ fontSize: '12px' }} icon="envelope" />

                <div style={{ fontSize: '12px', marginLeft: '10px' }}>
                  Contact Us
                </div>
              </MDBRow>

              <MDBRow className="ml-2">
                <MDBIcon style={{ fontSize: '12px' }} icon="comments" />

                <div style={{ fontSize: '12px', marginLeft: '10px' }}>
                  Support
                </div>
              </MDBRow>
            </MDBCol>
            <MDBCol
              md="4"
              className="text-center mt-auto pb-3"
              style={{ fontSize: '9px' }}
            >
              &copy; {new Date().getFullYear()} Copyright:{' '}
              <a href="https://www.logikvmi.app"> www.logikvmi.app </a>
            </MDBCol>
            <MDBCol md="4" className="text-right pr-4">
              <MDBRow>
                <MDBCol className="text-right d-flex align-items-center justify-content-end ">
                  <MDBIcon icon="map-marker-alt" />
                  <div style={{ fontSize: '14px', marginLeft: '10px' }}>
                    Locations
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>Dublin offices</MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        {/* <div className="footer-copyright text-center py-1">
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Copyright: <a href="https://www.logikvmi.app"> www.logikvmi.app </a>
            </MDBContainer>
          </div> */}
      </MDBFooter>
    </div>
  );
}

export default DoubleNavigationPage;
