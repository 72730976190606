import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import api from '../../../services/api';
import { format } from 'date-fns';
import { CSVLink, CSVDownload } from 'react-csv';
import { getProfile } from '../../../services/auth';

import Select from 'react-select';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
} from 'mdbreact';

import { Line } from './styles';

export default function ProductSupplier(props) {
  const [collapseID, setCollapseID] = useState(false);
  const [supplier, setSupplier] = useState('');
  const [listSupplier, setListSupplier] = useState([]);
  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);
  const [loadDownload, setLoadDownload] = useState([]);

  const [dadosModal, setDadosModal] = useState({});

  // download
  const [typeDownload, setTypeDownload] = useState();
  const [paramsDownload, setParamsDownload] = useState({});
  const [responseDownload, setResponseDownload] = useState([]);
  const [sort, setSort] = useState({ value: 'supplier', label: 'Vendor' });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });
  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  useEffect(() => {
    list(1);
  }, [quantity]);

  const enviaDados = async (e) => {
    if (supplier === '') {
      toast.error('Complete the fields!');
      return;
    }
    try {
      await api.post('/inventory-supplier', { supplier });
      setSupplier('');
      list();
      toast.success('Added Successfully!');
    } catch (error) {
      toast.error('Error');
    }
  };

  const list = async (npage = page) => {
    setLoading(true);
    window.scrollTo(0, 0);
    const response = await api.get(`/inventory-supplier`, {
      params: {
        search: search,
        pag: npage,
        p: quantity,
        sort: sort.value,
        typeSort: typeSort.value,
      },
    });

    setListSupplier(response.data.rows);
    setRows(response.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

    setLoading(false);
    setLoadDownload(response.data.rows);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };

  useEffect(() => {
    list(1);
  }, [sort, typeSort]);

  return (
    <>
      <NavBar>
        {/* BOTAO DE ADICIONAR */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi">
              Vendor
              {getProfile(['Super Admin', 'Admin']) && (
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="d-flex d-md-none justify-content-end"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBCollapse isOpen={collapseID}>
            <div
              onSubmit={enviaDados}
              style={{ borderRadius: '5px' }}
              className="p-3 d-column d-md-flex mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center align-items-center bg-white"
            >
              <MDBCol className="col-12 col-md-5">
                <MDBInput
                  label="New Vendor"
                  icon="clipboard"
                  group
                  size="lg"
                  onChange={(e) => setSupplier(e.target.value)}
                  value={supplier}
                />
              </MDBCol>
              <MDBCol className="col-12 col-md-2 d-flex justify-content-center">
                <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={() => enviaDados()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                  </span>
                </div>
              </MDBCol>
            </div>
          </MDBCollapse>
        </MDBContainer>
        {/* BOTAO DE SEARCH E QUANTIDADE */}
        <MDBContainer fluid>
          {/* <Select
                 width='200px'
                // className="col-10  form-control ml-3 flex-1"
                options={options}
                // type="text"
                // placeholder="Search"
                // aria-label="Search"
                // onChange={(e)=>{setSearch(e.target.value)}}

              /> */}
          <MDBRow className="md-form w-100  d-flex flex-column flex-md-row justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <MDBInput
                //  width='200px'
                className="col-10  form-control ml-3 flex-1"
                type="text"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    list(1);
                  }
                }}
              />
              <MDBIcon
                icon="search"
                size="2x"
                className="text-muted cursor-pointer"
                onClick={() => {
                  list(1);
                  setPage('1');
                }}
              />
            </div>
          </MDBRow>
        </MDBContainer>
        {/* LISTAGEM */}
        {/* HEAD FORM TO MD VISUALIZATION */}
        <MDBContainer fluid>
          <MDBRow
            style={{ color: '#3f5c80' }}
            className="d-flex justify-content-between mr-4 pl-3"
          >
            <MDBCol
              col="12"
              md="3"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                placeholder="Select Sort"
                options={[
                  { value: 'supplier', label: 'Vendor' },
                  {
                    value: 'active',
                    label: 'Activated',
                  },
                ]}
                value={sort}
                onChange={(t) => {
                  setSort(t);
                }}
              />
            </MDBCol>
            <MDBCol
              col="12"
              md="2"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                options={[
                  { value: 'ASC', label: 'Ascending' },
                  { value: 'DESC', label: 'Descending' },
                ]}
                placeholder="Select Type Sort"
                onChange={(t) => {
                  setTypeSort(t);
                }}
                value={typeSort}
              />
            </MDBCol>
            <MDBCol size="2" />
            <MDBCol
              size="4"
              className="d-flex justify-content-end align-items-center"
            >
              <Select
                className="w-50"
                // isClearable={true}

                placeholder="Select Download"
                options={[
                  { value: 0, label: 'Export Current Page' },
                  { value: 1, label: 'Export All' },
                ]}
                onChange={async (c) => {
                  if (c.value == 0) {
                    setLoading(true);
                    setResponseDownload([]);
                    const listing = await api.get(`/inventory-supplier`, {
                      params: {
                        pag: page,
                        p: quantity,
                        ...dadosModal,
                        sort,
                        typeSort,
                      },
                    });

                    const download = [];
                    listing.data.rows.forEach((element) => {
                      const {
                        id,
                        createdAt,
                        updatedAt,
                        ...rest
                      } = element;

                      download.push({
                        ...rest,
                         active: element.active === true ? 'Yes':'No',
                      });
                    });

                    setResponseDownload(download);
                    setLoading(false);
                  }
                  if (c.value == 1) {
                    setLoading(true);
                    setResponseDownload([]);
                    const response = await api.get(`/inventory-supplier`, {
                      params: paramsDownload,
                    });
                    const download = [];
                    response.data.rows.forEach((element) => {
                      const {
                        id,
                        createdAt,
                        updatedAt,
                        ...rest
                      } = element;

                      download.push({
                        ...rest,
                         active: element.active === true ? 'Yes':'No',
                      });
                    });

                    setResponseDownload(download);
                    setLoading(false);
                  }
                }}
              />

              {responseDownload.length !== 0 ? (
                <CSVLink
                  className="ml-2"
                  data={responseDownload}
                  // separator={'\t'}
                  separator={','}
                  filename={'inventorySupplier.csv'}
                 
                >
                  <span
                    style={{ color: '#0e4e9e' }}
                    className="mr-2 font-weight-bold"
                  >
                    Download
                  </span>

                  <MDBIcon
                    style={{ color: '#0e4e9e' }}
                    icon="download"
                    size="2x"
                  />
                </CSVLink>
              ) : (
                ''
              )}
            </MDBCol>
          </MDBRow>

          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-5 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Vendor:</span>
            </div>

            <div className="col-12 col-md-5 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Status:</span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center"></div>
          </MDBRow>
        </MDBContainer>

        {/* LIST CUSTOMER */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : (
            listSupplier.length > 0 &&
            listSupplier.map((t) => (
              <div key={t.id}>
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line key={t.id}>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className="mr-1 ml-1 mr-md-0 ml-md-0 p-2"
                  >
                    <div className="col-12 col-md-5 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Vendor:
                      </span>
                      <span className="ml-3">{t.supplier}</span>
                    </div>

                    <div className="col-12 col-md-5 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Status:
                      </span>
                      <span className="ml-3">
                        {t.active == true ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                      {/* EDIT MODAL */}
                      <Modal
                        id={t.id}
                        title="Vendor"
                        dados={dadosModal}
                        api={`/inventory-supplier/${t.id}`}
                        restart={async () => await list()}
                        size="lg"
                        disabled={!getProfile(['Super Admin', 'Admin'])}
                      >
                        <MDBRow>
                        
                          <MDBCol className="h4-responsive" col="12" md="6">
                            Creation Date:
                            {t.createdAt
                              ? format(new Date(t.createdAt), 'dd/MM/yyyy')
                              : ''}
                          </MDBCol>
                          <MDBCol
                            className={`h4-responsive ${
                              t.active === false && 'text-danger'
                            }`}
                            col="12"
                            md="6"
                          >
                            {t.active === false
                              ? 'Inactive Date:'
                              : 'Modified Date:'}

                            {format(new Date(t.updatedAt), 'dd/MM/yyyy')}
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol className="col-12 col-md-6">
                            <MDBInput
                              label="Program"
                              group
                              size="lg"
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              valueDefault={t.supplier}
                              onChange={(e) => {
                                setDadosModal({
                                  ...dadosModal,
                                  supplier: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento

                                if (e.target.value == '') {
                                  const { supplier, ...restante } = dadosModal;
                                  setDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol className="col-12 col-md-6">
                            <span>Status</span>
                            <Select
                              className="w-100"
                              isDisabled={!getProfile(['Super Admin', 'Admin'])}
                              options={[
                                { value: 1, label: 'Active' },
                                { value: 0, label: 'Inactive' },
                              ]}
                              defaultValue={{
                                label:
                                  t.active === true ? 'Active' : 'Inactive',
                                value: t.active === true ? true : false,
                              }}
                              onChange={(c) =>
                                setDadosModal({
                                  ...dadosModal,
                                  active: c.value,
                                })
                              }
                              // defaultValue={{ label: t.TB_customer_group.program_group, value: t.TB_customer_group.id}}
                            />
                          </MDBCol>
                        </MDBRow>
                        <br />

                        {/* <MDBModalFooter> */}
                        {/* <MDBBtn color="secondary" >Close</MDBBtn> */}
                        {/* <MDBBtn onClick={editDados} color="primary">Save changes</MDBBtn> */}
                        {/* <MDBBtn onClick={editaDados} color="primary">Save changes</MDBBtn> */}
                        {/* </MDBModalFooter> */}

                        {/* </form> */}
                      </Modal>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          )}
          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
      </NavBar>
    </>
  );
}
