import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'react-select';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
  MDBTooltip,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBSpinner,
  MDBSelect,
  MDBToastContainer,
  MDBToast,
} from 'mdbreact';
import api from '../../../services/api';
import Modal from '../../../components/Modal';
import NavBar from '../../../components/NavBar';

import { CSVLink } from 'react-csv';
import CsvDownloader from 'react-csv-downloader';
import {InsertText} from '../../../components/InsertText'
import { getData, getProfile } from '../../../services/auth';

// import Loading from '../../../components/Loading';
import { Line, Checkbox } from './styles';
import SelectMulti from '../../../components/SelectMulti'
import fileXlsx from './TemplateTextCustomerListings.xlsx';

export default function CustomerListings(props) {
  const programs = getData().profile.programs;
  const [collapseID, setCollapseID] = useState(false);
  const [searchCollapseID, setSearchCollapseID] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [listResult, setListResult] = useState([]);

  const [rows, setRows] = useState('');
 
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);

  const [rowError, setRowError] = useState([]);
  const [inputAddText, setInputAddText] = useState([]);
  const [qtyLine, setQtyLine] = useState(0);

  // customer acount number -> customer part num
  const [addCustomerPartNum, setAddCustomerPartNum] = useState('');

  const [addProgram, setAddProgram] = useState('');
  const [addProgramList, setAddProgramList] = useState([]);

  const [addVendorNumber, SetAddVendorNumber] = useState('');
  const [addVendorNumberList, setAddVendorNumberList] = useState([
    { value: '', text: '' },
  ]);

  const [addQtyOnHand, setAddQtyOnHand] = useState('');

  const [addMinInventory, setAddMinInventory] = useState('');
  const [addMaxInventory, setAddMaxInventory] = useState('');

  const [addUnitPrice, setAddUnitPrice] = useState('');

  const [addUserId, setAddUserId] = useState('');
  const [addCustomerGroupId, setAddCustomerGroupId] = useState('');
  const [addUnitCost, setAddUnitCost] = useState('');
  const [addOrderMultiple, setAddOrderMultiple] = useState('');
  const [addReplenish, setAddReplenish] = useState('');
  const [addFactor, setAddFactor] = useState('');

  const [listUser, setListUser] = useState([]);
  const [listCustomerGroupId, setListCustomerGroupId] = useState([]);

  const [dadosModal, setDadosModal] = useState({});

  const [editDadosModal, setEditDadosModal] = useState({});
  const [listProductGroupId, setListProductGroupId] = useState([]);
  const [listManufacturer, setListManufacturer] = useState([]);
  const [listCategory, setListCategory] = useState([]);

  const [typeDownload, setTypeDownload] = useState();
  const [paramsDownload, setParamsDownload] = useState({});

  const [responseDownload, setResponseDownload] = useState([]);
  const [radio, setRadio] = useState('program');

  const [sort, setSort] = useState({
    value: 'customer_part_num',
    label: 'Customer Part No',
  });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });

  const [erroMinMax, setErroMinMax] = useState([]);
  const [resultOk, setResultOk] = useState([]);
  const [loadText, setLoadText] = useState(false);

  
  const [search,setSearch] = useState(null)

  const [programAdSearch,setProgramAdSearch] = useState([])
  const [programGroupAdSearch,setProgramGroupAdSearch] = useState([])
  const [descriptionAdSearch,setDescriptionAdSearch] = useState(null)
  const [manufacturerAdSearch,setManufacturerAdSearch] = useState([])
  const [productGroupAdSearch,setProductGroupAdSearch] = useState([])
  const [productCategoryAdSearch,setProductCategoryAdSearch] = useState([])
  const [replenishAdSearch,setReplenishAdSearch] = useState(null)
  const [customerPartNumAdSearch,setCustomerPartNumAdSearch] = useState(null)
  const [vendorPartNumAdSearch,setvendorPartNumAdSearch] = useState(null)
  const [repAdSearch,setRepAdSearch] = useState([])
  const [statusAdSearch,setStatusAdSearch] = useState(null)

const [programGroupAdSearchList,setProgramGroupAdSearchList] = useState([])
const [manufacturerAdSearchList,setManufacturerAdSearchList] = useState([])
const [productGroupAdSearchList,setProductGroupAdSearchList] = useState([])
const [productCategoryAdSearchList,setProductCategoryAdSearchList] = useState([])
  
const erros = [];

  //results crated and update
  const ok = [];
 
  // carrega as listas para escolher os inputs
  const carregaListas = async () => {
    const selectProgram = [];
    programs.forEach((pro) => {
      selectProgram.push({ label: pro.program, value: pro.id });
    });

    setAddProgramList(selectProgram);

    // load product group in select
    // const responseCustomerGroupId = await api.get(`/customer-group?p=99999999`);
    // // setListCustomerGroupId(responseCustomerGroupId.data.rows);

    // // map list product group to options of select
    // const listaCustomerGroupId = [];
    // responseCustomerGroupId.data.rows.map((c) => {
    //   listaCustomerGroupId.push({ value: c.id, label: c.program_group });
    // });
    // setListCustomerGroupId(listaCustomerGroupId);


    // se for representante na mostra nada
    // ver se e manager se for, buscar os usuarios a baixo dele
    // se tiver viewall mostra tudo
    
    const responseUser = await api.get(`/profile-user`);

    // setListUser(responseUser.data.rows);

    // map list manufacturer to options of select
    let listagemUser = [];
    responseUser.data.map((c) => {
      listagemUser.push({
        value: c.id,
        label: `${c.first_name} ${c.last_name}`,
      });
    });
    setListUser(listagemUser);
  };

  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  const searchCollapse = async () => {
    // load product group in select
    // const responseProductGroupId = await api.get(`/product-group?p=99999999`);
    // setListProductGroupId(responseProductGroupId.data.rows);
    // // map list product group to options of select
    // const listaProductGroupId = [];
    // responseProductGroupId.data.rows.map((c) => {
    //   listaProductGroupId.push({ value: c.id, label: c.group });
    // });
    // setListProductGroupId(listaProductGroupId);

    // // load product manufacturer in select
    // const responseManufacturer = await api.get(`/manufacturer?p=99999999`);
    // setListManufacturer(responseManufacturer.data.rows);
    // // map list product group to options of select
    // const listaManufacturer = [];
    // responseManufacturer.data.rows.map((c) => {
    //   listaManufacturer.push({ value: c.id, label: c.manufacturer });
    // });
    // setListManufacturer(listaManufacturer);

    // // load product category in select
    // const responseCategory = await api.get(`/product-category?p=99999999`);
    // setListCategory(responseCategory.data.rows);
    // // map list product category to options of select
    // const listaCategory = [];
    // responseCategory.data.rows.map((c) => {
    //   listaCategory.push({ value: c.id, label: c.category });
    // });
    // setListCategory(listaCategory);
  };

  const onType = (string) => {
    setRadio(string);
  };


  useEffect(() => {
    list(page);
  }, [quantity]);

  const enviaDados = async (e) => {
    if (
      !addProgram ||
      addCustomerPartNum === '' ||
      addVendorNumber === '' ||
      addQtyOnHand === '' ||
      addMinInventory === '' ||
      addMaxInventory === '' ||
      addUnitPrice === '' ||
      addUnitCost === '' ||
      addOrderMultiple === '' ||
      addReplenish === ''
    ) {
      toast.error('Complete the fields!');
      return;
    }

    try {
      // console.log({group:group,product_category_id:categorySelected})
      const result = await api.post('/customer-listings', {
        customer_id: addProgram,
        customer_part_num: addCustomerPartNum,
        product_id: addVendorNumber,
        qty_on_hand: addQtyOnHand,
        min_inventory: addMinInventory,
        max_inventory: addMaxInventory,
        unit_price: addUnitPrice,
        unit_cost: addUnitCost,
        order_multiple: addOrderMultiple,
        replenish: addReplenish,
        factor: addFactor === '' && null,
      });

      if (result.data.error) {
        // toast.error(result.data.error);
        toast.error(result.data.error, {
          closeButton: true,
        });

        return;
      }

      setAddProgram('');
      setAddCustomerPartNum('');
      SetAddVendorNumber('');
      setAddQtyOnHand('');
      setAddMinInventory('');
      setAddMaxInventory('');
      setAddUnitPrice('');
      setAddUnitCost('');
      setAddOrderMultiple('');
      setAddReplenish('');
      toast.success('Added Successfully!');
    } catch (error) {
      // console.log(error);
      toast.error('Error');
    }
  };

  const addModalFunction = () => {
    setAddModal(!addModal);
  };

  const enviaText = async (e) => {
    setRowError([]);

    if (!inputAddText) {
      toast.error('No Data');
      return;
    }

    const rows = inputAddText.split('\n');
    if (rows <= 2) {
      toast.error('Error message', {
        closeButton: false,
      });
      // toast.error('No Data');
      return;
    }

    // salva a linha de cabeçalho para tratamento dos dados
    let headers = '';

    let n = 0;
    while (n < rows.length) {
      if (rows[n]) {
        headers = rows[n].split('\t');
        break;
      }
      n++;
    }

    let novaLinha = [];
    rows.map(async (i) => {
      const arrayLinha = i.split('\t');

      if (arrayLinha.length > 1) {
        let novaCelula = {};

        for (let i = 0; i < arrayLinha.length; i++) {
          novaCelula[headers[i]] = arrayLinha[i];
        }
        novaLinha.push(novaCelula);
      }
    });

    // retira primeira linha program:program
    novaLinha.shift();

    // quantidade de linhas totais
    setQtyLine(novaLinha.length);

    if (novaLinha.length > 1000) {
      toast.error('Max limit 1000');
    }

    
    if (radio === 'program') {
      setLoadText(true);
      Promise.all(
        novaLinha.map(async (linha) => {
          if (linha.program === '') {
            console.log('remover essa linha');
            return;
          }
          if (radio === 'program') {
            const result = await api.post(
              '/add-txt-customer-listings-program',
              linha
            );
            return result.data;
          }
        })
      ).then((datas) => {
        const erro = [];
        datas.map((linha) => {
          if (linha.erro) {
            erro.push(linha.row);
          }
        });

        setErroMinMax(erro);
        setLoadText(false);
      });

      setResultOk(ok);

      // setErroMinMax(erros);
    }
    if (radio === 'program_group') {
      setLoadText(true);
      const errosProgram = [];
      Promise.all(
        novaLinha.map(async (linha) => {
          if (linha.program_group === '') {
            console.log('remover essa linha');
            return;
          }
          if (radio === 'program_group') {
            const result = await api.post(
              '/add-txt-customer-listings-program-group',
              linha
            );
            return result.data;
          }
        })
      ).then((datas) => {
        datas.map((row) => {
          row.map((line) => {
            if (line.erro) {
              errosProgram.push(line.row);
            }
          });
        });
        setErroMinMax(errosProgram);
        setLoadText(false);
      });
    }

    // novaLinha.map(async (row) => {
    //   const teste = await api.post('/add-txt-vendor-product', row);

    //   if (teste.data.error) {
    //     testeError = [...testeError, teste.data.error];

    // console.log(teste.data.error);
    // setRowError(...rowError,row)
    // setLoadDownload([...loadDownload, teste.data.error]);
    // setRowError([...rowError, row]);
    // setListData({data: rowError});
    // }

    // toast.success('Data list Updated');
    // success++;
    // if (success == novaLinha.length) {
    //   toast.success('Data list Updated');
    // }
    // if (teste.data.error) {
    //   // setRowError([...rowError, teste.data.error ]);
    //   testeError.push(teste.data.error);
    //   // setRowError([...rowError ,teste.data.error]);
    //   toast.error('houve um error');
    // }
    // setRowError(testeError);
    // });
    // setLoading(false);
    // toast.success('Data list Updated');
    // console.log(testeError);
    // setLoadDownload(testeError);
    // }

    // return;
  };

 

  const list = async (npage = page) => {
    setLoading(true);
    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
    window.scrollTo(0, 0);
    const dados = {}
    if(searchCollapseID){
     

      if (programAdSearch.length > 0) {
        dados.program = programAdSearch.map(item => item.value);
      }
      
      if (programGroupAdSearch.length > 0) {
        dados.program_group = programGroupAdSearch.map(item => item.value);
      }
      
      if (descriptionAdSearch !== null) {
        dados.description = descriptionAdSearch;
      }
      
      if (manufacturerAdSearch.length > 0) {
        dados.manufacturer = manufacturerAdSearch.map(item => item.value);
      }
      
      if (productGroupAdSearch.length > 0) {
        dados.product_group = productGroupAdSearch.map(item => item.value);
      }
      
      if (productCategoryAdSearch.length > 0) {
        dados.product_category = productCategoryAdSearch.map(item => item.value);
      }
      
      if (replenishAdSearch !== null) {
        dados.replenish = replenishAdSearch.value;
      }
      
      if (customerPartNumAdSearch !== null) {
        dados.customer_part_num = customerPartNumAdSearch;
      }
      
      if (vendorPartNumAdSearch !== null) {
        dados.vendor_part_num = vendorPartNumAdSearch;
      }
      
      if (statusAdSearch !== null) {
        dados.active = statusAdSearch.value;
      }
      
    }else{
       if(search !== null){
      dados.search = search
      }
    }
   
    try {
      
      const listing = await api.get(`/customer-listings`, {
        params: {
          pag: npage,
          p: quantity,
          ...dados,
          sort: sort.value,
          typeSort: typeSort.value,
        
        },
      });
   
   
      if (!listing.data) {
        toast.error('BAD REQUEST');
        return;
      }

      setListResult(listing.data.rows);
      setRows(listing.data.count);
      setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
      setLoading(false);
      setParamsDownload({
        pag: npage,
        p: 999999999999999,
        ...dadosModal,
        sort: sort.value,
        typeSort: typeSort.value,
      });
      setEditDadosModal({});
      setTypeDownload('advanced');
    } catch (error) {
      toast.error('ERROR DATABASE');
      console.log(' ERROR ', error);
    }
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };
  useEffect(() => {
    searchCollapse();
    setDadosModal({});
  }, [searchCollapseID]);
  useEffect(() => {
    carregaListas();
  }, []);

  useEffect(() => {
    list(1);
  }, [typeSort, sort]);

  const preencheSelects = async () => {
   const program =
    {
      colname: 'program',
      colid: 'program_id',
    }

    if (programAdSearch.length > 0){
      const programs = []
      programAdSearch.map((e) => programs.push(e.value))
      program.id = programs
    } else{
      const id = []
      programs.forEach((pro) => {
        id.push(pro.id);
      });
      program.id = id
    }

   
  
    const program_group =
    {
      colname: 'program_group',
      colid: 'program_group_id',
    }

    if (programGroupAdSearch.length > 0){
      const program_groups = []
      programGroupAdSearch.map((e) => program_groups.push(e.value))
      program_group.id = program_groups
    } 


    const manufacturer = {
      colname: 'manufacturer',
      colid: 'manufacturer_id',
    }

    if (manufacturerAdSearch.length > 0){
      const manufacturers = []
      manufacturerAdSearch.map((e) => manufacturers.push(e.value))
      manufacturer.id = manufacturers
    } 

    const group = {
      colname: 'group',
      colid: 'group_id',
    }

    if (productGroupAdSearch.length > 0){
      const groups = []
      productGroupAdSearch.map((e) => groups.push(e.value))
      group.id = groups
    } 

    const category = {
      colname: 'category',
      colid: 'category_id',
    }
    if (productCategoryAdSearch.length > 0){
      const categorys = []
      productCategoryAdSearch.map((e) => categorys.push(e.value))
      category.id = categorys
    } 
    // if(program_group.id && manufacturer.id && group.id && category.id ) return
    try {
      const response = await api.post('/search-filters',
        [program,program_group,manufacturer,group,category]
      );
      console.log(response.data)
      if(!program_group.id || program_group.id.length === 0 || program_group.id.length === undefined){
        setProgramGroupAdSearchList(response.data.program_group)
      }
      if(!manufacturer.id || manufacturer.id.length === 0 || manufacturer.id.length === undefined){
        setManufacturerAdSearchList(response.data.manufacturer)
      }
      if(!group.id || group.id.length === 0 || group.id.length === undefined){
        setProductGroupAdSearchList(response.data.group)
      }
      if(!category.id || category.id.length === 0 || category.id.length === undefined){
        setProductCategoryAdSearchList(response.data.category)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    preencheSelects()
  },[programAdSearch,programGroupAdSearch,manufacturerAdSearch,productGroupAdSearch,productCategoryAdSearch])

  return (
    <>
      <NavBar>
        {/* <MDBToastContainer /> */}
        {/* ADD BUTTON */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi">
              Program Listing
              {getProfile(['Super Admin', 'Admin']) && (
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="d-flex d-md-none justify-content-end"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBCollapse isOpen={collapseID}>
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: 'rgba(255,255,255,0.9)',
              }}
            >
              <MDBRow className="justify-content-center">
                <MDBCol className="col-12 col-md-4">
                  <span>Program</span>
                  <Select
                    className="w-100"
                    options={addProgramList}
                    onChange={(c) => setAddProgram(c.value )}
                    // value={addProgram}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New Customer Part No"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddCustomerPartNum(e.target.value);
                    }}
                    value={addCustomerPartNum}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New Vendor Part No"
                    group
                    size="lg"
                    onChange={(e) => {
                      SetAddVendorNumber(e.target.value);
                    }}
                    value={addVendorNumber}
                  />
                 
                 
                </MDBCol>
              </MDBRow>
              <MDBRow className="justify-content-center">
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New QOH"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddQtyOnHand(e.target.value);
                    }}
                    value={addQtyOnHand}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New MIN"
                    group
                    type="number"
                    size="lg"
                    onChange={(e) => {
                      setAddMinInventory(e.target.value);
                    }}
                    value={addMinInventory}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New MAX"
                    group
                    type="number"
                    size="lg"
                    onChange={(e) => {
                      setAddMaxInventory(e.target.value);
                    }}
                    value={addMaxInventory}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow>
                {/* Default unchecked disabled */}
                <MDBCol col="12" md="3">
                  <MDBInput
                    label="New Unit Price"
                    group
                    type="number"
                    size="lg"
                    onChange={(e) => {
                      setAddUnitPrice(e.target.value);
                    }}
                    value={addUnitPrice}
                  />
                </MDBCol>
                <MDBCol col="12" md="3">
                  <MDBInput
                    label="New Unit Cost"
                    group
                    type="number"
                    size="lg"
                    onChange={(e) => {
                      setAddUnitCost(e.target.value);
                    }}
                    value={addUnitCost}
                  />
                </MDBCol>
                <MDBCol col="12" md="2">
                  <MDBInput
                    label="New Order Multiple"
                    group
                    type="number"
                    size="lg"
                    onChange={(e) => {
                      setAddOrderMultiple(e.target.value);
                    }}
                    value={addOrderMultiple}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-2">
                  <span>Replenish</span>
                  <Select
                    className="w-100"
                    options={[
                      { value: 1, label: 'Yes' },
                      { value: 0, label: 'No' },
                    ]}
                    onChange={(c) => setAddReplenish(c.value)}
                    // value={addProgram}
                  />
                </MDBCol>
                <MDBCol col="12" md="2">
                  <MDBInput
                    label="Factor"
                    group
                    type="number"
                    size="lg"
                    onChange={(e) => {
                      setAddFactor(e.target.value);
                    }}
                    value={addFactor}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={addModalFunction}
                >
                  <span className="m-0 font-weight-bold text-white">Text</span>
                </div>

                <MDBModal
                  // fullHeight
                  // position="right"
                  size="fluid"
                  backdrop={true}
                  className="modal-notify text-white"
                  isOpen={addModal}
                  toggle={addModalFunction}
                >
                  <MDBModalHeader
                    className="bg-vmi text-center"
                    tag="h5"
                    toggle={addModalFunction}
                  >
                    Insert Text
                  </MDBModalHeader>
                  <MDBModalBody className="text-center">
                    <MDBIcon
                      icon="file-text-o"
                      size="4x"
                      className="animated rotateIn "
                    />

                 
                    {loadText === true ? <MDBSpinner small /> : ''}

                    {/* {loading === true ? <Loading /> : ''} */}
                    {/* Radio */}
                    <MDBRow className="d-flex justify-content-center">
                      <MDBCol className="d-flex justify-content-center p-2">
                        <MDBInput
                          onClick={() => onType('program')}
                          checked={radio === 'program' ? true : false}
                          label="Program"
                          type="radio"
                          id="radio1"
                        />
                        <MDBInput
                          onClick={() => onType('program_group')}
                          checked={radio === 'program_group' ? true : false}
                          label="Program Group"
                          type="radio"
                          id="radio2"
                        />

                        <a className="ml-3" href={fileXlsx}>
                          Template xlsx
                        </a>
                      </MDBCol>
                    </MDBRow>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon">
                          <i className="fas fa-pencil-alt prefix"></i>
                        </span>
                      </div>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        onChange={(t) => {
                          setInputAddText(t.target.value);
                        }}
                      ></textarea>
                    </div>

                    <div
                      className="btn btn-sm shadow-5 bg-danger"
                      size="sm"
                      onClick={addModalFunction}
                    >
                      <span className="m-0 font-weight-bold text-white">
                        Cancel
                      </span>
                    </div>
                    <div
                      className="btn btn-sm shadow-5 bg-vmi"
                      size="sm"
                      onClick={() => {
                        enviaText();
                      }}
                    >
                      <span className="m-0 font-weight-bold text-white">
                        Send
                      </span>
                    </div>
                    {erroMinMax.length !== 0 ? (
                     
                     <div className="mt-3">
                       <CSVLink
                         className="btn btn-sm shadow-5 bg-warning text-dark"
                         data={erroMinMax}
                         separator={'\t'}
                         filename={'errors.csv'}
                       >
                         Download Errors
                       </CSVLink>
                     </div>
             
                
                 ) : (
                   ''
                 )}
                  </MDBModalBody>
                </MDBModal>

                <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={() => enviaDados()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                  </span>
                </div>
              </MDBRow>
            </div>
          </MDBCollapse>
        </MDBContainer>

        {/* SEARCH E SEARCH ADVANCED */}
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol className="d-flex align-items-center justify-content-center">
              <MDBInput
                className={`${
                  searchCollapseID ? 'text-danger' : ''
                }   form-control ml-3`}
                disabled={searchCollapseID}
                type="text"
                placeholder={searchCollapseID ? 'Disabled' : 'Search'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !searchCollapseID) {
                    list(1);
                  }
                }}
              />

              <MDBIcon
                onClick={() => {
                  // list com a pagina 1 NO BACKEND
                  // simpleSearch(1);
                  list(1);
                  // seta o valor da pagina NO FRONTEND
                  setPage('1');
                }}
                icon="search"
                size="2x"
                className="text-muted cursor-pointer ml-4"
              />
              <div
                className={`ml-3 btn btn-sm shadow-5  ${
                  searchCollapseID
                    ? 'btn-outline-grey grey-text'
                    : 'bg-vmi text-white'
                }  `}
                size="sm"
                onClick={() => setSearchCollapseID(!searchCollapseID)}
              >
                <span className="m-0 font-weight-bold ">
                  {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                  Advanced Search
                  <MDBIcon className="ml-3" icon="filter" />
                </span>
              </div>
            </MDBCol>
          </MDBRow>
          {/* SEARCH COMUNS   */}

          {/*ADVANCED SEARCH COLLAPSE */}
          <MDBContainer fluid>
            <MDBRow className="mb-3 px-3">
              <MDBCollapse
                isOpen={searchCollapseID}
                className="col-12"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              >
                {/* ADVANCED SEARCH */}
                <MDBRow className="h5-responsive mx-3 font-weight-bold">
                  ADVANCED SEARCH
                </MDBRow>
                <MDBRow>
                  <MDBCol size='12' md='3'>
                    <span>Programs</span>
                    {/* <Select
                    isMulti
                      className="w-100"
                      options={addProgramList}
                      isClearable
                      value={programAdSearch}
                      onChange={(e) =>setProgramAdSearch(e || [])}
                    /> */}
                     <SelectMulti 
  options={addProgramList || []}
  value={programAdSearch || []}
  onChange={(e) => setProgramAdSearch(e)}
/>
                  </MDBCol>
                  <MDBCol size='12' md='3'>
                    <span>Program Groups</span>
                    {/* <Select
                     isMulti
                      className="w-100"
                      options={programGroupAdSearchList}
                      isClearable
                      value={programGroupAdSearch}
                      onChange={(e) => setProgramGroupAdSearch(e || [])}
                    /> */}
                     <SelectMulti 
  options={programGroupAdSearchList || []}
  value={programGroupAdSearch || []}
  onChange={(e) => setProgramGroupAdSearch(e)} />
                  </MDBCol>
                  <MDBCol size='12' md='3'>
                    <MDBInput
                   
                      label="Description"
                      group
                      size="md"
                      value={descriptionAdSearch}
                      onChange={(e) => setDescriptionAdSearch(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol size='12' md='3'>
                    <span>Manufacturer</span>
                    {/* <Select
                     isMulti
                      className="w-100"
                      options={manufacturerAdSearchList}
                      isClearable={true}
                      value={manufacturerAdSearch}
                      
                      onChange={(e) => setManufacturerAdSearch(e || [])}
                    /> */}
                      <SelectMulti 
  options={manufacturerAdSearchList || []}
  value={manufacturerAdSearch}
  onChange={(e) => setManufacturerAdSearch(e)}
/>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-4 justify-content-center">
                  <MDBCol size='12' md='4'>
                    <span>Product Group</span>
                    {/* <Select
                     isMulti
                      className="w-100"
                      options={productGroupAdSearchList}
                      isClearable={true}
                      value={productGroupAdSearch}
                      onChange={(e) => {setProductGroupAdSearch(e || [])}}
                    /> */}
                     <SelectMulti 
  options={productGroupAdSearchList || []}
  value={productGroupAdSearch}
  onChange={(e) => setProductGroupAdSearch(e)}
/>
                  </MDBCol>
                  
                  {/* <MDBCol className="col-12 col-md-4">
                    <span>User</span>
                    <Select
                      className="w-100"
                      options={listUser}
                      value={dadosModal.user_id || ''}
                      onChange={(c) =>
                        setDadosModal({ ...dadosModal, user_id: c.value })
                      }
                    // defaultValue={{ label: t.TB_product_manufacturer.manufacturer, value: t.TB_product_manufacturer.id }}
                    />
                  </MDBCol> */}
                  <MDBCol size='12' md='4'>
                    <span>Product Category</span>
                    {/* <Select
                     isMulti
                      className="w-100"
                      isClearable={true}
                      options={productCategoryAdSearchList}
                      value={productCategoryAdSearch}
                      onChange={(e) => setProductCategoryAdSearch(e || [])}
                      // value={addProgram}
                    /> */}
                        <SelectMulti 
  options={productCategoryAdSearchList || []}
  value={productCategoryAdSearch}
  onChange={(e) => setProductCategoryAdSearch(e)}
/>
                  </MDBCol>

                  <MDBCol size='12' md='4'>
                    <span>Replenish: </span>
                    <Select
                      isClearable={true}
                      options={[
                        { value: 1, label: 'Yes' },
                        { value: 0, label: 'No' },
                      ]}
                      value={replenishAdSearch}
                      onChange={(e) => setReplenishAdSearch(e)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-4">
                  <MDBCol size='12' md='4'>
                    <MDBInput
                      label="Customer Part No"
                      group
                      size="md"
                      value={customerPartNumAdSearch}
                      onChange={(e) => setCustomerPartNumAdSearch(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol size='12' md='4'>
                    <MDBInput
                      label="Vendor Part No"
                      group
                      size="md"
                      value={vendorPartNumAdSearch}
                      onChange={(e) => setvendorPartNumAdSearch(e.target.value)}
                    />
                  </MDBCol>
                  {
                    listUser.length > 0 && 
                    <MDBCol className="col-12 col-md-2">
                    <span>Rep</span>
                    <Select
                     isMulti
                      className="w-100"
                      options={listUser}
                      isClearable
                      value={repAdSearch}
                      onChange={(e) => setRepAdSearch(e)}
                    />
                  </MDBCol>
                  }
                  <MDBCol col="12" md="2">
                    Status
                    <Select
                     options={[
                      {value: '1', label: 'Active'},
                      {value: '0', label: 'Inactive'},
                    ]} 
                      value={statusAdSearch}
                      onChange={(e) => setStatusAdSearch(e)}
                    
                  
                   
                    /> 
                  </MDBCol>
                </MDBRow>

                <MDBRow center>
                  <MDBCol size="12" md="4"></MDBCol>
                  <MDBCol size="6" md="4" className="text-center">
                    <div
                      className={`ml-3 btn btn-sm shadow-5 bg-vmi text-white`}
                      size="sm"
                      onClick={() => {
                        // list com a pagina 1 NO BACKEND
                        list(1);
                        // seta o valor da pagina NO FRONTEND
                        setPage('1');
                      }}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        Search
                      </span>
                    </div>
                  </MDBCol>

                  <MDBCol size="6" md="4" className="text-right">
                    <div
                      className={`ml-3 btn btn-sm shadow-5 btn-outline-grey grey-text`}
                      size="sm"
                      onClick={() => setDadosModal({})}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        clear filter
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>
        {/* HEAD FORM TO MD VISUALIZATION */}
        <MDBContainer fluid>
          <MDBRow style={{ color: '#3f5c80' }} className=" mr-4 pl-3">
            <MDBCol
              col="12"
              md="2"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                placeholder="Select Sort"
                options={[
                  { value: 'program', label: 'Program' },
                  { value: 'customer_part_num', label: 'Customer Part No' },
                  { value: 'vendor_part_num', label: 'Vendor Part No' },
                  { value: 'qty_on_hand', label: 'QOH' },
                  { value: 'min_inventory', label: 'Min' },
                  { value: 'max_inventory', label: 'Max' },
                  { value: 'unit_price', label: 'Unit Price' },
                ]}
                value={sort}
                onChange={(t) => {
                  setSort(t);
                }}
              />
            </MDBCol>
            <MDBCol
              col="12"
              md="2"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                options={[
                  { value: 'ASC', label: 'Ascending' },
                  { value: 'DESC', label: 'Descending' },
                ]}
                value={typeSort}
                placeholder="Select Type Sort"
                onChange={(t) => {
                  setTypeSort(t);
                }}
              />
            </MDBCol>

            <MDBCol size="12" md="8" className="pt-2">
              <MDBRow end>
                <MDBCol className="text-right" size="6" md="3">
                  <Select
                    // isClearable={true}

                    placeholder="Select Download"
                    options={[
                      { value: 0, label: 'Export Current Page' },
                      { value: 1, label: 'Export All' },
                    ]}
                    onChange={async (c) => {
                      if (c.value == 0) {
                     
                        setLoading(true);
                        setResponseDownload([]);
                        try {
                     
    
                          let rightDownload = [];
                          listResult.map((t) => {
                            rightDownload.push({
                              Program: t.TB_customer.program,
                              Customer_Part_No: t.customer_part_num,
                              Vendor_Part_No: t.TB_product.vendor_part_num,
                              QOH: t.qty_on_hand,
                              min_inventory: t.min_inventory,
                              max_inventory: t.max_inventory,
                              Replenish: t.replenish === 1 ? 'Yes' : 'No',
                              Description: t.TB_product.description,
                              Group: t.TB_product.TB_product_group.group,
                              Manufacturer:
                                t.TB_product.TB_product_manufacturer
                                  .manufacturer,
                              Order_Multiple: t.order_multiple,
                              Barcode: t.TB_product.bar_code,
                              Status: t.active == true ? 'Active' : 'Inactive',
                              Unit_Cost: t.unit_cost.toFixed(2),
                              Unit_Price: `${t.unit_price.toFixed(2)} ${
                                t.TB_customer.currency || ''
                              }`,
                              Category:
                                t.TB_product.TB_product_group
                                  .TB_product_category.category,
                              Factor: t.factor || 'No',
                            });
                          });

                          setResponseDownload(rightDownload);
                        } catch (error) {
                          // console.log(error);
                          toast.error('Export Current Page Error!!');
                        }

                        setLoading(false);
                      }
                      if (c.value == 1) {
                        setLoading(true);
                        setResponseDownload([]);
                        try {
                          const response = await api.get(`/customer-listings`, {
                            params: paramsDownload,
                          });
                     
                          let rightDownload = [];
                          response.data.rows.map((t) => {
                            rightDownload.push({
                              Program: t.TB_customer.program,
                              Customer_Part_No: t.customer_part_num,
                              Vendor_Part_No: t.TB_product.vendor_part_num,
                              QOH: t.qty_on_hand,
                              min_inventory: t.min_inventory,
                              max_inventory: t.max_inventory,
                              Replenish: t.replenish === 1 ? 'Yes' : 'No',
                              Description: t.TB_product.description,
                              Group: t.TB_product.TB_product_group.group,
                              Manufacturer:
                                t.TB_product.TB_product_manufacturer
                                  .manufacturer,
                              Order_Multiple: t.order_multiple,
                              Barcode: t.TB_product.bar_code,
                              Status: t.active == true ? 'Active' : 'Inactive',
                              Unit_Cost: t.unit_cost.toFixed(2),
                              Unit_Price: `${t.unit_price.toFixed(2)} ${
                                t.TB_customer.currency
                              }`,
                              Category:
                                t.TB_product.TB_product_group
                                  .TB_product_category.category,
                              Factor: t.factor || 'No',
                            });
                          });

                          setResponseDownload(rightDownload);
                        } catch (error) {
                          toast.error('Export All Error!!');
                        }

                        setLoading(false);
                      }
                    }}
                  />
                </MDBCol>

                {responseDownload.length !== 0 ? (
                  <MDBCol size="6" md="2">
                    <CsvDownloader
                      style={{ cursor: 'pointer' }}
                      className="ml-2"
                      datas={responseDownload}
                      wrapColumnChar=""
                      separator={','}
                      filename={'ProgramListings.csv'}
                    
                    >
                      <span
                        style={{ color: '#0e4e9e' }}
                        className="mr-2 font-weight-bold"
                      >
                        Download
                      </span>

                      <MDBIcon
                        style={{ color: '#0e4e9e' }}
                        icon="download"
                        size="2x"
                      />
                    </CsvDownloader>
                  </MDBCol>
                ) : (
                  ''
                )}
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between ">
              <span className="d-sm-inline font-weight-bold mr-3">
                Program:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Customer Part No:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Vendor Part No:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">QOH:</span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">MIN:</span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">MAX:</span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Replenish:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-center" />
          </MDBRow>
        </MDBContainer>
        {/* LIST TABLE */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : (
            listResult.length > 0 &&
            listResult.map((t) => (
              <div key={t.id}>
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className="mr-1 ml-1 mr-md-0 ml-md-0 p-2"
                  >
                    <div className="col-12 col-md-2 d-flex justify-content-between ">
                      <span className="d-sm-inline d-md-none mr-3 d-flex align-items-end">
                        Program:
                      </span>
                      <span>{t?.TB_customer?.program}</span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Customer Part No:
                      </span>
                      <span>{`${t?.customer_part_num}`}</span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Vendor Part No:
                      </span>
                      <span>{`${t?.TB_product?.vendor_part_num}`}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">QOH:</span>
                      <span>{`${t?.qty_on_hand}`}</span>
                    </div>

                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">MIN:</span>
                      <span>{`${t?.min_inventory}`}</span>
                    </div>

                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">MAX:</span>
                      <span>{`${t?.max_inventory}`}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Replenish:
                      </span>
                      <span>{t?.replenish === 1 ? 'Yes' : 'No'}</span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-center">
                      <MDBTooltip domElement tag="span" placement="top">
                        <span
                          style={{ color: '#079a9c' }}
                          className="font-weight-bold d-flex align-items-center"
                        >
                          <MDBIcon icon="info-circle" />
                          <span className="ml-1">Details</span>
                        </span>
                        <span>
                          Description: {t?.TB_product?.description}
                          <br />
                          Group: {t?.TB_product?.TB_product_group?.group}
                          <br />
                          Manufacturer:{' '}
                          {t?.TB_product?.TB_product_manufacturer?.manufacturer}
                          <br />
                          Order Multiple: {t?.order_multiple}
                          <br />
                          Factor: {t?.factor}
                          <br />
                          Barcode: {t?.TB_product?.bar_code}
                          <br />
                          Status: {t?.active == true ? 'Active' : 'Inactive'}
                          <br />
                          Unit Cost: {t?.unit_cost.toFixed(2)}
                          <br />
                          Unit Price: {t?.unit_price.toFixed(2)}{' '}
                          {t?.TB_customer?.currency}
                          <br />
                        </span>
                      </MDBTooltip>

                      {/* EDIT MODAL */}
                      <Modal
                        id={t.id}
                        title="Program Listings"
                        dados={editDadosModal}
                        api={`/customer-listings/${t.id}`}
                        restart={async () => await list()}
                        disabled={!getProfile(['Super Admin', 'Admin'])}
                        size="lg"
                      >
                        <MDBRow>
                            <MDBCol className="h5-responsive" col="12" md="6">
                                Creation Date:
                                {t.createdAt
                                  ? format(new Date(t.updatedAt), 'dd/MM/yyyy')
                                  : ''}
                              </MDBCol>
                              <MDBCol className="h5-responsive" col="12" md="6">
                                {t.active === true ? 'Modified Date:': <span className='text-danger'>Discontinued Date:</span>  }
                            
                                {format(new Date(t.updatedAt), ' dd/MM/yyyy')}
                              </MDBCol>
                            </MDBRow>
                        <br />
                        <MDBRow>
                          <MDBCol md="6">
                            <h6>Program: {t?.TB_customer?.program}</h6>
                          </MDBCol>

                          <MDBCol md="6">
                            <h6>
                              Vendor Part No: {t?.TB_product?.vendor_part_num}
                            </h6>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="6">
                            <MDBInput
                              style={{ fontSize: '.9rem' }}
                              label="Customer Part No"
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              group
                              size="lg"
                              valueDefault={t?.customer_part_num}
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  customer_part_num: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento

                                if (e.target.value == '') {
                                  const { customer_part_num, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="6">
                             
                            <MDBInput
                              disabled={!getProfile(['Rep','Super Admin','Admin'])}
                              style={{ fontSize: '.9rem' }}
                              label="QOH"
                              group
                              size="lg"
                              valueDefault={t.qty_on_hand}
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  qty_on_hand: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { qty_on_hand, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="6">
                            <MDBInput
                              disabled={!getProfile(['Rep','Super Admin','Admin'])}
                              style={{ fontSize: '.9rem' }}
                              label="MIN"
                              group
                              valueDefault={t.min_inventory}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  min_inventory: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { min_inventory, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="6">
                            <MDBInput
                               disabled={!getProfile(['Super Admin','Admin','Rep'])}
                              style={{ fontSize: '.9rem' }}
                              label="MAX"
                              valueDefault={t.max_inventory}
                              group
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  max_inventory: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { max_inventory, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="4">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              label="Unit Price"
                              style={{ fontSize: '.9rem' }}
                              group
                              valueDefault={t.unit_price}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  unit_price: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { unit_price, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              label="Unit Cost"
                              style={{ fontSize: '.9rem' }}
                              group
                              valueDefault={t.unit_cost}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  unit_cost: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { unit_cost, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              style={{ fontSize: '.9rem' }}
                              label="Factor"
                              group
                              size="lg"
                              valueDefault={t.factor}
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  factor: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento

                                if (e.target.value == '') {
                                  const { factor, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="4">
                            <span className="font-weight-bold">Replenish:</span>

                            <Select
                              className="w-100"
                              isDisabled={!getProfile(['Super Admin', 'Admin'])}
                              options={[
                                { value: '1', label: 'Yes' },
                                { value: '0', label: 'No' },
                              ]}
                              defaultValue={{
                                label: t.replenish === 1 ? 'Yes' : 'No',
                                value: t.replenish === 1 ? '1' : '0',
                              }}
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  replenish: e.value,
                                });

                                // se estiver vazio , retiro o elemento
                                if (e.value == '') {
                                  const { replenish, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              label="Order Multiple:"
                              group
                              style={{ fontSize: '.9rem' }}
                              type="number"
                              valueDefault={t.order_multiple}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  order_multiple: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { unit_cost, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <span className="font-weight-bold">Status:</span>

                            <Select
                              isDisabled={!getProfile(['Super Admin', 'Admin'])}
                              className="w-100"
                              options={[
                                { value: '1', label: 'Active' },
                                { value: '0', label: 'Inactive' },
                              ]}
                              defaultValue={{
                                label:
                                  t.active === true ? 'Active' : 'Inactive',
                                value: t.active === true ? '1' : '0',
                              }}
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  active: e.value,
                                });

                                // se estiver vazio , retiro o elemento
                                if (e.value == '') {
                                  const { active, ...restante } =
                                    editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                        </MDBRow>

                        {/* <MDBCol className="col-12">
              <span >User</span>
              <Select
                className="w-100"
                options={listUser}
                onChange={c=>setEditDadosModal({...editDadosModal, user_id:c.value})}
                // defaultValue={{ label: t.TB_user.first_name, value: t.TB_user.id }}

              />
            </MDBCol> */}
                        {/* <br/>

            <MDBCol className="col-12">
              <span>Product Group</span>
              <Select
                className="w-100"
                options={listCustomerGroupId}
                onChange={c=>setEditDadosModal({...editDadosModal, customer_group_id:c.value})}
                  // defaultValue={{ label: t.TB_customer_group.program_group, value: t.TB_customer_group.id}}
              />
            </MDBCol> */}

                        {/* <MDBBtn color="secondary" >Close</MDBBtn> */}
                        {/* <MDBBtn onClick={editDados} color="primary">Save changes</MDBBtn> */}
                        {/* <MDBBtn onClick={editaDados} color="primary">Save changes</MDBBtn> */}

                        {/* </form> */}
                      </Modal>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          )}
          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
        <br />
        {/* BUTTON NEXT / BACK OF PAGES */}
      </NavBar>
    </>
  );
}

// <>
// <h5>Old Group: {t.branch_name}</h5>

// <MDBInput
// label="Update Group"
// icon="clipboard"
// group
// size="lg"
// onChange={e =>{
// setDadosModal({...dadosModal, group:e.target.value})
// if(e.target.value == ''){
//   const { group , ...restante} = dadosModal
//   setDadosModal(restante)

// }

// }}

// />
// <br/>
// <div className="h5-responsive">Select new Category</div>
// <Select
// className="w-100"
// // options={options}
// onChange={c=>setDadosModal({...dadosModal, product_category_id:c.value})}
// // defaultValue={{ label: t.TB_product_category.category, value: t.TB_product_category.id }}

// />
// </>
