import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import api from '../../../services/api';
import { getData, getProfile } from '../../../services/auth';
import AsyncSelect from 'react-select/async';
import CsvDownloader from 'react-csv-downloader';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'react-select';
import SelectMulti from '../../../components/SelectMulti'
 
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
  MDBSpinner,
  MDBSelect
} from 'mdbreact';


import { InsertText } from '../../../components/InsertText'
import fileXlsx from './program.xlsx';
import { Line, Checkbox } from './styles';

export default function Customer(props) {
  const programs = getData().profile.programs;

  const [collapseID, setCollapseID] = useState(false);
  const [searchCollapseID, setSearchCollapseID] = useState(false);

  const [listGroup, setListGroup] = useState([]);
  const [listPrograms, setListPrograms] = useState([]);

  const [rows, setRows] = useState('');
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);

  const [addProgram, setAddProgram] = useState('');
  const [addBranchName, setAddBranchName] = useState('');

  const [addCustomerAccountNumber, setAddCustomerAccountNumber] = useState('');
  const [addUserId, setAddUserId] = useState('');
  const [addCustomerGroupId, setAddCustomerGroupId] = useState('');
  const [addReplanDays, setAddReplanDay] = useState('');
  const [addProgramCycleCount, setAddProgramCycleCount] = useState(null);
  const [addCurrency, setAddCurrency] = useState('');

  const [addBranchFullName, setAddBranchFullName] = useState('');
  const [branchEmail, setBranchEmail] = useState('');
  const [branchRepName, setBranchRepName] = useState('');
  const [repPbiAccount, setRepPbiAccount] = useState('');
  const [covarageWeeks, setCovarageWeeks] = useState('');
  const [covaragePeriod, setCovaragePeriod] = useState('');
  const [startOrderDate, setStartOrderDate] = useState('');
  const [stopOrderDate, setStopOrderDate] = useState('');

  const [loadDownload, setLoadDownload] = useState([]);

  const [listUser, setListUser] = useState([]);
  const [listCustomerGroupId, setListCustomerGroupId] = useState([]);

  // const [dadosModal, setDadosModal] = useState({});
  const [dadosModal, setDadosModal] = useState({});
  const [editDadosModal, setEditDadosModal] = useState({});

  // download
  const [typeDownload, setTypeDownload] = useState();
  const [paramsDownload, setParamsDownload] = useState({});
  const [responseDownload, setResponseDownload] = useState([]);
  const [sort, setSort] = useState({ value: 'program', label: 'Program' });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });
  const [customerLocationOptions, setCustomerLocationOptions] = useState([]);
  const [customerAccountNumberOptions, setCustomerAccountNumberOptions] = useState([]);

 

  const [programAdSearch, setProgramAdSearch] = useState([])
  const [branchNameAdSearch, setBranchNameAdSearch] = useState([])
  const [customerAcountNumberAdSearch, setCustomerAcountNumberAdSearch] = useState([])
  const [repAdSearch, setRepAdSearch] = useState([])
  const [programGroupAdSearch, setProgramGroupAdSearch] = useState([])
  const [replanDaysAdSearch, setReplanDaysAdSearch] = useState([])
  const [programCycleCountAdSearch, setProgramCycleCountAdSearch] = useState(null)
  const [statusAdSearch, setStatusAdSearch] = useState(null)

 
  const [branchNameAdSearchList, setBranchNameAdSearchList] = useState([])
  const [customerAcountNumberAdSearchList, setCustomerAcountNumberAdSearchList] = useState([])
  const [repAdSearchList, setRepAdSearchList] = useState([])
  const [programGroupAdSearchList, setProgramGroupAdSearchList] = useState([])
  const [replanDaysAdSearchList, setReplanDaysAdSearchList] = useState([])
  const formatDate = (date) => {
    if (!date) return '';  // Verifica se a data existe
    return format(new Date(date), 'yyyy-MM-dd');  // Formata a data para YYYY-MM-DD
  };

  const verifyReplay = (replans_days) => {
    if (replans_days === 1) return 'Sunday';
    if (replans_days === 2) return 'Monday';
    if (replans_days === 3) return 'Tuesday';
    if (replans_days === 4) return 'Wednesday';
    if (replans_days === 5) return 'Thursday';
    if (replans_days === 6) return 'Friday';
    if (replans_days === 7) return 'Saturday';
  };

  const verifyCycle = (program_cycle_count) => {
    if (program_cycle_count === null) return 'No';
    if (program_cycle_count === 0) return 'Weekly';
    if (program_cycle_count === 1) return 'Bimonthly W1+W3';
    if (program_cycle_count === 2) return 'Bimonthly W2+W4';
    if (program_cycle_count === 3) return 'Monthly';
  };

  // carrega as listas para escolher os inputs
  const carregaListas = async () => {
    const selectProgram = [];
    programs.forEach((pro) => {
      selectProgram.push({ label: pro.program, value: pro.id });
    });
    setListPrograms(selectProgram);
    // load manufacturer in select
    const responseUser = await api.get(`/profile-user`);
    // setListUser(responseUser.data.rows);

    // // map list manufacturer to options of select
    let listagemUser = [];
    responseUser.data.map((c) => {
      listagemUser.push({
        value: c.id,
        label: `${c.first_name} ${c.last_name}`,
      });
    });
    setListUser(listagemUser);

    // // load product group in select
    const responseCustomerGroupId = await api.get(`/customer-group?p=99999999`);
    // setListCustomerGroupId(responseCustomerGroupId.data.rows);

    // map list product group to options of select
    let listaCustomerGroupId = [];
    responseCustomerGroupId.data.rows.map((c) => {
      listaCustomerGroupId.push({ value: c.id, label: c.program_group });
    });
    setListCustomerGroupId(listaCustomerGroupId);

    const customerLocation = await api.get('/customer')

    const fetchedOptions = customerLocation.data.rows.map((branchName) => ({
      label: branchName.branch_name,
      value: branchName.branch_name,
    }));
    setCustomerLocationOptions(fetchedOptions)

    const fetchedcustomerOptions = customerLocation.data.rows.map((item) => ({
      label: item.customer_account_number,
      value: item.customer_account_number,
    }));
    setCustomerAccountNumberOptions(fetchedcustomerOptions);
  };

  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  const searchCollapse = () => {
    setSearchCollapseID(!searchCollapseID);

    setDadosModal({});
  };




  useEffect(() => {
    carregaListas();
  }, []);
  useEffect(() => {
    list(1);
  }, [quantity, sort, typeSort]);

  const enviaDados = async (e) => {
    if (
      !addProgram ||
      addCustomerAccountNumber === '' ||
      addBranchName === '' ||
      addReplanDays === '' ||
      addUserId === '' ||
      addCustomerGroupId === '' ||
      addReplanDays === '' ||
      !addCurrency
    ) {
      toast.error('Complete the fields!');
    }

    try {
      const data = {
        program: addProgram,
        customer_account_number: addCustomerAccountNumber,
        branch_name: addBranchName,

        replan_days: addReplanDays,
        id_user: addUserId,
        customer_group_id: addCustomerGroupId,
        currency: addCurrency,
        branch_full_name: addBranchFullName,

        branch_email: branchEmail,
        branch_rep_name: branchRepName,
        rep_pbi_account: repPbiAccount,
        covarage_weeks: covarageWeeks,
        covarage_period: covaragePeriod,
        start_order_date: startOrderDate,
        stop_order_date: stopOrderDate,
      };
      
      if (addProgramCycleCount !== null) {
        data.program_cycle_count = addProgramCycleCount;
      }

      const result = await api.post('/customer', data);
      if (result.data.erro) {
        toast.error(result.data.erro);
        return;
      }
      // "replan_days":"1",
      // "program_cycle_count":"true",

      // setAddProgram('');
      // setAddBranchName('');
      // setAddCustomerAccountNumber('');
      // setAddUserId('');
      // setAddCustomerGroupId('');
      list();

      toast.success('Added Successfully!');
    } catch (error) {
      console.log(error);
      toast.error('Error!');
      // toast.error('Error');
    }
  };

 

  const list = async (npage = page) => {
    setLoading(true);
    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
    window.scrollTo(0, 0);
    const dados = {}
    
    if(searchCollapseID){
     

      if (programAdSearch.length > 0) {
        dados.program = programAdSearch.map(item => item.value);
      }

      if (branchNameAdSearchList.length > 0) {
        dados.branch_name = branchNameAdSearch.map(item => item.value);
      
      }

      if (customerAcountNumberAdSearch.length > 0) {
        dados.customer_part_num = customerAcountNumberAdSearch.map(item => item.value);
        
      }

      if (repAdSearch.length > 0) {
        dados.user_id = repAdSearch.map(item => item.value);
        
      }

      if (programGroupAdSearch.length > 0) {
        dados.program_group = programGroupAdSearch.map(item => item.value);
      }
     
      if (replanDaysAdSearch.length > 0) {
        dados.replan_days = replanDaysAdSearch.map(item => item.value);
      }

      if (programCycleCountAdSearch !== null) {
        dados.program_cycle_count = programCycleCountAdSearch.value;
      }
      if (statusAdSearch !== null) {
        dados.active = statusAdSearch.value;
      }
      // if (vendorPartNumAdSearch !== null) {
      //   dados.vendor_part_num = vendorPartNumAdSearch;
      // }
      
      // if (statusAdSearch !== null) {
      //   dados.active = statusAdSearch.value;
      // }
      
    }else{
       if(search !== null){
      dados.search = search
      }
    }
    const response = await api.get(`/customer`, {
      params: {
        pag: npage,
        p: quantity,
        ...dados,
        sort: sort.value,
        typeSort: typeSort.value,
        // branch_name: dadosModal.branch_name,
        // program: dadosModal.program,
        // customer_account_number: dadosModal.customer_account_number,
        // user_id: dadosModal.user_id,
        // customer_group_id: dadosModal.customer_group_id,
        // replan_days:dadosModal.replan_days,
      },
    });
   

    setListGroup(response.data.rows);
    setRows(response.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
    setLoading(false);
    setParamsDownload({
      pag: npage,
      p: 999999999999999,
      ...dadosModal,
      sort: sort.value,
      typeSort: typeSort.value,
    });
    // setDadosModal({});
    setTypeDownload('advanced');
    setLoadDownload(response.data.rows);
  };


  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };

  const findSelectedOption = (options, value) => {
    if (!options || !value) return null;
    return options.find((option) => option.value === value) || null;
  };


 


  const preencheSelects = async () => {
    // Array de condições que será enviado no corpo da requisição
    const conditions = [];
  
    // Verificar e adicionar o filtro para 'program'
    if (programAdSearch.length > 0) {
      conditions.push({
        colname: 'program',
        colid: 'program_id',
        id: programAdSearch.map(e => e.value), // Adiciona os IDs selecionados
      });
    } else {
      const id = []
      programs.forEach((pro) => {
        id.push(pro.id);
      });
      conditions.push({
        colname: 'program',
        colid: 'program_id', // Só retorna os valores, sem id para filtragem
        id
      });
    }
  
    // Verificar e adicionar o filtro para 'branch_name'
    if (branchNameAdSearch.length > 0) {
      conditions.push({
        colname: 'branch_name',
        colid: 'branch_name',
        id: branchNameAdSearch.map(e => e.value),
      });
    } else {
      conditions.push({
        colname: 'branch_name',
        colid: 'branch_name', // Retorna os valores para preenchimento do select
      });
    }
  
    // Verificar e adicionar o filtro para 'customer_account_number'
    if (customerAcountNumberAdSearch.length > 0) {
      conditions.push({
        colname: 'customer_part_num',
        colid: 'customer_part_num',
        id: customerAcountNumberAdSearch.map(e => e.value),
      });
    } else {
      conditions.push({
        colname: 'customer_part_num',
        colid: 'customer_part_num',
      });
    }
  
    // Verificar e adicionar o filtro para 'rep_user' (usuário representante)
    if (repAdSearch.length > 0) {
      conditions.push({
        colname: 'user_id',
        colid: 'user_id',
        id: repAdSearch.map(e => e.value),
      });
    } else {
      conditions.push({
        colname: 'first_name',
        colid: 'user_id',
      });
    }
  // Verificar e adicionar o filtro para 'program_group'
  if (replanDaysAdSearch.length > 0) {
    conditions.push({
      colname: 'replan_days',
      colid: 'replan_days',
      id: replanDaysAdSearch.map(e => e.value),
    });
  } else {
    conditions.push({
      colname: 'replan_days',
      colid: 'replan_days',
    });
  }
 
    // Verificar e adicionar o filtro para 'program_group'
    if (programGroupAdSearch.length > 0) {
      conditions.push({
        colname: 'program_group',
        colid: 'program_group_id',
        id: programGroupAdSearch.map(e => e.value),
      });
    } else {
      conditions.push({
        colname: 'program_group',
        colid: 'program_group_id',
      });
    }

    // Fazer a requisição para o backend com o array de condições
    try {
      const response = await api.post('/search-filters', conditions);

  
  
      if (!replanDaysAdSearch.length) {
        const formattedReplanDays = response.data.replan_days.map(item => ({
          value: item.value, // Ensure this is the correct value from your data
          label: verifyReplay(item.label), // Convert label to string if necessary
        }));
        setReplanDaysAdSearchList(formattedReplanDays);
      }

    
      if (!branchNameAdSearch.length) {
        setBranchNameAdSearchList(response.data.branch_name);
      }
      if (!customerAcountNumberAdSearch.length) {
        setCustomerAcountNumberAdSearchList(response.data.customer_part_num);
      }
      // if (!repAdSearch.length) {
      //   console.log(' tem usuarios')
      //   setRepAdSearchList(response.data.user_id);
      // }

      if (!repAdSearch.length) {
   
        setRepAdSearchList(response.data.first_name);
      }
      if (!programGroupAdSearch.length) {
        setProgramGroupAdSearchList(response.data.program_group);
      }
    } catch (error) {
      console.error('Erro ao preencher selects:', error);
    }
  };
  

  useEffect(() => {
    preencheSelects();
  }, [programAdSearch, branchNameAdSearch, customerAcountNumberAdSearch, repAdSearch,replanDaysAdSearch, programGroupAdSearch]);
  

  
  return (
    <>
      <NavBar>
        {/* ADD BUTTON */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi">
              Program
              {getProfile(['Super Admin', 'Admin']) && (
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="d-flex d-md-none justify-content-end"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBCollapse isOpen={collapseID}>
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: 'rgba(255,255,255,0.9)',
              }}
              className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center align-items-center"
            >
              <MDBRow className="justify-content-center">
                <MDBCol size="12" md="4">
                  <MDBInput
                    label="New Program"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddProgram(e.target.value);
                    }}
                    value={addProgram}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New Customer Location"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddBranchName(e.target.value);
                    }}
                    value={addBranchName}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="Customer ERP A/C No"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddCustomerAccountNumber(e.target.value);
                    }}
                    value={addCustomerAccountNumber}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="text"
                    label="Branch FullName"
                    onChange={(e) => setAddBranchFullName(e.target.value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    type="email"
                    label="Branch Email"
                    onChange={(e) => setBranchEmail(e.target.value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    type="text"
                    label="Branch RepName"
                    onChange={(e) => setBranchRepName(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="text"
                    label="Rep Pbi Account"
                    onChange={(e) => setRepPbiAccount(e.target.value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    type="number"
                    label="Covarage Weeks"
                    onChange={(e) => setCovarageWeeks(e.target.value)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    label="Covarage Period"
                    type="number"
                    onChange={(e) => setCovaragePeriod(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <span>Replan Days</span>
                  <Select
                    className="w-100"
                    options={[
                      { value: '1', label: 'Sunday' },
                      { value: '2', label: 'Monday' },
                      { value: '3', label: 'Tuesday' },
                      { value: '4', label: 'Wednesday' },
                      { value: '5', label: 'Thursday' },
                      { value: '6', label: 'Friday' },
                      { value: '7', label: 'Saturday' },
                    ]}
                    onChange={(c) => setAddReplanDay(c.value)}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <span>Program Group</span>
                  <Select
                    className="w-100"
                    options={listCustomerGroupId}
                    onChange={(c) => setAddCustomerGroupId(c.value)}
                  />
                </MDBCol>
                <MDBCol className="md-form">
                  <input
                    className="form-control"
                    type="date"
                    label="Start Order Date"
                    onChange={(e) => setStartOrderDate(e.target.value)}
                  />
                  <label for="form1">Start Order Date</label>
                </MDBCol>
                <MDBCol className="md-form">
                  <input
                    className="form-control"
                    type="date"
                    label="Stop Order Date"
                    onChange={(e) => setStopOrderDate(e.target.value)}
                  />
                  <label for="form2">Stop Order Date</label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-12 col-md-4">
                  <span>User</span>
                  <Select
                    className="w-100"
                    options={listUser}
                    onChange={(c) => setAddUserId(c.value)}
                  />
                </MDBCol>
                {/* Default unchecked disabled */}
                <MDBCol col="12" md="2">
                  <span>Program Cycle </span>
                  <Select
                    options={[
                      { value: null, label: 'No' },
                      { value: 0, label: 'Weekly' },
                      { value: 1, label: 'Bimonthly W1+W3' },
                      { value: 2, label: 'Bimonthly W2+W4' },
                      { value: 3, label: 'Monthly' },
                    ]}
                    onChange={(c) => setAddProgramCycleCount(c.value)}
                  />
                </MDBCol>
                <MDBCol col="12" md="2">
                  <span>Currency </span>
                  <Select
                    options={[
                      { value: 'EUR', label: 'EUR' },
                      { value: 'USD', label: 'USD' },
                    ]}
                    onChange={(c) => setAddCurrency(c.value)}
                  />
                </MDBCol>

              </MDBRow>
              <MDBRow center className='mt-5'>

                <InsertText
                  fileTemplate={fileXlsx}
                  apiUrl="/txt-program"
                  showRadioButtons={false}
                  onRadioChange={(type) => console.log(`Radio changed to: ${type}`)} // Comportamento dinâmico
                />

                <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={() => enviaDados()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                  </span>
                </div>

              </MDBRow>
            </div>
          </MDBCollapse>
        </MDBContainer>
               
        {/* SEARCH E SEARCH ADVANCED */}
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol className="d-flex align-items-center justify-content-center">
              <MDBInput
                className={`${searchCollapseID ? 'text-danger' : ''
                  }   form-control ml-3`}
                disabled={searchCollapseID}
                // options={options}
                // placeholder="teste"
                type="text"
                placeholder={searchCollapseID ? 'Disabled' : 'Program'}
                value={search}
                // aria-label="Search"
                onChange={(e) => setSearch(e.target.value )}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !searchCollapseID) {
                    list(1);
                  }
                }}
              />

              <MDBIcon
                onClick={() => {
                  // list com a pagina 1 NO BACKEND
                  // simpleSearch(1);
                  list(1);
                  // seta o valor da pagina NO FRONTEND
                  setPage('1');
                }}
                icon="search"
                size="2x"
                className="text-muted cursor-pointer ml-4"
              />
              <div
                className={`ml-3 btn btn-sm shadow-5  ${searchCollapseID
                    ? 'btn-outline-grey grey-text'
                    : 'bg-vmi text-white'
                  }  `}
                size="sm"
                onClick={() => searchCollapse()}
              >
                <span className="m-0 font-weight-bold ">
                  {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                  Advanced Search
                  <MDBIcon className="ml-3" icon="filter" />
                </span>
              </div>
            </MDBCol>
          </MDBRow>
          {/* SEARCH COMUNS   */}

          {/*ADVANCED SEARCH COLLAPSE */}
          <MDBContainer fluid>
            <MDBRow className="mb-3 px-3">
              <MDBCollapse
                isOpen={searchCollapseID}
                className="col-12"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              >
                {/* ADVANCED SEARCH */}
                <MDBRow className="h5-responsive mx-3 font-weight-bold">
                  ADVANCED SEARCH
                </MDBRow>
                <MDBRow>
                  <MDBCol className="col-12 col-md-4">
                    <span>Programs</span>
                    {/* <Select
                    isMulti
                      className="w-100"
                      options={listPrograms}
                      isClearable
                      value={programAdSearch}
                      onChange={(e) => setProgramAdSearch(e || [])}
                    /> */}

<SelectMulti 
  options={listPrograms || []}
  value={programAdSearch || []}
  onChange={(e) => setProgramAdSearch(e)}
/>

                    {/*
                    <MDBInput
                      label="Program"
                      group
                      size="lg"
                      value={dadosModal.program || ''}

                    /> */}
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <span>Customer Location</span>
                    {/* <Select
                    isMulti
                      className="w-100"
                      options={branchNameAdSearchList}
                      value={branchNameAdSearch}
                      onChange={(e) => setBranchNameAdSearch(e || [])}
                      isClearable
                    />
                  */}
                  <SelectMulti 
  options={branchNameAdSearchList || []}
  value={branchNameAdSearch || []}
  onChange={(e) => setBranchNameAdSearch(e)}
/>
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <span>Customer ERP A/C No</span>
                    <Select
                    isMulti
                      className="w-100"
                      options={customerAcountNumberAdSearchList}
                      value={customerAcountNumberAdSearch}
                      onChange={(e) => setCustomerAcountNumberAdSearch(e || [])}
                      isClearable
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-4 justify-content-center">
                  <MDBCol className="col-12 col-md-4">
                    <span>Replan Days</span>
                 
                   
   
  

<SelectMulti 
  options={replanDaysAdSearchList || []}
  value={replanDaysAdSearch || []}
  onChange={(e)=>setReplanDaysAdSearch(e)}
/>


                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <span>Rep</span>
                    <Select
                    isMulti
                      className="w-100"
                      options={repAdSearchList}
                      isClearable
                      value={repAdSearch}
                      onChange={(c) => setRepAdSearch(c || [])}
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <span>Program Group</span>
                    <Select
                    isMulti
                      className="w-100"
                      options={programGroupAdSearchList}
                      isClearable
                      value={programGroupAdSearch}
                      onChange={(c) => setProgramGroupAdSearch(c || [])
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mb-4'>
                  <MDBCol col="12" md="2">
                    <span>Program Cycle: </span>
                    <Select
                      options={[
                        { value: null, label: 'No' },
                        { value: 0, label: 'Weekly' },
                        { value: 1, label: 'Bimonthly W1+W3' },
                        { value: 2, label: 'Bimonthly W2+W4' },
                        { value: 3, label: 'Monthly' },
                      ]}
                      value={programCycleCountAdSearch}
                      isClearable
                      onChange={(c) => setProgramCycleCountAdSearch(c)}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="2">
                    Status
                    <Select options={[
                      { value: true, label: 'Active' },
                      { value: false, label: 'Inactive' },
                    ]}
                    value={statusAdSearch}
                      onChange={(c) => setStatusAdSearch(c)}
                    />
                  </MDBCol>

                  <MDBCol
                    middle
                    className="col-12 col-md-4 d-flex align-items-center justify-content-center"
                  >
                    <div
                      className={`ml-3 btn btn-sm shadow-5 bg-vmi text-white`}
                      size="sm"
                      onClick={() => {
                        // list com a pagina 1 NO BACKEND
                        list(1);
                        // seta o valor da pagina NO FRONTEND
                        setPage('1');
                      }}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        Search
                      </span>
                    </div>

                    {/* <MDBBtn
              className="mt-4"
              style={{height:"35px", width:"200px"}}
              outlin
              color="danger"
              rounded
              size="sm"
              type="submit"
              onClick={()=> {
                console.log(dadosModal)
                // list com a pagina 1 NO BACKEND
                list(1)
                // seta o valor da pagina NO FRONTEND
                setPage('1')


              }}
              >
              Clear
              </MDBBtn>
 */}
                  </MDBCol>
                  <MDBCol className="text-right">
                    <div
                      className={`ml-3 btn btn-sm shadow-5 btn-outline-grey grey-text`}
                      size="sm"
                      onClick={() => setDadosModal({})}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        clear filter
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>

        {/* HEAD FORM TO MD VISUALIZATION */}
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol size="12" md="6">
              <MDBRow>
                <MDBCol size="12" md="4" className="my-1">
                  <Select
                    placeholder="Select Sort"
                    options={[
                      { value: 'program', label: 'Program' },
                      {
                        value: 'customer_account_number',
                        label: 'Customer Account No',
                      },
                    ]}
                    value={sort}
                    onChange={(t) => {
                      setSort(t);
                    }}
                  />
                </MDBCol>
                <MDBCol size="12" md="4" className="my-1">
                  <Select
                    options={[
                      { value: 'ASC', label: 'Ascending' },
                      { value: 'DESC', label: 'Descending' },
                    ]}
                    placeholder="Select Type Sort"
                    value={typeSort}
                    onChange={(t) => {
                      setTypeSort(t);
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol size="12" md="6" className="p-2 mt-1 px-3 ">
              <MDBRow end>
                <MDBCol size="6" md="4">
                  <Select
                    className="w-100"
                    placeholder="Select Download"
                    options={[
                      { value: 0, label: 'Export Current Page' },
                      { value: 1, label: 'Export All' },
                    ]}
                    onChange={async (c) => {
                      let rightDownload = [];
                      let resultValues = [];
                      if (c.value == 0) {
                        setLoading(true);
                        setResponseDownload([]);
                        const listing = await api.get(`/customer`, {
                          params: {
                            pag: page,
                            p: quantity,
                            ...dadosModal,
                            // sort,
                            // typeSort,
                          },
                        });
                        resultValues = listing.data.rows;
                      }
                      if (c.value == 1) {
                        setLoading(true);
                        setResponseDownload([]);
                        const listing = await api.get(`/customer`, {
                          params: paramsDownload,
                        });
                        resultValues = listing.data.rows;
                      }
                      const result = [];
                      resultValues.forEach((element) => {
                        result.push({
                          program: element.program,
                          'Customer ERP A/C No':
                            element.customer_account_number,
                          'Customer Location': element.branch_name,
                          'Program Group':
                            element.TB_customer_group.program_group,
                          'Replans Days': verifyReplay(element.replan_days),

                          Status:
                            element.active === true ? 'Active' : 'Inactive',
                          branch_full_name: element.branch_full_name,
                          currency: element.currency,
                          branch_email: element.branch_email,
                          branch_rep_name: element.branch_rep_name,
                          rep_pbi_account: element.rep_pbi_account,
                          covarage_weeks: element.covarage_weeks,
                          covarage_period: element.covarage_period,
                          start_order_date: element.start_order_date,
                          stop_order_date: element.stop_order_date,
                          'Program Cycle': verifyCycle(
                            element.program_cycle_count
                          ),
                        });
                      });

                      // resultValues.map((t) => {
                      //   rightDownload.push({
                      //     program_group: t.program_group,

                      //     supplier: t.TB_inventory_supplier.supplier,
                      //   });
                      // });

                      setResponseDownload(result);
                      setLoading(false);
                    }}
                  />
                </MDBCol>

                {responseDownload.length !== 0 ? (
                  <MDBCol size="6" md="3">
                    <CsvDownloader
                      style={{ cursor: 'pointer', width: '200px' }}
                      className="ml-2"
                      datas={responseDownload}
                      wrapColumnChar=""
                      separator={','}
                      filename={'Programs.csv'}

                    >
                      <span
                        style={{ color: '#0e4e9e' }}
                        className="mr-2 font-weight-bold"
                      >
                        Download
                      </span>

                      <MDBIcon
                        style={{ color: '#0e4e9e' }}
                        icon="download"
                        size="2x"
                      />
                    </CsvDownloader>
                  </MDBCol>
                ) : (
                  ''
                )}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Program:
              </span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Customer ERP A/C No:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Customer Location:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Program Group:
              </span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Replans Days:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Program Cycle:
              </span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Status:</span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center"></div>
          </MDBRow>
        </MDBContainer>
        {loading === true ? (
          <MDBRow className="mt-5" center>
            <MDBSpinner size="lg" role="status" tag="span" />
          </MDBRow>
        ) : (
          <MDBContainer fluid style={{ borderRadius: '100px' }}>
            {listGroup == ''
              ? ''
              : listGroup.map((t) => (
                <div key={t.id}>
                  {/* ALTER VALUE SETDADOS FOR DB */}
                  <Line>
                    <MDBRow
                      between
                      style={{ borderBottom: '1px solid silver' }}
                      className="mr-1 ml-1 mr-md-0 ml-md-0 p-2"
                    >
                      <div className="col-12 col-md-2 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Program:
                        </span>
                        <span className="ml-3">{`${t.program}`}</span>
                      </div>

                      <div className="col-12 col-md-1 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Customer ERP A/C No:
                        </span>
                        <span className="ml-3">{`${t.customer_account_number}`}</span>
                      </div>

                      <div className="col-12 col-md-2 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Customer Location:
                        </span>
                        <span className="ml-3">{`${t.branch_name}`}</span>
                      </div>
                      <div className="col-12 col-md-2 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Program Group:
                        </span>
                        <span className="ml-3">
                          {t.TB_customer_group.program_group}
                        </span>
                        {/* <span className="ml-3">{t.updatedAt.split('T')[0]}</span> */}
                        {/* {teste[0]} */}
                      </div>
                      <div className="col-12 col-md-1 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Replans Days:
                        </span>

                        <span className="ml-3">
                          {(t.replan_days === 1 && 'Sunday') ||
                            (t.replan_days === 2 && 'Monday') ||
                            (t.replan_days === 3 && 'Tuesday') ||
                            (t.replan_days === 4 && 'Wednesday') ||
                            (t.replan_days === 5 && 'Thursday') ||
                            (t.replan_days === 6 && 'Friday') ||
                            (t.replan_days === 7 && 'Saturday')}
                        </span>
                      </div>
                      <div className="col-12 col-md-2 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Program Cycle:
                        </span>
                        <span className="ml-3">
                          {(t.program_cycle_count === null && 'No') ||
                            (t.program_cycle_count === 0 && 'Weekly') ||
                            (t.program_cycle_count === 1 &&
                              'Bimonthly W1+W3') ||
                            (t.program_cycle_count === 2 &&
                              'Bimonthly W2+W4') ||
                            (t.program_cycle_count === 3 && 'Monthly')}
                        </span>
                      </div>

                      <div className="col-12 col-md-1 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Status:
                        </span>
                        <span className="ml-3">
                          {t.active === true ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                      <div className="col-12 col-md-1 d-flex justify-content-center">
                        {/* EDIT MODAL */}
                        <Modal
                          id={t.id}
                          title="Program details"
                          dados={editDadosModal}
                          api={`/customer/${t.id}`}
                          restart={async () => await list()}
                          size="lg"
                          disabled={!getProfile(['Super Admin', 'Admin'])}
                        >
                          <MDBRow>
                            <MDBCol className="h4-responsive" col="12" md="6">
                              Creation Date:
                              {t.createdAt
                                ? format(new Date(t.createdAt), 'dd/MM/yyyy')
                                : ''}
                            </MDBCol>
                            <MDBCol className="h4-responsive" col="12" md="6">
                              {t.active === true ? 'Modified Date:' : <span className='text-danger'>Discontinued Date:</span>}

                              {format(new Date(t.updatedAt), ' dd/MM/yyyy')}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Program"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                size="lg"
                                valueDefault={t.program}
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    program: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento

                                  if (e.target.value == '') {
                                    const { program, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Customer ERP A/C No"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                size="lg"
                                valueDefault={t.customer_account_number}
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    customer_account_number: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const {
                                      customer_account_number,
                                      ...restante
                                    } = editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Customer Location"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                valueDefault={t.branch_name}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    branch_name: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { branch_name, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <span>Program Group</span>
                              <Select
                                className="w-100"
                                isDisabled={
                                  !getProfile(['Super Admin', 'Admin'])
                                }
                                options={listCustomerGroupId}
                                onChange={(c) =>
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    customer_group_id: c.value,
                                  })
                                }
                                defaultValue={{
                                  label: t.TB_customer_group
                                    ? t.TB_customer_group.program_group
                                    : 'without values',
                                  value: t.TB_customer_group
                                    ? t.TB_customer_group.id
                                    : 'without values',
                                }}
                              // defaultValue={{ label: t.TB_customer_group.program_group, value: t.TB_customer_group.id}}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBCol className="col-12">
                                <span>Currency</span>
                                <Select
                                  className="w-100"
                                  isDisabled={
                                    !getProfile(['Super Admin', 'Admin'])
                                  }
                                  options={[
                                    { value: 'EUR', label: 'EUR' },
                                    { value: 'USD', label: 'USD' },
                                    { value: 'GBP', label: 'GBP' },
                                  ]}
                                  defaultValue={{
                                    label: t.currency
                                      ? t.currency
                                      : 'without values',
                                    value: t.currency
                                      ? t.currency
                                      : 'without values',
                                  }}
                                  onChange={(c) =>
                                    setEditDadosModal({
                                      ...editDadosModal,
                                      currency: c.value,
                                    })
                                  }
                                // defaultValue={{ label: t.TB_customer_group.program_group, value: t.TB_customer_group.id}}
                                />
                              </MDBCol>
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <span>REPLAN DAY</span>
                              <Select
                                className="w-100"
                                isDisabled={
                                  !getProfile(['Super Admin', 'Admin'])
                                }
                                options={[
                                  { value: '1', label: '1 - Sunday' },
                                  { value: '2', label: '2 - Monday' },
                                  { value: '3', label: '3 - Tuesday' },
                                  { value: '4', label: '4 - Wednesday' },
                                  { value: '5', label: '5 - Thursday' },
                                  { value: '6', label: '6 - Friday' },
                                  { value: '7', label: '7 - Saturday' },
                                ]}
                                onChange={(c) =>
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    replan_days: c.value,
                                  })
                                }
                                defaultValue={{
                                  label: t.replan_days
                                    ? (t.replan_days &&
                                      t.replan_days === 1 &&
                                      '1 - Sunday') ||
                                    (t.replan_days === 2 && '2 - Monday') ||
                                    (t.replan_days === 3 &&
                                      '3 - Tuesday') ||
                                    (t.replan_days === 4 &&
                                      '4 - Wednesday') ||
                                    (t.replan_days === 5 &&
                                      '5 - Thursday') ||
                                    (t.replan_days === 6 && '6 - Friday') ||
                                    (t.replan_days === 7 && '7 - Saturday')
                                    : 'No',
                                  value: t.program_cycle_count
                                    ? t.program_cycle_count
                                    : 'without values',
                                }}

                              // defaultValue={{
                              //   label: t.replan_days
                              //     ? t.replan_days
                              //     : 'without values',
                              //   value: t.replan_days
                              //     ? t.replan_days
                              //     : 'without values',
                              // }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <br />
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBCol className="col-12">
                                <span>Program Cycle</span>
                                <Select
                                  className="w-100"
                                  isDisabled={
                                    !getProfile(['Super Admin', 'Admin'])
                                  }
                                  options={[
                                    { value: null, label: 'No' },
                                    { value: 0, label: 'Weekly' },
                                    { value: 1, label: 'Bimonthly W1+W3' },
                                    { value: 2, label: 'Bimonthly W2+W4' },
                                    { value: 3, label: 'Monthly' },
                                  ]}
                                  onChange={(c) =>
                                    setEditDadosModal({
                                      ...editDadosModal,
                                      program_cycle_count: c.value,
                                    })
                                  }
                                  defaultValue={{
                                    label: t.program_cycle_count
                                      ? (t.program_cycle_count &&
                                        t.program_cycle_count === 0 &&
                                        'Weekly') ||
                                      (t.program_cycle_count === 1 &&
                                        'Bimonthly W1+W3') ||
                                      (t.program_cycle_count === 2 &&
                                        'Bimonthly W2+W4') ||
                                      (t.program_cycle_count === 3 &&
                                        'Monthly')
                                      : 'No',
                                    value: t.program_cycle_count
                                      ? t.program_cycle_count
                                      : '0',
                                  }}
                                />
                                {editDadosModal.program_cycle_count}
                              </MDBCol>
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <span>Status</span>
                              <Select
                                className="w-100"
                                isDisabled={
                                  !getProfile(['Super Admin', 'Admin'])
                                }
                                options={[
                                  { value: 1, label: 'Active' },
                                  { value: 0, label: 'Inactive' },
                                ]}
                                defaultValue={{
                                  label:
                                    t.active === true ? 'Active' : 'Inactive',
                                  value: t.active === true ? true : false,
                                }}
                                // onChange={c=>setEditDadosModal({...editDadosModal, replan_days:c.value})}
                                onChange={(c) => {

                                  // se estiver vazio , retiro o elemento
                                  if (
                                    c.value !== '' &&
                                    c.value !== undefined
                                  ) {
                                    setEditDadosModal({
                                      ...editDadosModal,
                                      active: c.value,
                                    });
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Branch Full Name"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                valueDefault={t.branch_full_name}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    branch_full_name: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { branch_full_name, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Branch Email"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                valueDefault={t.branch_email}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    branch_email: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { branch_email, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Branch Rep Name"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                valueDefault={t.branch_rep_name}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    branch_rep_name: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { branch_rep_name, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Rep Pbi account"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                valueDefault={t.rep_pbi_account}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    rep_pbi_account: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { rep_pbi_account, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Covarage Weeks"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                type="number"
                                valueDefault={t.covarage_weeks}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    covarage_weeks: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { covarage_weeks, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Covarage Period"
                                group
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                type="number"
                                valueDefault={t.covarage_period}
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    covarage_period: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { covarage_period, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Start Order Date"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                type="date"
                                valueDefault={formatDate(t.start_order_date)} 
                           
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    start_order_date: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { start_order_date, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                           
                            <MDBCol className="col-12 col-md-6">
                              <MDBInput
                                label="Stop Order Date"
                                disabled={!getProfile(['Super Admin', 'Admin'])}
                                group
                                type="date"
                                valueDefault={formatDate(t.stop_order_date)} 
                                size="lg"
                                onChange={(e) => {
                                  setEditDadosModal({
                                    ...editDadosModal,
                                    stop_order_date: e.target.value,
                                  });
                                  // se estiver vazio , retiro o elemento
                                  if (e.target.value == '') {
                                    const { stop_order_date, ...restante } =
                                      editDadosModal;
                                    setEditDadosModal(restante);
                                  }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <br />

                          <br />

                          {/* <MDBModalFooter> */}
                          {/* <MDBBtn color="secondary" >Close</MDBBtn> */}
                          {/* <MDBBtn  color="primary">Save changes</MDBBtn> */}
                          {/* <MDBBtn onClick={editaDados} color="primary">Save changes</MDBBtn> */}
                          {/* </MDBModalFooter> */}

                          {/* </form> */}
                        </Modal>
                      </div>
                    </MDBRow>
                  </Line>
                </div>
              ))}
            <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
              <div className="font-weight-bold">
                Rows: <span className="ml-1">{rows}</span>
              </div>

              <div className="font-weight-bold">
                Rows per page:
                <select
                  className="muted-text ml-2 mr-5"
                  style={{
                    width: '100px',
                    height: '25px',
                    border: '1px solid muted',
                  }}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                >
                  <option disabled>Per Page</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="1000">1000</option>
                </select>
                <span className="mr-4">
                  {page} of {Math.ceil(rows / quantity)} pages
                </span>
                <MDBIcon
                  onClick={prevPage}
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  className="grey-text mr-3"
                  icon="chevron-left"
                // size="2x"
                />
                <MDBIcon
                  onClick={nextPage}
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  className="grey-text ml-2"
                  icon="chevron-right"
                // size="1x"
                />
              </div>
            </Line>
          </MDBContainer>
        )}

        <br />
        <br />
      </NavBar>
    </>
  );
}

// <>
// <h5>Old Group: {t.branch_name}</h5>

// <MDBInput
// label="Update Group"
// icon="clipboard"
// group
// size="lg"
// onChange={e =>{
// setDadosModal({...dadosModal, group:e.target.value})
// if(e.target.value == ''){
//   const { group , ...restante} = dadosModal
//   setDadosModal(restante)

// }

// }}

// />
// <br/>
// <div className="h5-responsive">Select new Category</div>
// <Select
// className="w-100"
// // options={options}
// onChange={c=>setDadosModal({...dadosModal, product_category_id:c.value})}
// // defaultValue={{ label: t.TB_product_category.category, value: t.TB_product_category.id }}

// />
// </>
