import React, { Component } from 'react';
import {
 
  
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
// fazer roteamento com history


export default class Dashboards extends Component {
 
  render() {
    return (
      <>
        <NavBar />
        <h3 className="text-center">Statistics Package</h3>
        <iframe className="w-100" title="package" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=10efddae-8fcc-425b-b0c6-dc5b281550f3&autoAuth=true&ctid=3517876e-6e48-4b26-aebe-d50c988a4816&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
      </>
    );
  }
}
