import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  // baseURL: 'http://192.168.112.22:5555',
  baseURL: 'https://api.logikvmi.app', // production
});

api.interceptors.request.use(async (config) => {
  const token = await getToken();
  const configuration = config;

  if (token) {
    configuration.headers.Authorization = `Bearer ${token}`;
  }
  return configuration;
});

export default api;
