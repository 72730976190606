import React, { useState } from 'react';
import Select from 'react-select';

const SelectMulti = ({ options = [], onChange, value, placeholder }) => {
  const [selectAllOption] = useState({ value: '*', label: 'Select All' });
  const [inputValue, setInputValue] = useState('');

 
  const customFilterOption = (option, inputValue) => {
   
    if (option.value === selectAllOption.value) {
      return true; // Always show "Select All"
    }
    const label = option.label || ''; // Default to empty string if undefined
    return label.includes(inputValue);
  };



  const handleSelectChange = (selectedOptions, { action }) => {
    if (!selectedOptions) {
      // Se selectedOptions for null ou undefined, assegura que onChange seja chamado com um array vazio
      onChange([]);
      return;
    }

    if (action === 'select-option' && selectedOptions.some((option) => option.value === selectAllOption.value)) {
      // Selecionar todos os itens filtrados
      const filteredOptions = options.filter(
        (option) =>
          customFilterOption(option, inputValue) && option.value !== selectAllOption.value
      );
      onChange(filteredOptions);
    } else if (action === 'deselect-option' && selectedOptions.length === 0) {
      // Deseleciona tudo
      onChange([]);
    } else {
      // Seleção normal de itens individuais
      onChange(selectedOptions.filter((option) => option.value !== selectAllOption.value));
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.data.value === selectAllOption.value ? 'bold' : 'normal',
    }),
  };

  return (
    <Select
      isMulti
      options={[selectAllOption, ...options]} // options = [] garante que seja um array
      onChange={handleSelectChange}
      value={value || []} // Garante que value seja um array vazio se estiver indefinido
      placeholder={placeholder}
      filterOption={customFilterOption}
      onInputChange={handleInputChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      styles={customStyles}
    />
  );
};

export default SelectMulti;
