import React, { Component } from 'react';
import {
 
  
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
// fazer roteamento com history


export default class TFF extends Component {
 
  render() {
    return (
      
        <NavBar>
      <div style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
        <h3 className="text-center">TFF VMI System</h3>
        <iframe  style={{ flex: 1 }}
          className="w-100"
          title="Dashboard"
         
          allowFullScreen  src="https://app.powerbi.com/reportEmbed?reportId=dfa7bcd4-3824-4520-9672-40f617790051&groupId=ec7c0416-82d3-4c1b-8adc-3942d978c8b9&autoAuth=true&ctid=3517876e-6e48-4b26-aebe-d50c988a4816&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1lLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&disablecdnExpiration=1702942795" frameborder="0" allowFullScreen="true"></iframe>
        </div>
        </NavBar>
    );
  }
}
