import jwtDecode from 'jwt-decode';

export const TOKEN_KEY = '@Token';
export const DATA_PERFIL = '@dados-Perfil';
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token, perfil) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(DATA_PERFIL, JSON.stringify(perfil));
};

export const getData = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return jwtDecode(token);
};

export const getProfile = (names) => {
  const token = jwtDecode(localStorage.getItem(TOKEN_KEY)).profile;
  
  if (Array.isArray(names)) {
    
    return names.some((name) => token.name === name);
  }

  return token.name === names;
};

export const getPermission = (name) => {
  const token = jwtDecode(localStorage.getItem(TOKEN_KEY)).card;

  // Vamos iterar sobre cada item no array token
  for (const item of token) {
    // Cada 'item' tem uma propriedade 'funcao' que é um array, vamos iterar sobre ele
    for (const func of item.funcao) {
      if (func.name === name) {
        // Se o id do objeto for o que estamos procurando, logamos e retornamos

        return true;
      }
    }
  }
  // Se terminarmos de iterar e não encontrarmos o id, podemos logar isso também
  return false;
};

export const getPerfil = () => {
  const perfil = JSON.parse(localStorage.getItem(DATA_PERFIL));
  return perfil;
};

export const logout = (history) => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(DATA_PERFIL);

  history.push('/'); // Altere '/login' para a rota da sua página de login
};
