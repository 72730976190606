import React from 'react';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';

import { getData, getPerfil, logout } from '../../../services/auth';
// import { Container } from './styles';
import api from '../../../services/api';
import { useState, useEffect } from 'react';

function Perfil(props) {
  const history = useHistory();
  const [dados, setDados] = useState({});

  const [modalEditPerfil, setModalEditPerfil] = useState(false);
  const [editContato, setEditContato] = useState({});

  const [modalEditPassword, setModalEditPassword] = useState(false);
  const [editPassword, setEditPassword] = useState({});

  const [newPassword, setNewPassword] = useState({});

  // const idReq = getData().id;
  const data = getData();
  const perfil = getPerfil();


  // const loadDados = async () => {
  //   // const response = await api.get(`/usuario/${idReq}`);

  //   const response = await api.get(`/perfil`);

  //   setDados(response.data);
  // };

  const updatePassword = async () => {
    if (!newPassword.password) {
      toast.error('Fill in the fields');
      return;
    }
    if (newPassword.password != newPassword.confirm) {
      toast.error('Passwords do not match');
      return;
    }
    if (newPassword.password.length <= 5) {
      toast.error('Password needs more than 6 characters');
      return;
    }
    try {
      const edit = await api.put(`/change-password`, {
        password: newPassword.password,
      });

      toast.success(edit.data.ok);
      // loadDados();
      setModalEditPassword(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro, entre em contato com administrador!');
    }
  };

  

  const logoff = () => {
    
    logout(history);
  }

  
  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow center>
            <MDBCol
              md="8"
              className="d-flex flex-column justify-content-between mt-2 mt-md-0"
            >
              <MDBCard>
                <MDBCardBody className="d-flex flex-column">
                  <MDBRow className='h3-responsive' between>
                    <MDBCol >Information</MDBCol>
                    <MDBCol className='text-right'>
                    <Link className=' text-danger' to={`/app`}>
                    <MDBIcon far icon="times-circle"  />
                          </Link>
                      
                      </MDBCol>
                  </MDBRow>
               
                  <div className="grey-text">
                    <MDBInput
                      label="First Name"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={perfil?.first_name}
                      disabled
                    />
                    <MDBInput
                      label="Last Name"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={perfil?.last_name}
                      disabled
                    />
                    <MDBInput
                      label="Phone"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={perfil?.phone}
                      disabled
                    />

                    <MDBInput
                      label="Email"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={perfil?.email}
                      disabled
                    />

                    {/* <MDBInput
                      label="Level Access"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={dados?.level_access}
                      disabled
                    /> */}
                  </div>
                  <MDBRow className='h5-responsive grey-text'>
                      <MDBCol>
                        Profile: {data?.profile?.name}
                      </MDBCol>
                    </MDBRow>
                    <br/>
                    <MDBRow className='h5-responsive grey-text'>
                      <MDBCol>
                        Programs:
                      </MDBCol>
                    </MDBRow>
                    
                    <MDBRow className='h5-responsive grey-text'>
                      
                       {data?.profile?.programs.map(pro => (<MDBCol size='6' md='3'>{pro.program}</MDBCol>))}
                      
                    </MDBRow>
                  <br />
                  <MDBRow center>
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => {
                      setModalEditPassword(!modalEditPassword);
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                       Change Password
                    </span>
                  </div>
                  <div
                    className="btn btn-sm shadow-5 bg-danger"
                    size="sm"
                    onClick={() => {
                      logoff()
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      Logoff
                    </span>
                  </div>
                
                  </MDBRow>
                   

                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBModal
            isOpen={modalEditPassword}
            toggle={() => {
              setModalEditPassword(!modalEditPassword);
            }}
          >
            <MDBModalHeader
              toggle={() => {
                setModalEditPassword(!modalEditPassword);
              }}
            >
              Change Password
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                label="New Password"
                icon="unlock"
                group
                type="password"
                validate
                error="wrong"
                success="right"
                // valueDefault={dados.email}
                onChange={(e) =>
                  setNewPassword({ ...newPassword, password: e.target.value })
                }
              />
              <MDBInput
                className="mt-5"
                label="Repeat Password"
                icon="unlock"
                group
                type="password"
                validate
                error="wrong"
                success="right"
                // valueDefault={dados.email}
                onChange={(e) => {
                  setNewPassword({ ...newPassword, confirm: e.target.value });
                }}
              />
              {newPassword.password &&
              newPassword.password == newPassword.confirm ? (
                <div className="d-flex mb-3 justify-content-center align-items-center green-text">
                  <MDBIcon icon="check-circle" size="2x" />
                  <span className="ml-3 font-weight-bold">Passwords match</span>
                </div>
              ) : (
                ''
              )}
              <span style={{ fontSize: '12px' }}>
                * Password must be at least 6 characters.
              </span>
            </MDBModalBody>
            <MDBModalFooter>
            <div
                    className="btn btn-sm shadow-5 bg-danger"
                    size="sm"
                    onClick={() => {
                      setModalEditPassword(!modalEditPassword);
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                    Cancel
                    </span>
                  </div>
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => {
                      updatePassword();
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                    Update
                    </span>
                  </div>
             
            </MDBModalFooter>
          </MDBModal>
          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Perfil;
