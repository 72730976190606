import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'react-select';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
  MDBTooltip,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdbreact';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';

import NavBar from '../../../components/NavBar';
import Modal from '../../../components/Modal';

import { Line, Checkbox } from './styles';

export default function Customer(props) {
  const [loading, setLoading] = useState(false);
  const [programList, setProgramList] = useState([
    {
      value: '',
      label: '',
    },
  ]);
  const [programSelected, setProgramSelected] = useState('');
  const [typeSelected, setTypeSelected] = useState([
    {
      value: '',
      label: '',
    },
  ]);

  const [dataListOptions, setDataListOptions] = useState([
    {
      value: '',
      label: '',
    },
  ]);
  const [dataListOptionsSelected, setDataListOptionsSelected] = useState(null);

  const [loadingProgram, setLoadingProgram] = useState(false);
  const [loadingAny, setLoadingAny] = useState(false);

  const [finalList, setFinalList] = useState([]);
  const [isDisbled, setIsDisabled] = useState(false);

  useEffect(() => {
    programs();
    // carregaListas();
  }, []);

  useEffect(() => {
    if (finalList.length === 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [finalList]);

  const programs = async () => {
    setLoadingProgram(true);
    try {
      const result = await api.get('/customer', {
        params: {
          p: 999999999,
        },
      });
      const listSelect = [];
      result.data.rows.forEach((element) => {
        listSelect.push({ label: element.program, value: element.id });
      });
      setProgramList(listSelect);
    } catch (error) {
      toast.error('DATABASE ERROR');
    }
    setLoadingProgram(false);
  };

  // preencher select
  const handlerType = async (type) => {
    setLoadingAny(true);
    switch (type) {
      case 'category':
        api
          .get('/search-category-program', {
            params: {
              program: programSelected,
            },
          })
          .then((t) => {
            setDataListOptionsSelected(null);
            setDataListOptions(t.data);
            setLoadingAny(false);
          });
        break;
      case 'group':
        api
          .get('/search-group-program', {
            params: {
              program: programSelected,
            },
          })
          .then((t) => {
            // usar a view
            setDataListOptionsSelected(null);

            setDataListOptions(t.data);
            setLoadingAny(false);
          });
        break;
      case 'manufacturer':
        api
          .get('/search-manufacturer-program', {
            params: {
              program: programSelected,
            },
          })
          .then((t) => {
            // usar a view
            setDataListOptionsSelected(null);
            setDataListOptions(t.data);
            setLoadingAny(false);
          });
        break;
      case 'vendor_part_num':
        setDataListOptionsSelected(null);
        api
          .get('/search_vendor_program', {
            params: {
              program: programSelected,
            },
          })
          .then((t) => {
            // customer-listings

            setDataListOptionsSelected(null);

            setDataListOptions(t.data);
            setLoadingAny(false);
          });
        break;
      case 'customer_part_num':
        setDataListOptionsSelected(null);
        api
          .get('/search_customer_program', {
            params: {
              program: programSelected,
            },
          })
          .then((t) => {
            setDataListOptionsSelected(null);
            // const custpartnum = [];

            // t.data.map((cat) => {
            //   custpartnum.push({
            //     // value: cat.id,
            //     label: cat.customer_part_num,
            //   });
            // });
            setDataListOptions(t.data);
            setLoadingAny(false);
          });

        break;
      default:
        setLoadingAny(false);
    }
  };

  const submitOrder = () => {
    let validate = true;
    finalList.forEach((t) => {
      // object result in data
      t.result.map((ob) => {
        if (ob.order_qty == 0) {
          toast.error('Cannot contain zero');
          validate = false;
        }
      });
    });
    if (validate === true) {
      api.post('/orders-manually', finalList).then((result) => {
        setFinalList([]);
        toast.success('New Order Create');
      });
    }

    // api.post('/orders-manually', finalList).then((result) => {
    //   setFinalList([]);
    //   toast.success('New Order Create');
    // });
  };

  const addOrder = () => {
    if (dataListOptionsSelected === null) {
      toast.error('Fill in all fields');
      return;
    }
    api
      .get('/orders-manually', {
        params: {
          program: programSelected,
          type: typeSelected.value,
          typeSelected: dataListOptionsSelected.value,
          typeLabel: dataListOptionsSelected.label,
        },
      })
      .then((t) => {
        setLoading(true);
        setFinalList([...finalList, ...t.data]);
      })
      .catch((error) => {
        toast.error('ERROR DATABASE');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // delete all list
  const deleteList = (search) => {
    const list = finalList.filter((r) => {
      return r !== search;
    });
    setFinalList(list);
  };

  const deleteItem = (id) => {
    // all data
    const newObject = [];
    finalList.forEach((t) => {
      const obj = [];
      // object result in data
      t.result.map((ob) => {
        if (ob.id === id) {
          return;
        } else {
          obj.push(ob);
        }
      });
      newObject.push({ search: t.search, result: obj });
    });
    setFinalList(newObject);
  };

  const updateChange = (id, value) => {
    const newObject = [];
    finalList.forEach((t) => {
      const obj = [];
      // object result in data
      t.result.map((ob) => {
        if (ob.id === id) {
          ob.order_qty = value;
          obj.push(ob);
        } else {
          obj.push(ob);
        }
      });
      newObject.push({ search: t.search, result: obj });
    });
    setFinalList(newObject);
  };

  const noZero = () => {
    finalList.forEach((t) => {
      // object result in data
      t.result.map((ob) => {
        if (ob.order_qty == 0) {
          toast.error('Cannot contain zero');
          return false;
        }
      });
    });
  };

  useEffect(() => {
    handlerType(typeSelected.value);
  }, [programSelected, typeSelected]);

  // const newOrder = async (npage = page) => {
  //   if (!createOrder.program) {
  //     toast.error('The field Program is required');
  //     return;
  //   }

  //   const order = await api.post(`/orders`, {
  //     // ...createOrder,
  //     // branch_name: dadosModal.branch_name,
  //     program: createOrder.program,
  //     // customer_account_number: dadosModal.customer_account_number,
  //     // user_id: dadosModal.user_id,
  //     // customer_group_id: dadosModal.customer_group_id,
  //     // replan_days:dadosModal.replan_days,
  //   });

  //   setLoading(true);

  //   const listing = await api.get(`/orders`, {
  //     params: {
  //       pag: npage,
  //       p: quantity,
  //       order: order.data[0].order_number,
  //     },
  //   });

  //   setListResult(listing.data.rows);
  //   setRows(listing.data.count);
  //   setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
  //   setLoading(false);
  //   setEditDadosModal({});

  //   setViewOrder(listing.data.rows[0].order);

  //   setViewDate(format(new Date(listing.data.rows[0].date), 'dd/MM/yyyy'));
  //   setViewBranch(
  //     listing.data.rows[0].TB_customer_listing.TB_customer.branch_name
  //   );
  // };

  return (
    <>
      <NavBar>
        {/* SEARCH E SEARCH ADVANCED */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi text-left">
              Order Manually Creation
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBContainer
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            className="bg-white py-4"
          >
            <MDBRow className="align-items-center">
              <MDBCol col="12" md="3" className="text-center">
                {loadingProgram ? (
                  <MDBSpinner size="sm" />
                ) : (
                  <Select
                    placeholder="Program"
                    className="w-100"
                    options={programList}
                    // isClearable={true}
                    // isDisabled={isDisbled}
                    // isLoading={loadingProgram}
                    isSearchable={true}
                    onChange={(e) => {
                      // object with label and value
                      setProgramSelected(e.label);
                    }}
                    // value={programSelected}
                  />
                )}
              </MDBCol>
              <MDBCol col="12" md="3">
                <Select
                  placeholder="Type"
                  className="w-100"
                  isSearchable={false}
                  options={[
                    { label: 'Category', value: 'category' },
                    { label: 'Group', value: 'group' },
                    { label: 'Manufacturer', value: 'manufacturer' },
                    {
                      label: 'Vendor part number',
                      value: 'vendor_part_num',
                    },
                    {
                      label: 'Customer part number',
                      value: 'customer_part_num',
                    },
                  ]}
                  // isClearable={true}
                  // isSearchable
                  onChange={(e) => {
                    // object with label and value

                    setTypeSelected(e);
                  }}

                  // value={programSelected.value && programSelected.value}
                />
              </MDBCol>
              <MDBCol col="12" md="3">
                {loadingAny === true ? (
                  <MDBSpinner size="sm" />
                ) : dataListOptions.length > 1 ? (
                  <Select
                    placeholder={`Select ${
                      typeSelected.label === undefined ? '' : typeSelected.label
                    }`}
                    className="w-100"
                    options={dataListOptions}
                    isClearable={true}
                    isSearchable
                    onChange={(e) => {
                      // object with label and value
                      setDataListOptionsSelected(e);
                    }}
                    value={dataListOptionsSelected}
                    // defaultValue={dataListOptions[0].value}
                    // value={programSelected.value && programSelected.value}
                  />
                ) : (
                  ''
                )}
              </MDBCol>
              <div
                className="btn btn-sm shadow-5 bg-vmi"
                size="sm"
                onClick={() => addOrder()}
              >
                <span className="m-0 font-weight-bold text-white">
                  <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                </span>
              </div>
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
            <MDBRow className="mt-5 h4-responsive">
              <MDBRow className="col-12">
                {loading === true ? (
                  <MDBRow className="mt-5" center>
                    <MDBSpinner size="lg" role="status" tag="span" />
                  </MDBRow>
                ) : (
                  finalList &&
                  finalList.map((t) => (
                    <>
                      <MDBRow
                        style={{
                          backgroundColor: 'rgba(0,0,0,0.3)',
                          borderRadius: '5px',
                        }}
                        className="col-12 white-text d-flex font-weight-bold"
                      >
                        <MDBCol>{`${t.search.type}: ${t.search.typeLabel}`}</MDBCol>
                        <MDBCol className="col-2 text-right">
                          <div
                            className="d-flex justify-content-end align-items-center"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              deleteList(t);
                            }}
                          >
                            <MDBIcon className="mr-2" far icon="trash-alt" />
                            <div>Clean List</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow
                        style={{
                          backgroundColor: '#3f5c80',
                          borderRadius: '5px',
                        }}
                        className="col-12 d-flex  white-text font-weight-bold"
                      >
                        <MDBCol size="12" md="3">
                          Customer Number
                        </MDBCol>
                        <MDBCol size="12" md="3">
                          Vendor Number
                        </MDBCol>
                        <MDBCol size="12" md="2">
                          Unit Price
                        </MDBCol>
                        <MDBCol size="12" md="2">
                          Order Qty
                        </MDBCol>
                        <MDBCol
                          size="12"
                          md="2"
                          className="text-right"
                        ></MDBCol>
                      </MDBRow>

                      {t.result.map((linha) => (
                        <MDBRow key={linha.id} className="col-12 d-flex">
                          {/* mostrar vendor part number
                          mostrar customer part number */}
                          {/* mostrar qtd hand */}

                          <MDBCol size="12" md="3">
                            {linha.customer_part_num}
                          </MDBCol>
                          <MDBCol size="12" md="3">
                            {linha.vendor_part_num}
                          </MDBCol>

                          <MDBCol size="12" md="2">
                            {linha.unit_price}
                          </MDBCol>
                          <MDBCol size="12" md="2">
                            <input
                              defaultValue={linha.order_qty}
                              type="number"
                              onChange={(e) => {
                                updateChange(linha.id, e.target.value);
                              }}
                            />
                          </MDBCol>
                          <MDBCol size="12" md="2" className="text-right">
                            <MDBIcon
                              far
                              icon="trash-alt"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                deleteItem(linha.id);
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                      ))}
                      <br />
                    </>
                  ))
                )}
              </MDBRow>
            </MDBRow>
            {loading === true ? (
              <MDBRow className="mt-5" center>
                <MDBSpinner size="lg" role="status" tag="span" />
              </MDBRow>
            ) : finalList.length !== 0 ? (
              <MDBRow center>
                <MDBBtn
                  className=" d-flex align-items-center"
                  onClick={submitOrder}
                >
                  <MDBIcon className="mr-2" icon="upload" />
                  <div>Submit Order</div>
                </MDBBtn>
              </MDBRow>
            ) : (
              ''
            )}

            <br />
            <br />
          </MDBContainer>
        </MDBContainer>
      </NavBar>
    </>
  );
}
