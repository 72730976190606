import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
// import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import api from '../../../services/api';
import moment from 'moment';
import Select from 'react-select';
import { toast, ToastContainer } from 'mdbreact';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCollapse,
  MDBCol,
} from 'mdbreact';

import Loading from '../../../components/Loading';
import { Line } from './styles';
import { CSVLink } from 'react-csv';

export default function ProductCategory(props) {
  const [collapseID, setCollapseID] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');

  const [qtdPage, setQtdPage] = useState(1);
  const [dadosModal, setDadosModal] = useState({});
  const [listData, setListData] = useState('');

  const [qtyLine, setQtyLine] = useState(0);
  const [qtyLineSuccess, setQtyLineSuccess] = useState(0);
  const [qtyLineError, setQtyLineError] = useState(0);
  const [headers, setHeaders] = useState('');

  const [rowError, setRowError] = useState([]);
  const [loadDownload, setLoadDownload] = useState([]);

  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  useEffect(() => {
    // list()
  }, []);

  const enviaDados = async (e) => {
    setLoadDownload([]);
    let testeError = [];

    setRowError([]);

    if (!listData.data) {
      toast.error('No Data');
      return;
    }
    setLoading(true);

    const rows = listData.data.split('\n');
    if (rows <= 2) {
      toast.error('Error message', {
        closeButton: false,
      });
      // toast.error('No Data');
      return;
    }

    // setListData({data: ''});

    let headers = '';

    // headers = rows[i].split('\t')

    let n = 0;
    while (n < rows.length) {
      if (rows[n]) {
        setHeaders(rows[n]);
        headers = rows[n].split('\t');
        break;
      }
      n++;
    }

    let novaLinha = [];
    rows.map((i) => {
      const arrayLinha = i.split('\t');

      if (arrayLinha.length > 1) {
        let novaCelula = {};

        for (let i = 0; i < arrayLinha.length; i++) {
          novaCelula[headers[i]] = arrayLinha[i];
        }
        novaLinha.push(novaCelula);
      }
    });

    // retira primeira linha program:program
    novaLinha.shift();

    // quantidade de linhas totais
    setQtyLine(novaLinha.length);

    if (novaLinha.length > 1000) {
      toast.error('Max limit 1000');
    }
    let success = 0;

    const teste = await api.post('/teste', novaLinha);


    novaLinha.map(async (row) => {
      const teste = await api.post('/teste', row);

      if (teste.data.error) {
        testeError = [...testeError, teste.data.error];

        // console.log(teste.data.error);
        // setRowError(...rowError,row)
        // setLoadDownload([...loadDownload, teste.data.error]);
        // setRowError([...rowError, row]);
        // setListData({data: rowError});
      }

      // toast.success('Data list Updated');
      // success++;
      // if (success == novaLinha.length) {
      //   toast.success('Data list Updated');
      // }
      // if (teste.data.error) {
      //   // setRowError([...rowError, teste.data.error ]);
      //   testeError.push(teste.data.error);
      //   // setRowError([...rowError ,teste.data.error]);
      //   toast.error('houve um error');
      // }
      // setRowError(testeError);
      setLoadDownload(testeError);
    });
    setLoading(false);
    toast.success('Data list Updated');
    // console.log(testeError);
    // setLoadDownload(testeError);
    // }

    return;
  };

  const list = async (npage = page) => {
    // setLoading(true);
    // const response = await api.get(`/product-category?pag=${npage}&p=${quantity}&search=${search}`
    // {
    //   params: {
    //     search: search,
    //     p:quantity
    //   }
    // }
    // )
    // setListCategory(response.data.rows)
    // setRows(response.data.count)
    // setQtdPage(Math.ceil(Number(rows) / Number(quantity)))
    // setLoading(false);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };
  const nextPage = async () => {
    // if(Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity)))return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };

  return (
    <>
      <NavBar>
        {loading === true ? <Loading /> : ''}
        {/* BOTAO DE ADICIONAR */}
        <MDBContainer >
          <MDBRow style={{ borderRadius: '5px' }}>
            <MDBCol className="ml-0">
              <h3
           
                className="h3-responsive mt-3 font-weight-bolder text-vmi"
              >
                Load Items
              </h3>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBContainer>
          <MDBRow className="justify-content-center"></MDBRow>

          
            <MDBRow>
              <textarea
                onChange={(e) => {
                  setListData({ data: e.target.value });
                }}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                value={listData.data}
              ></textarea>
            </MDBRow>
            <MDBRow className="justify-content-center mt-2">
            <div
                className="btn btn-sm shadow-5 bg-vmi"
                size="sm"
                onClick={()=>enviaDados()}
              >
                <span className="m-0 font-weight-bold text-white">
                Submit
                </span>
              </div>
             

              {loadDownload.length > 0 ? (
                <>
                  <CSVLink data={loadDownload} separator={'\t'}>
                    <span
                      style={{ color: 'red' }}
                      className="mr-2 font-weight-bold"
                    >
                      {loadDownload.length} Error Found
                    </span>
                    <MDBIcon
                      style={{ color: 'red' }}
                      icon="download"
                      size="2x"
                    />
                  </CSVLink>
                </>
              ) : (
                ''
              )}
            </MDBRow>
         

          {/* <MDBRow className="mt-3 mb-4">
          <MDBCol
            className="d-flex align-items-center justify-content-center"
            col="12"
          >
            <MDBIcon
              onClick={prevPage}
              style={{ cursor: 'pointer' }}
              className="text-info mr-2"
              far
              icon="arrow-alt-circle-left"
              size="2x"
            />
            <span className="font-weight-bold">{page}</span>
            <MDBIcon
              onClick={nextPage}
              style={{ cursor: 'pointer' }}
              className="text-info ml-2"
              far
              icon="arrow-alt-circle-right"
              size="2x"
            />
          </MDBCol>
        </MDBRow> */}
        </MDBContainer>
      </NavBar>
    </>
  );
}
