import React, { Component } from 'react';
import {
 
  
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
// fazer roteamento com history


export default class Dashboards extends Component {
 
  render() {
    return (
      <>
        <NavBar />
        <h3 className="text-center">VMI Orders Daily</h3>
        <iframe className="w-100" title="Dashboard" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=e6a13265-fc17-48dc-aba2-bb7c027d91df&autoAuth=true&ctid=3517876e-6e48-4b26-aebe-d50c988a4816&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
      </>
    );
  }
}
