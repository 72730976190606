import React from 'react';
import { MDBCard, MDBCardTitle, MDBCardText, MDBRow, MDBCol } from 'mdbreact';
// import { Container } from './styles';

export default function card(props) {
  return (
    <MDBCard
      // style={{
      //   backgroundImage: `url(${props.img})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      //   cursor: 'pointer',
      // }}
      className="mx-3 h-100"
    >
      <MDBCardText
      tag="div"
        style={{
          color: 'rgba(0,0,0,0.5)',
          fontSize: '16px',
          minHeight: '90px',
        }}
        className="text-left px-3"
      >
        <MDBRow
          style={{ fontSize: '20px' }}
          className="bg-vmi text-white p-2 text-center z-depth-1 rounded mb-0 justify-content-center"
        >
          {props.titleName}
        </MDBRow>
        <MDBRow className="my-2">
          <MDBCol className="p-4" style={{ fontSize: '15px' }}>
            {props.children}
          </MDBCol>
        </MDBRow>
      </MDBCardText>
    </MDBCard>
  );
}
