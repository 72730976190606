import React from 'react';
import imgloading from '../../assets/loading.gif';
// import { Container } from './styles';

export default function Loading(props) {
  return (
    <div style={{
      position:'fixed',
      zIndex:'1000',
      marginTop:'37vh'
      }}

      className="w-100 d-flex justify-content-center align-items-center">
      <img
      style={{
        position:'fixed',
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderRadius: '20px'
        }}

      width='150' src={imgloading} />
      <span className="font-weight-bold">Loading...</span>
    </div>
  );
}
