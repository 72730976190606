import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import fileXlsx from './text_vendor_listings.xlsx';
import { getProfile } from '../../../services/auth';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBModalHeader,
  MDBModal,
  MDBModalBody,
  MDBSpinner,
  MDBModalFooter,
} from 'mdbreact';
import SelectMulti from '../../../components/SelectMulti'
import { Line } from './styles';

import {InsertText} from '../../../components/InsertText'
export default function VendorProduct(props) {
  const [collapseID, setCollapseID] = useState(false);
  const [searchCollapseID, setSearchCollapseID] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [listGroup, setListGroup] = useState([]);

  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);

  const [addVendor_part_num, setAddVendor_part_num] = useState('');
  const [addDescription, setAddDescription] = useState('');
  const [addBarCode, setAddBarCode] = useState('');
  const [addManufacturer, setAddManufacturer] = useState('');
  const [addProductGroup, setAddProductGroup] = useState('');
  const [loadDownload, setLoadDownload] = useState([]);
  const [loadText, setLoadText] = useState(false);
  const [erroMinMax, setErroMinMax] = useState([]);

  // const [ searchVendor_part_num, setSearchVendor_part_num ] = useState('')
  // const [ searchDescription, setSearchDescription ] = useState('')
  // const [ searchBarCode, setSearchBarCode ] = useState('')
  // const [ searchManufacturer, setSearchManufacturer ] = useState('')
  // const [ searchProductGroup, setSearchProductGroup ] = useState('')

  const [listManufacturer, setListManufacturer] = useState([]);
  const [listProductGroup, setListProductGroup] = useState([]);
  const [listCategory, setListCategory] = useState([]);

  const [dadosModal, setDadosModal] = useState({});

const [vendorAdSearch,setVendorAdSearch] = useState(null)
const [descriptionAdSearch,setDescriptionAdSearch] = useState(null)
const [barCodeAdSearch,setBarCodeAdSearch] = useState(null)

const [manufacturerAdSearch,setManufacturerAdSearch] = useState([])
const [manufacturerAdSearchList,setManufacturerAdSearchList] = useState([])

const [productGroupAdSearch,setProductGroupAdSearch] = useState([])
const [productGroupAdSearchList,setProductGroupAdSearchList] = useState([])

const [productCategoryAdSearch,setProductCategoryAdSearch] = useState([])
const [productCategoryAdSearchList,setProductCategoryAdSearchList] = useState([])

const [statusAdSearch,setStatusAdSearch] = useState(null)


  // edit
  const [editDadosModal, setEditDadosModal] = useState({});
  // download
  const [typeDownload, setTypeDownload] = useState();
  const [paramsDownload, setParamsDownload] = useState({});
  const [responseDownload, setResponseDownload] = useState([]);

  const [sort, setSort] = useState({
    value: 'vendor_part_num',
    label: 'Vendor Part No',
  });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });

  // adiciona pelo campo de texto
  const [inputAddText, setInputAddText] = useState();
  // quantidade de linhas enviadas pelo texto addInput
  const [qtdLine, setQtyLine] = useState();

  // erro das linhas enviadas
  const [rowError, setRowError] = useState();

  // carrega as listas para escolher os inputs
  const carregaListas = async () => {
    // load manufacturer in select
    const responseManufacturer = await api.get(`/manufacturer?p=99999999`);
    setListManufacturer(responseManufacturer.data.rows);

    // map list manufacturer to options of select
    let listagemManufacturer = [];
    responseManufacturer.data.rows.map((c) => {
      listagemManufacturer.push({ value: c.id, label: c.manufacturer });
    });
    setListManufacturer(listagemManufacturer);

    // load manufacturer in select
    const responseCategory = await api.get(`/product-category?p=99999999`);
    setListCategory(responseCategory.data.rows);

    // map list Category to options of select
    let listagemCategory = [];
    responseCategory.data.rows.map((c) => {
      listagemCategory.push({ value: c.id, label: c.category });
    });
    setListCategory(listagemCategory);

    // load product group in select
    const responseProductGroup = await api.get(`/product-group?p=99999999`);
    setListProductGroup(responseProductGroup.data.rows);

    // map list product group to options of select
    let listaProductGroup = [];
    responseProductGroup.data.rows.map((c) => {
      listaProductGroup.push({ value: c.id, label: c.group });
    });
    setListProductGroup(listaProductGroup);
  };

  const enviaText = async (e) => {
    // e.preventDefault();
    // setLoadDownload([]);
    let testeError = [];

    setRowError([]);

    if (!inputAddText) {
      toast.error('No Data');
      return;
    }
    setLoadText(true);

    const rows = inputAddText.split('\n');
    if (rows <= 2) {
      toast.error('Error message', {
        closeButton: false,
      });
      // toast.error('No Data');
      return;
    }

    // salva a linha de cabeçalho para tratamento dos dados
    let headers = '';

    let n = 0;
    while (n < rows.length) {
      if (rows[n]) {
        headers = rows[n].split('\t');
        break;
      }
      n++;
    }

    let novaLinha = [];
    rows.map((i) => {
      const arrayLinha = i.split('\t');

      if (arrayLinha.length > 1) {
        let novaCelula = {};

        for (let i = 0; i < arrayLinha.length; i++) {
          novaCelula[headers[i]] = arrayLinha[i];
        }
        novaLinha.push(novaCelula);
      }
    });

    // retira primeira linha program:program
    novaLinha.shift();

    // quantidade de linhas totais
    setQtyLine(novaLinha.length);

    if (novaLinha.length > 1000) {
      toast.error('Max limit 1000');
    }
    let success = 0;

    const teste = await api.post('/add-txt-vendor-product', novaLinha);
    setErroMinMax(teste.data);

    // novaLinha.map(async (row) => {
    //   const teste = await api.post('/add-txt-vendor-product', row);

    //   if (teste.data.error) {
    //     testeError = [...testeError, teste.data.error];

    // console.log(teste.data.error);
    // setRowError(...rowError,row)
    // setLoadDownload([...loadDownload, teste.data.error]);
    // setRowError([...rowError, row]);
    // setListData({data: rowError});
    // }

    // toast.success('Data list Updated');
    // success++;
    // if (success == novaLinha.length) {
    //   toast.success('Data list Updated');
    // }
    // if (teste.data.error) {
    //   // setRowError([...rowError, teste.data.error ]);
    //   testeError.push(teste.data.error);
    //   // setRowError([...rowError ,teste.data.error]);
    //   toast.error('houve um error');
    // }
    // setRowError(testeError);
    // });
    setLoadText(false);
    // toast.success('Data list Updated');
    // console.log(testeError);
    // setLoadDownload(testeError);
    // }

    // return;
  };

  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };
  const searchCollapse = () => {
    setSearchCollapseID(!searchCollapseID);

    setDadosModal({});
  };

  useEffect(() => {
    list();

    carregaListas();
  }, []);
  useEffect(() => {
    list(1);
  }, [quantity]);

  // useEffect(()=>{
  //   if(dadosModal.group == ''){
  //     const { group , ...restante} = dadosModal
  //     setDadosModal(restante)

  //   }

  // },[dadosModal])

  const enviaDados = async (e) => {
    if (
      !addVendor_part_num ||
      !addDescription ||
      addProductGroup === '' ||
      addManufacturer === ''
    ) {
      toast.error('Complete the fields!');
      return;
    }

    try {
      // console.log({group:group,product_category_id:categorySelected})
      await api.post('/product', {
        vendor_part_num: addVendor_part_num,
        description: addDescription,
        bar_code: addBarCode,
        manufacturer_id: addManufacturer,
        product_group_id: addProductGroup,
      });

      setAddVendor_part_num('');
      setAddDescription('');
      setAddBarCode('');
      setAddManufacturer('');
      setAddProductGroup('');
      list();
      toast.success('Added Successfully!');
    } catch (error) {
      console.log(error);
      // toast.error('Error');
    }
  };

 

  const simpleSearch = async (npage = page) => {
    setLoading(true);
    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
    const response = await api.get(`/product`, {
      params: {
        pag: npage,
        p: quantity,
        vendor_part_num: search,
      },
    });

    setListGroup(response.data.rows);
    setRows(response.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

    setLoading(false);
  };

  const list = async (npage = page) => {
    window.scrollTo(0, 0);
    setLoading(true);

    const dados = {}
    if(searchCollapseID){
      if (manufacturerAdSearch.length > 0) {
        dados.manufacturer = manufacturerAdSearch.map(item => item.value);
      }

      if (productGroupAdSearch.length > 0) {
        dados.group = productGroupAdSearch.map(item => item.value);
      
      }
      
      if (productCategoryAdSearch.length > 0) {
      
        dados.category = productCategoryAdSearch.map(item => item.value);
        
      }

      if (statusAdSearch !== null) {
        dados.active = statusAdSearch.value;
      }

      if (vendorAdSearch !== null) {
        dados.vendor_part_num = vendorAdSearch;
      }

      if (descriptionAdSearch !== null) {
        dados.description = descriptionAdSearch;
      }
      if (barCodeAdSearch !== null) {
        dados.bar_code = barCodeAdSearch;
      }
      
      // if (statusAdSearch !== null) {
      //   dados.active = statusAdSearch.value;
      // }
      
    }else{
       if(search !== null){
      dados.search = search
      }
    }

    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
    const response = await api.get(`/product`, {
      params: {
        pag: npage,
        p: quantity,
      ...dados,
        sort: sort.value,
        typeSort: typeSort.value,
      },
    });

    setListGroup(response.data.rows);
    setRows(response.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
    setEditDadosModal({});
    setLoading(false);
    setLoadDownload(response.data.rows);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };
  const addModalFunction = () => {
    setAddModal(!addModal);
  };

  let editDados = {};
  const editaDados = () => {
    if (addVendor_part_num) editaDados.addVendor_part_num = addVendor_part_num;
    if (addDescription) editaDados.addDescription = addDescription;
    if (addBarCode) editaDados.addBarCode = addBarCode;
    if (addManufacturer) editaDados.addManufacturer = addManufacturer;
    if (addProductGroup) editaDados.addProductGroup = addProductGroup;
  };

  const preencheSelects = async () => {
    // Array de condições que será enviado no corpo da requisição
    const conditions = [];
  
    // Verificar e adicionar o filtro para 'program'
    if (manufacturerAdSearch.length > 0) {
      conditions.push({
        colname: 'manufacturer',
        colid: 'manufacturer_id',
        id: manufacturerAdSearch.map(e => e.value), // Adiciona os IDs selecionados
      });
    } else {
      conditions.push({
        colname: 'manufacturer',
        colid: 'manufacturer_id', // Só retorna os valores, sem id para filtragem
      });
    }
  
    // Verificar e adicionar o filtro para 'branch_name'
    if (productGroupAdSearch.length > 0) {
      conditions.push({
        colname: 'group',
        colid: 'group_id',
        id: productGroupAdSearch.map(e => e.value),
      });
    } else {
      conditions.push({
        colname: 'group',
        colid: 'group_id', // Retorna os valores para preenchimento do select
      });
    }
  
    // Verificar e adicionar o filtro para 'customer_account_number'
    if (productCategoryAdSearch.length > 0) {
      conditions.push({
        colname: 'category',
        colid: 'category_id',
        id: productCategoryAdSearch.map(e => e.value),
      });
    } else {
      conditions.push({
        colname: 'category',
        colid: 'category_id',
      });
    }
  

  
    // Fazer a requisição para o backend com o array de condições
    try {
      const response = await api.post('/search-filters', conditions);
    
      // Processando a resposta para preencher os selects
      if (!manufacturerAdSearch.length) {
        setManufacturerAdSearchList(response.data.manufacturer);
      }
      if (!productGroupAdSearch.length) {
        setProductGroupAdSearchList(response.data.group);
      }

      if (!productCategoryAdSearch.length) {
        setProductCategoryAdSearchList(response.data.category);
      }
     
    } catch (error) {
      console.error('Erro ao preencher selects:', error);
    }
  };
  
  useEffect(() => {
    preencheSelects();
  }, [manufacturerAdSearch, productGroupAdSearch, productCategoryAdSearch]);
  



  useEffect(() => {
    list(1);
  }, [sort, typeSort]);

  return (
    <>
      <NavBar>
        {/* BOTAO DE ADICIONAR */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi">
              Vendor Listings
              {getProfile(['Super Admin', 'Admin']) && (
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="d-flex d-md-none justify-content-end"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBCollapse isOpen={collapseID}>
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: 'rgba(255,255,255,0.9)',
              }}
            >
              <MDBRow className="justify-content-center">
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New Vendor Part No"
                    icon="thumbtack"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddVendor_part_num(e.target.value);
                    }}
                    value={addVendor_part_num}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="New Description"
                    icon="clipboard"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddDescription(e.target.value);
                    }}
                    value={addDescription}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    label="Bacode"
                    icon="barcode"
                    group
                    size="lg"
                    onChange={(e) => {
                      setAddBarCode(e.target.value);
                    }}
                    value={addBarCode}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="justify-content-center">
                <MDBCol className="col-12 col-md-4">
                  <span>Manufacturer</span>
                  <Select
                    className="w-100"
                    options={listManufacturer}
                    onChange={(c) => setAddManufacturer(c.value)}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <span>Product Group</span>
                  <Select
                    className="w-100"
                    options={listProductGroup}
                    onChange={(c) => setAddProductGroup(c.value)}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow center>
              <InsertText
  fileTemplate={fileXlsx}
  apiUrl="/add-txt-vendor-product"
  showRadioButtons={false}
  onRadioChange={(type) => console.log(`Radio changed to: ${type}`)} // Comportamento dinâmico
/>
                {/* <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={() => addModalFunction()}
                >
                  <span className="m-0 font-weight-bold text-white">Text</span>
                </div> */}
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5"
                  size="sm"
                  onClick={() => enviaDados()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                  </span>
                </div>
              
              </MDBRow>
            </div>
          </MDBCollapse>
        </MDBContainer>
        {/* BOTAO DE SEARCH E QUANTIDADE */}
        <MDBContainer fluid>
          <MDBRow className="md-form w-100  d-flex flex-column flex-md-row justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <MDBInput
                //  width='200px'

                className={`col-10  form-control ml-3 flex-1 ${
                  searchCollapseID ? 'text-danger' : ''
                }`}
                disabled={searchCollapseID}
                // options={options}
                type="text"
                placeholder={searchCollapseID ? 'Disabled' : 'search'}
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !searchCollapseID) {
                    simpleSearch(1);
                  }
                }}
              />
              <MDBIcon
                icon="search"
                size="2x"
                className="text-muted cursor-pointer"
                onClick={() => {
                  // list com a pagina 1 NO BACKEND
                  simpleSearch(1);
                  // seta o valor da pagina NO FRONTEND
                  setPage('1');
                }}
              />
            </div>

            <div
              className={`ml-3 btn btn-sm shadow-5  ${
                searchCollapseID
                  ? 'btn-outline-grey grey-text'
                  : 'bg-vmi text-white'
              }  `}
              size="sm"
              onClick={() => searchCollapse()}
            >
              <span className="m-0 font-weight-bold ">
                {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                Advanced Search
                <MDBIcon className="ml-3" icon="filter" />
              </span>
            </div>
          </MDBRow>

          {/*ADVANCED SEARCH COLLAPSE */}
          <MDBContainer fluid>
            <MDBRow className="mb-3">
              <MDBCollapse
                isOpen={searchCollapseID}
                className="col-12"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              >
                {/* ADVANCED SEARCH */}
                <MDBRow className="h3-responsive mx-3">ADVANCED SEARCH</MDBRow>
                <MDBRow>
                  <MDBCol className="col-12 col-md-4">
                    <MDBInput

                      label="Vendor Part No"
                      icon="thumbtack"
                      group
                      size="lg"
                      value={vendorAdSearch}
                      onChange={(e) => setVendorAdSearch(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <MDBInput
                      label="Description"
                      icon="clipboard"
                      group
                      size="lg"
                      value={descriptionAdSearch}
                      onChange={(e) => setDescriptionAdSearch(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <MDBInput
                      label="Barcode"
                      icon="barcode"
                      group
                      size="lg"
                      value={barCodeAdSearch}
                      onChange={(e) =>setBarCodeAdSearch(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-4 justify-content-center">
                  <MDBCol className="col-12 col-md-3">
                    <span>Manufacturer</span>
                    {/* <Select
                    isMulti
                      className="w-100"
                      options={manufacturerAdSearchList}
                      isClearable
                      value={manufacturerAdSearch}
                      onChange={(c) => setManufacturerAdSearch(c || [])
                        }
                   
                      // defaultValue={{ label: t.TB_product_manufacturer.manufacturer, value: t.TB_product_manufacturer.id }}
                    /> */}
                    
  <SelectMulti 
  options={manufacturerAdSearchList || []}
  value={manufacturerAdSearch || []}
  onChange={(e) => setManufacturerAdSearch(e)}
/>

                  </MDBCol>
                  <MDBCol className="col-12 col-md-3">
                    <span>Product Group</span>
                    {/* <Select
                    isMulti
                      className="w-100"
                      options={productGroupAdSearchList}
                      isClearable
                      value={productGroupAdSearch}
                      onChange={(c) => setProductGroupAdSearch(c || [])}
                      // defaultValue={{ label: t.TB_product_group.group, value: t.TB_product_group.id}}
                    /> */}
                      <SelectMulti 
  options={productGroupAdSearchList || []}
  value={productGroupAdSearch || []}
  onChange={(e) => setProductGroupAdSearch(e)}
/>
                  </MDBCol>
                  <MDBCol className="col-12 col-md-3">
                    <span>Product Category</span>
                    {/* <Select
                    isMulti
                      className="w-100"
                      options={productCategoryAdSearchList}
                      isClearable
                      value={productCategoryAdSearch}
                      onChange={(c) => setProductCategoryAdSearch(c || [])}
                      
              
                      // defaultValue={{ label: t.TB_product_group.group, value: t.TB_product_group.id}}
                    /> */}
                       <SelectMulti 
  options={productCategoryAdSearchList || []}
  value={productCategoryAdSearch || []}
  onChange={(e) => setProductCategoryAdSearch(e)}
/>
                  </MDBCol>
                  <MDBCol col="12" md="3">
                    Status
                    <Select
                     options={[
                      {value: '1', label: 'Active'},
                      {value: '0', label: 'Inactive'},
                    ]} 
                      value={statusAdSearch}
                      onChange={(c) => setStatusAdSearch(c)}
                    
                  
                   
                    /> 
                  </MDBCol>

                  {/* <MDBBtn
              className="mt-4"
              style={{height:"35px", width:"200px"}}
              outlin
              color="danger"
              rounded
              size="sm"
              type="submit"
              onClick={()=> {
                console.log(dadosModal)
                // list com a pagina 1 NO BACKEND
                list(1)
                // seta o valor da pagina NO FRONTEND
                setPage('1')


              }}
              >
              Clear
              </MDBBtn>
 */}
                </MDBRow>
                <MDBRow center>
                  <MDBCol size="0" md="4"></MDBCol>
                  <MDBCol size="6" md="4" className="text-center">
                    <div
                      className="btn btn-sm shadow-5 bg-vmi"
                      size="sm"
                      onClick={() => {
                        // list com a pagina 1 NO BACKEND
                        list(1);
                        // seta o valor da pagina NO FRONTEND
                        setPage('1');
                      }}
                    >
                      <span className="m-0 font-weight-bold text-white">
                        Search
                      </span>
                    </div>
                  </MDBCol>
                  <MDBCol className="text-right" size="6" md="4">
                    <div
                      className={`ml-3 btn btn-sm shadow-5 btn-outline-grey grey-text`}
                      size="sm"
                      onClick={() => setDadosModal({})}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        Clear filter
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>
        {/* LISTAGEM */}

        <MDBContainer fluid>
          <MDBRow
            style={{ color: '#3f5c80' }}
            className="d-flex justify-content-between mr-4 pl-3"
          >
            <MDBCol
              col="12"
              md="3"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                placeholder="Select Sort"
                options={[
                  { value: 'vendor_part_num', label: 'Vendor Part No' },
                  { value: 'description', label: 'Description' },
                ]}
                value={sort}
                onChange={(t) => {
                  setSort(t);
                }}
              />
            </MDBCol>
            <MDBCol
              col="12"
              md="2"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                options={[
                  { value: 'ASC', label: 'Ascending' },
                  { value: 'DESC', label: 'Descending' },
                ]}
                placeholder="Select Type Sort"
                onChange={(t) => {
                  setTypeSort(t);
                }}
                value={typeSort}
              />
            </MDBCol>
            <MDBCol size="2" />
            <MDBCol
              size="4"
              className="d-flex justify-content-end align-items-center"
            >
              <Select
                className="w-50"
                // isClearable={true}

                placeholder="Select Download"
                options={[
                  { value: 0, label: 'Export Current Page' },
                  { value: 1, label: 'Export All' },
                ]}
                onChange={async (c) => {
                  if (c.value == 0) {
                    setLoading(true);
                    setResponseDownload([]);
                    const listing = await api.get(`/product`, {
                      params: {
                        pag: page,
                        p: quantity,
                        ...dadosModal,
                        sort: sort.value,
                        typeSort: typeSort.value,
                      },
                    });

                    const download = [];
                    listing.data.rows.forEach((element) => {
                      const {
                        TB_product_manufacturer,
                        TB_product_group,
                        id,
                        createdAt,
                        updatedAt,
                        ...rest
                      } = element;
                      download.push({
                        ...rest,
                        manufacturer:
                          element.TB_product_manufacturer.manufacturer,
                        product_group: element.TB_product_group.group,
                        active: element.active === true ? 'Yes':'No',
                      });
                    });

                    setResponseDownload(download);
                    setLoading(false);
                  }
                  if (c.value == 1) {
                    setLoading(true);
                    setResponseDownload([]);
                    const listing = await api.get(`/product`, {
                      params: {
                        p: 999999999,
                        ...dadosModal,
                      },
                    });
                    const download = [];
                    listing.data.rows.forEach((element) => {
                      const {
                        TB_product_manufacturer,
                        TB_product_group,
                        id,
                        createdAt,
                        updatedAt,
                        ...rest
                      } = element;
                      download.push({
                        ...rest,
                        manufacturer:
                          element.TB_product_manufacturer.manufacturer,
                        product_group: element.TB_product_group.group,
                        active: element.active === true ? 'Yes':'No',
                      });
                    });

                    setResponseDownload(download);
                    setLoading(false);
                  }
                }}
              />

              {responseDownload.length !== 0 ? (
                <CSVLink
                  className="ml-2"
                  data={responseDownload}
                  separator={','}
                  filename={'vendorProduct.csv'}
                
                >
                  <span
                    style={{ color: '#0e4e9e' }}
                    className="mr-2 font-weight-bold"
                  >
                    Download
                  </span>

                  <MDBIcon
                    style={{ color: '#0e4e9e' }}
                    icon="download"
                    size="2x"
                  />
                </CSVLink>
              ) : (
                ''
              )}
            </MDBCol>
          </MDBRow>

          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Vendor Part No:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Description:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Product Category:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Product Group:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Manufacturer:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-center">
              <span className="d-sm-inline font-weight-bold mr-3">
                Barcode:
              </span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Status:</span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center"></div>
          </MDBRow>
        </MDBContainer>

        {/* LIST CUSTOMER */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) :listGroup && listGroup.length > 0 ? (
            listGroup.map((t) => (
              <div key={t.id}>
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line key={t.id}>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className="mr-1 ml-1 mr-md-0 ml-md-0 p-2"
                  >
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Vendor Part No:
                      </span>
                      <span>{t.vendor_part_num}</span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Description:
                      </span>
                      <span>{t.description}</span>
                    </div>

                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Product Category:
                      </span>
                      <span>
                        {t.TB_product_group.TB_product_category.category}
                      </span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Product Group:
                      </span>
                      <span>
                        {t.TB_product_group ? t.TB_product_group.group : ''}
                      </span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Manufacturer:
                      </span>
                      <span>
                        {t.TB_product_manufacturer
                          ? t.TB_product_manufacturer.manufacturer
                          : ''}
                      </span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-center">
                      <span className="d-sm-inline d-md-none mr-3">
                        Barcode:
                      </span>
                      <span>{t.bar_code}</span>
                    </div>

                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Status:
                      </span>
                      <span>{t.active == true ? 'Active' : 'Inactive'}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                      {/* EDIT MODAL */}
                      <Modal
                        id={t.id}
                        title="Vendor Listings"
                        dados={editDadosModal}
                        api={`/product/${t.id}`}
                        restart={async () => await list()}
                        size="lg"
                        disabled={!getProfile(['Super Admin', 'Admin'])}
                      >
                       <MDBRow>
                              <MDBCol className="h4-responsive" col="12" md="6">
                                Creation Date:
                                {t.createdAt
                                  ? format(new Date(t.updatedAt), 'dd/MM/yyyy')
                                  : ''}
                              </MDBCol>
                              <MDBCol className="h4-responsive" col="12" md="6">
                                {t.active === true ? 'Modified Date:': <span className='text-danger'>Discontinued Date:</span>  }
                            
                                {format(new Date(t.updatedAt), ' dd/MM/yyyy')}
                              </MDBCol>
                            </MDBRow>
                        <MDBRow>
                          <MDBCol className="col-12 col-md-6">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              label="Vendor Part No"
                              group
                              valueDefault={t.vendor_part_num}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  vendor_part_num: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { vendor_part_num, ...restante } =
                                  editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                              type="number"
                            />
                          </MDBCol>
                          <MDBCol col="12" md="6">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              label="Description"
                              group
                              valueDefault={t.description}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  description: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { description, ...restante } =
                                  editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" md="6">
                            <MDBInput
                              disabled={!getProfile(['Super Admin', 'Admin'])}
                              label="Barcode"
                              group
                              valueDefault={t.bar_code}
                              size="lg"
                              onChange={(e) => {
                                setEditDadosModal({
                                  ...editDadosModal,
                                  bar_code: e.target.value,
                                });
                                // se estiver vazio , retiro o elemento
                                if (e.target.value == '') {
                                  const { bar_code, ...restante } = editDadosModal;
                                  setEditDadosModal(restante);
                                }
                              }}
                            />
                          </MDBCol>

                          <MDBCol col="12" md="6">
                            <span>Manufacturer</span>
                            <Select
                              isDisabled={!getProfile(['Super Admin', 'Admin'])}
                              className="w-100"
                              options={listManufacturer}
                              onChange={(c) =>
                                setEditDadosModal({
                                  ...editDadosModal,
                                  manufacturer_id: c.value,
                                })
                              }
                              defaultValue={{
                                label: t.TB_product_manufacturer.manufacturer,
                                value: t.TB_product_manufacturer.id,
                              }}
                            />
                          </MDBCol>
                        </MDBRow>

                        <br />
                        <MDBRow>
                          <MDBCol className="col-12 col-md-6">
                            <span>Product Group</span>
                            <Select
                              isDisabled={!getProfile(['Super Admin', 'Admin'])}
                              className="w-100"
                              options={listProductGroup}
                              onChange={(c) =>
                                setEditDadosModal({
                                  ...editDadosModal,
                                  product_group_id: c.value,
                                })
                              }
                              defaultValue={{
                                label: t.TB_product_group.group,
                                value: t.TB_product_group.id,
                              }}
                            />
                          </MDBCol>
                          <MDBCol className="col-12 col-md-6">
                            <span>Product Category</span>

                            <Select
                              isDisabled={true}
                              className="w-100"
                              options={listCategory}
                              onChange={(c) =>
                                setEditDadosModal({
                                  ...editDadosModal,
                                  product_category_id: c.value,
                                })
                              }
                              defaultValue={{
                                label:
                                  t.TB_product_group.TB_product_category
                                    .category,
                                value:
                                  t.TB_product_group.TB_product_category.id,
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <br />
                        <MDBRow between>
                          <MDBCol col="12" md="6">
                            <span>Status</span>
                            <Select
                              isDisabled={!getProfile(['Super Admin', 'Admin'])}
                              className="w-100"
                              options={[
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                              ]}
                              onChange={(c) =>
                                setEditDadosModal({
                                  ...editDadosModal,
                                  active: c.value,
                                })
                              }
                              defaultValue={{
                                label:
                                  t.active === true ? 'Active' : 'Inactive',
                                value: t.active === true ? true : false,
                              }}
                            />
                          </MDBCol>
                          
                          <br />
                        </MDBRow>
                        <br />
                      </Modal>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          ) : (
            <MDBRow center>No Data</MDBRow>
          )}
          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
      </NavBar>
    </>
  );
}

// <>
// <h5>Old Group: {t.description}</h5>

// <MDBInput
// label="Update Group"
// icon="clipboard"
// group
// size="lg"
// onChange={e =>{
// setDadosModal({...dadosModal, group:e.target.value})
// if(e.target.value == ''){
//   const { group , ...restante} = dadosModal
//   setDadosModal(restante)

// }

// }}

// />
// <br/>
// <div className="h5-responsive">Select new Category</div>
// <Select
// className="w-100"
// // options={options}
// onChange={c=>setDadosModal({...dadosModal, product_category_id:c.value})}
// // defaultValue={{ label: t.TB_product_category.category, value: t.TB_product_category.id }}

// />
// </>
