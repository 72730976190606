import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';

// import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';

import App from './pages/App';
import Perfil from './pages/Administration/Perfil';

import vmiOrders from './pages/Dashboards/VMI';
import statisticsPackage from './pages/Dashboards/package';

// INVENTORY
import ProductCategory from './pages/Inventory/ProductCategory';
import ProductGroup from './pages/Inventory/ProductGroup';
import Manufacturer from './pages/Inventory/ProductManufacturer';
import VendorProduct from './pages/Inventory/VendorProduct';

import Vendor from './pages/Vendors/Vendor';
import CustomerGroup from './pages/Customers/CustomerGroup';
import Program from './pages/Customers/Program';
import CustomerListings from './pages/Customers/ProgramListings';

import Transaction from './pages/OrderManagement/Transaction';
import Order from './pages/OrderManagement/Order';
import OrderManually from './pages/OrderManagement/OrderManually';
import OrderHistory from './pages/OrderManagement/OrderHistory';

import UploadUtilities from './pages/Customers/UploadUtilities';
import Users from './pages/Administration/Users';

import TBI from './pages/statistic&reports/TBI';
import TFF from './pages/statistic&reports/TFF';

// import { DoubleNavigationPage } from 'mdbreact';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <>
          {window.scrollTo(0, 0)}
          <Component {...props} />
        </>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />

      <PrivateRoute path="/app" component={App} />

      {/* <PrivateRoute path="/perfil/:id" component={Perfil} />
      <PrivateRoute path="/meu-perfil" component={MeuPerfil} />

      <PrivateRoute path="/usuarios" component={Usuarios} />
      <PrivateRoute path="/usuario/add" component={AddUsuario} /> */}

      <PrivateRoute
        path="/dashboards/statistics-package"
        component={statisticsPackage}
      />
      {/* <PrivatePrivateRoute
        path="/dashboards/statistics-package"
        component={statisticsPackage}
      /> */}

      <PrivateRoute path="/dashboards/vmi-orders" component={vmiOrders} />

      <PrivateRoute path="/administration/perfil" component={Perfil} />

      <PrivateRoute
        path="/inventory/product-category"
        component={ProductCategory}
      />
      <PrivateRoute path="/inventory/product-groups" component={ProductGroup} />
      <PrivateRoute path="/inventory/manufacturer" component={Manufacturer} />
      <PrivateRoute
        path="/inventory/vendor-listing"
        component={VendorProduct}
      />

      <PrivateRoute
        path="/vendors/vendor"
        component={Vendor}
      />

      {/* old /customer-group */}
      <PrivateRoute path="/customers/program-group" component={CustomerGroup} />
      <PrivateRoute path="/customers/program" component={Program} />

      <PrivateRoute
        path="/customers/program-listing"
        component={CustomerListings}
      />
      {/* criar a alteracao de informacoes atraves de texto */}
      <PrivateRoute
        path="/customers/upload-utilities"
        component={UploadUtilities}
      />

      <PrivateRoute
        path="/order-management/transaction"
        component={Transaction}
      />

      <PrivateRoute path="/order-management/order" component={Order} />
      <PrivateRoute
        path="/order-management/order-manually"
        component={OrderManually}
      />
      <PrivateRoute
        path="/order-management/order-history"
        component={OrderHistory}
      />

      <PrivateRoute path="/administration/users" component={Users} />

      <PrivateRoute path="/statistic-reports/tbi" component={TBI} />
      <PrivateRoute path="/statistic-reports/tff" component={TFF} />

      {/* <PrivateRoute path="/dashboards/vmi-orders" component={vmiOrders} /> */}

      {/* <PrivateRoute path="/product-category" component={ProductCategory} /> */}
      {/* <PrivateRoute path="/product-groups" component={ProductGroup} /> */}
      {/* <PrivateRoute path="/manufacturer" component={Manufacturer} /> */}
      {/* <PrivateRoute path="/vendor-listing" component={VendorProduct} /> */}

      {/* <PrivateRoute path="/inventory-supplier" component={InventorySupplier} /> */}

      {/* old /customer-group */}
      {/* <PrivateRoute path="/program-group" component={CustomerGroup} /> */}
      {/* <PrivateRoute path="/program" component={Customer} /> */}

      {/* <PrivateRoute path="/program-listing" component={CustomerListings} /> */}

      {/* <PrivateRoute path="/transaction" component={Transaction} /> */}

      {/* <PrivateRoute path="/order" component={Order} /> */}

      {/* criar a alteracao de informacoes atraves de texto */}
      {/* <PrivateRoute path="/teste" component={Teste} /> */}

      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
