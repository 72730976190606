import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'react-select';

import CsvDownloader from 'react-csv-downloader';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBSpinner,
  MDBModalFooter,
  MDBTooltip,
} from 'mdbreact';
import api from '../../../services/api';

import NavBar from '../../../components/NavBar';

import { Line, Checkbox } from './styles';

export default function Customer(props) {
  const [collapseID, setCollapseID] = useState(false);
  const [searchCollapseID, setSearchCollapseID] = useState(false);

  const [listResult, setListResult] = useState([]);

  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);

  const [dadosModal, setDadosModal] = useState({});

  const [addProgramList, setAddProgramList] = useState([]);

  const [editDadosModal, setEditDadosModal] = useState({});

  // const [sort, setSort] = useState('date');
  // const [typeSort, setTypeSort] = useState('ASC');
  const [typeDownload, setTypeDownload] = useState();
  const [paramsDownload, setParamsDownload] = useState({});

  const [responseDownload, setResponseDownload] = useState([]);
  const [sort, setSort] = useState({ value: 'date', label: 'Date' });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });
  const searchCollapse = async () => {
    // alter boolean collapse
    setSearchCollapseID(!searchCollapseID);

    // verify search is true to make request
    if (searchCollapseID) {
      return;
    }
  };
  useEffect(() => {
    list(1);
  }, [quantity]);
  useEffect(() => {
    list();
    carregaListas();
  }, []);

  // useEffect(()=>{
  //   if(dadosModal.group == ''){
  //     const { group , ...restante} = dadosModal
  //     setDadosModal(restante)

  //   }

  // },[dadosModal])
  const carregaListas = async () => {
    // load CUSTOMER COLUMN PROGRAM in select
    const responseProgram = await api.get(`/customer?p=99999999`);
    const listagemProgram = [];
    responseProgram.data.rows.map((item) => {
      listagemProgram.push({ value: item.id, label: item.program });
    });
    setAddProgramList(listagemProgram);
  };

  const findSelectedOptionValue = (options, value) => {
    if (!options || !value) return null;
    return options.find((option) => option.value === value) || null;
  };

  const findSelectedOptionLabel = (options, label) => {
    if (!options || !label) return null;
    return options.find((option) => option.label === label) || null;
  };
  // const simpleSearch = async (npage = page) => {

  //   setLoading(true);
  //   try {
  //     const response = await api.get(`/transaction`, {
  //       params: {
  //         pag: npage,
  //         p: quantity,
  //         search: search,
  //         sort,
  //         typeSort,
  //         type_search: 'simple',
  //       },
  //     });
  //     setListResult(response.data.rows);
  //     setRows(response.data.count);
  //     setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
  //     setTypeDownload('simple');
  // setParamsDownload({
  //   pag: npage,
  //   p: 99999999999,
  //   search: search,
  //   sort,
  //   typeSort,
  //   type_search: 'simple',
  // });
  // setLoading(false);
  // // getDownload();
  //   } catch (error) {
  //     toast.error('Error');
  //   }
  // };

  const list = async (npage = page) => {
    setLoading(true);
    window.scrollTo(0, 0);
    try {
      const listing = await api.get(`/transaction`, {
        params: {
          pag: npage,
          p: quantity,
          ...dadosModal,
          sort: sort.value,
          typeSort: typeSort.value,
          // ...dadosModal,
          // branch_name: dadosModal.branch_name,
          // program: dadosModal.program,
          // customer_account_number: dadosModal.customer_account_number,
          // user_id: dadosModal.user_id,
          // customer_group_id: dadosModal.customer_group_id,
          // replan_days:dadosModal.replan_days,
        },
      });

      setListResult(listing.data.rows);
      setRows(listing.data.count);
      setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

      setParamsDownload({
        pag: 1,
        p: 99999999999,
        ...dadosModal,
        sort: sort.value,
        typeSort: typeSort.value,
      });

      // getDownload();
      setEditDadosModal({});
    } catch (error) {
      toast.error('ERROR DATABASE');
    }
    setLoading(false);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };

  useEffect(() => {
    setDadosModal({});
  }, [searchCollapseID]);

  useEffect(() => {
    list(1);
  }, [sort, typeSort]);

  return (
    <>
      <NavBar>
        {/* SEARCH E SEARCH ADVANCED */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi text-left">
              Inventory Transactions
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          {/* SEARCH COMUNS   */}
          <MDBRow className="md-form w-100  d-flex flex-column flex-md-row justify-content-center align-items-center">
            {/* <div className="d-flex flex-row justify-content-center align-items-center">
              <MDBInput

                className={`col-10  form-control ml-3 flex-1 ${
                  searchCollapseID ? 'text-danger' : ''
                }`}
                disabled={searchCollapseID}

                type="text"
                placeholder={searchCollapseID ? 'Disabled' : 'search'}
                aria-label="Search"

                value={dadosModal.search || ''}
                onChange={(e) => setDadosModal({ search: e.target.value })}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !searchCollapseID) {
                    list(1);
                  }
                }}
              />

              <MDBIcon
                icon="search"
                size="2x"
                className="text-muted cursor-pointer"
                onClick={() => {

                  list(1);

                  setPage('1');
                }}
              />
            </div> */}
            <div
              className={`ml-3 btn btn-sm shadow-5  ${
                searchCollapseID
                  ? 'btn-outline-grey grey-text'
                  : 'bg-vmi text-white'
              }  `}
              size="sm"
              onClick={() => searchCollapse()}
            >
              <span className="m-0 font-weight-bold ">
                {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                Advanced Search
                <MDBIcon className="ml-3" icon="filter" />
              </span>
            </div>
          </MDBRow>

          {/* ADVANCED SEARCH COLLAPSE */}
          <MDBContainer fluid>
            <MDBRow className="mb-3">
              <MDBCollapse
                isOpen={searchCollapseID}
                className="col-12"
                style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
              >
                {/* ADVANCED SEARCH */}
                <MDBRow className="h4-responsive mx-3 font-weight-bold">
                  ADVANCED SEARCH
                </MDBRow>
                <MDBRow>
                  <MDBCol className="col-12 col-md-4">
                    <span>Program</span>
                    <Select
                      className="w-100"
                      options={addProgramList}
                      isClearable={true}
                      value={findSelectedOptionLabel(
                        addProgramList,
                        dadosModal.program
                      )}
                      onChange={(e) => {
                        if (e === null) {
                          const { program, ...restante } = dadosModal;
                          setDadosModal(restante);
                          return;
                        }
                        setDadosModal({ ...dadosModal, program: e.label });
                        // se estiver vazio , retiro o elemento
                        if (e.label == '') {
                          const { program, ...restante } = dadosModal;
                          setDadosModal(restante);
                        }
                      }}
                      // value={addProgram}
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-4">
                    <MDBInput
                      // label="Customer Number"
                      label="Customer Number"
                      group
                      size="lg"
                      value={dadosModal.customer_part_num || ''}
                      onChange={(e) => {
                        setDadosModal({
                          ...dadosModal,
                          customer_part_num: e.target.value,
                        });
                        // se estiver vazio , retiro o elemento
                        if (e.target.value == '') {
                          const { customer_part_num, ...restante } = dadosModal;
                          setDadosModal(restante);
                        }
                      }}
                    />
                  </MDBCol>

                  <MDBCol className="col-12 col-md-4">
                    <MDBInput
                      // label="Customer Number"
                      label="Vendor Number"
                      group
                      size="lg"
                      value={dadosModal.vendor_part_num || ''}
                      onChange={(e) => {
                        setDadosModal({
                          ...dadosModal,
                          vendor_part_num: e.target.value,
                        });
                        // se estiver vazio , retiro o elemento
                        if (e.target.value == '') {
                          const { vendor_part_num, ...restante } = dadosModal;
                          setDadosModal(restante);
                        }
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className="col-12 col-md-4">
                    <span>Transaction</span>
                    <Select
                      className="w-100"
                      options={[
                        { value: 'Sales', label: 'Consumption' },
                        { value: 'GoodsIn', label: 'Receipt' },
                        { value: 'Adjustments', label: 'Adjustments' },
                      ]}
                      value={findSelectedOptionValue(
                        [
                          { value: 'Sales', label: 'Consumption' },
                          { value: 'GoodsIn', label: 'Receipt' },
                          { value: 'Adjustments', label: 'Adjustments' },
                        ],
                        dadosModal.transaction_type
                      )}
                      isClearable={true}
                      onChange={(e) => {
                        if (e === null) {
                          const { transaction_type, ...restante } = dadosModal;
                          setDadosModal(restante);
                          return;
                        }
                        setDadosModal({
                          ...dadosModal,
                          transaction_type: e.value,
                        });
                        // se estiver vazio , retiro o elemento
                        if (e.value == '') {
                          const { transaction_type, ...restante } = dadosModal;
                          setDadosModal(restante);
                        }
                      }}
                      // value={addProgram}
                    />
                  </MDBCol>

                  <MDBCol col="12" md="4">
                    <MDBInput
                      // label="Customer Number"
                      label="Initial date"
                      group
                      size="lg"
                      type="date"
                      value={dadosModal.initial_date || ''}
                      onChange={(e) => {
                        setDadosModal({
                          ...dadosModal,
                          initial_date: e.target.value,
                        });
                        // se estiver vazio , retiro o elemento
                        if (e.target.value == '') {
                          const { initial_date, ...restante } = dadosModal;
                          setDadosModal(restante);
                        }
                      }}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="4">
                    <MDBInput
                      // label="Customer Number"
                      label="Final date"
                      group
                      size="lg"
                      type="date"
                      value={dadosModal.final_date || ''}
                      onChange={(e) => {
                        setDadosModal({
                          ...dadosModal,
                          final_date: e.target.value,
                        });
                        // se estiver vazio , retiro o elemento
                        if (e.target.value == '') {
                          const { final_date, ...restante } = dadosModal;
                          setDadosModal(restante);
                        }
                      }}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  {/* <MDBCol col='12' md='2'>
                <span>Program Cycle Count: </span>
                <Select
                  options={
                    [
                      { value: 'true', label: 'Yes' },
                      { value: 'false', label: 'No' }
                    ]
                  }
                  onChange={c =>{
                    // se estiver vazio , retiro o elemento
                    if(c.value !== '' || c.value !== undefined ){
                      setDadosModal({...dadosModal, program_cycle_count:c.value})
                    }
                  }}
                />
              </MDBCol> */}
                  <MDBCol size="0" md="4"></MDBCol>
                  <MDBCol
                    size="6"
                    md="4"
                    className="d-flex justify-content-center"
                  >
                    <div
                      className="btn btn-sm shadow-5 bg-vmi"
                      size="sm"
                      onClick={() => {
                        // list com a pagina 1 NO BACKEND

                        list(1);
                        // seta o valor da pagina NO FRONTEND
                        setPage('1');
                      }}
                    >
                      <span className="m-0 font-weight-bold text-white">
                        Search
                      </span>
                    </div>
                  </MDBCol>
                  <MDBCol size="6" md="4" className="text-right">
                    <div
                      className={`ml-3 btn btn-sm shadow-5 btn-outline-grey grey-text`}
                      size="sm"
                      onClick={() => setDadosModal({})}
                    >
                      <span className="m-0 font-weight-bold ">
                        {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                        clear filter
                      </span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>
        {/* HEAD FORM TO MD VISUALIZATION */}
        <MDBContainer fluid>
          <MDBRow
            style={{ color: '#3f5c80' }}
            className="d-flex justify-content-between mr-4 pl-3"
          >
            <MDBCol
              col="12"
              md="3"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                placeholder="Select Sort"
                options={[
                  { value: 'date', label: 'Date' },
                  // { value: 'program', label: 'Program' },
                  // { value: 'customer_part_num', label: 'Customer Part No' },
                  // { value: 'vendor_part_num', label: 'Vendor Part No' },
                  { value: 'qoh', label: 'Opening Balance' },
                  { value: 'new_qoh', label: 'Closing Balance' },
                ]}
                value={sort}
                onChange={(t) => {
                  setSort(t);
                }}
              />
            </MDBCol>
            <MDBCol
              col="12"
              md="2"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                options={[
                  { value: 'ASC', label: 'Ascending' },
                  { value: 'DESC', label: 'Descending' },
                ]}
                placeholder="Select Type Sort"
                value={typeSort}
                onChange={(t) => {
                  setTypeSort(t);
                }}
              />
            </MDBCol>
            <MDBCol size="2" />
            <MDBCol
              size="4"
              className="d-flex justify-content-end align-items-center"
            >
              <Select
                className="w-50"
                // isClearable={true}

                placeholder="Select Download"
                options={[
                  { value: 0, label: 'Export Current Page' },
                  { value: 1, label: 'Export All' },
                ]}
                onChange={async (c) => {
                  if (c.value == 0) {
                    setLoading(true);
                    setResponseDownload([]);
                    const listing = await api.get(`/transaction`, {
                      params: {
                        pag: page,
                        p: quantity,
                        ...dadosModal,
                        // sort,
                        // typeSort,
                      },
                    });
                    const dataDownload = [];
                    listing.data.rows.forEach((t) => {
                      dataDownload.push({
                        Date: format(new Date(t.date), 'dd/MM/yyyy'),
                        Program: t.TB_customer_listing.TB_customer.program,
                        customer_part_num:
                          t.TB_customer_listing !== null
                            ? `${t.TB_customer_listing.customer_part_num}`
                            : 'Null',
                        vendor_part_num: t.TB_customer_listing.TB_product
                          .vendor_part_num
                          ? `${t.TB_customer_listing.TB_product.vendor_part_num}`
                          : 'Null',
                        Opening_balance: t.qoh.toFixed(2),
                        closing_balance: t.new_qoh.toFixed(2),
                        Transaction: t.transaction_qty.toFixed(2),
                        Type:
                          t.transaction_type === 'GoodsIn'
                            ? 'Receipt'
                            : t.transaction_type === 'Sales'
                            ? 'Consumption'
                            : t.transaction_type,
                      });
                    });
                    setResponseDownload(dataDownload);
                    setLoading(false);
                  }
                  if (c.value == 1) {
                    setLoading(true);
                    setResponseDownload([]);
                    const listing = await api.get(`/transaction`, {
                      params: paramsDownload,
                    });

                    const dataDownload = [];
                    listing.data.rows.forEach((t) => {
                      dataDownload.push({
                        Date: format(new Date(t.date), 'dd/MM/yyyy'),
                        Program: t.TB_customer_listing.TB_customer.program,
                        customer_part_num:
                          t.TB_customer_listing !== null
                            ? `${t.TB_customer_listing.customer_part_num}`
                            : 'Null',
                        vendor_part_num: t.TB_customer_listing.TB_product
                          .vendor_part_num
                          ? `${t.TB_customer_listing.TB_product.vendor_part_num}`
                          : 'Null',
                        Opening_balance: t.qoh.toFixed(2),
                        closing_balance: t.new_qoh.toFixed(2),
                        Transaction: t.transaction_qty.toFixed(2),
                        Type:
                          t.transaction_type === 'GoodsIn'
                            ? 'Receipt'
                            : t.transaction_type === 'Sales'
                            ? 'Consumption'
                            : t.transaction_type,
                      });
                    });
                    setResponseDownload(dataDownload);
                    setLoading(false);
                  }
                }}
              />

              {responseDownload.length !== 0 ? (
                <CsvDownloader
                  style={{ cursor: 'pointer' }}
                  className="ml-2"
                  filename={'inventoryTransactions.csv'}
                  separator=","
                  wrapColumnChar=""
                  noHeader="false"
                  // columns={collumns}
                  datas={responseDownload}
                >
                  <span
                    style={{ color: '#0e4e9e' }}
                    className="mr-2 font-weight-bold"
                  >
                    Download
                  </span>

                  <MDBIcon
                    style={{ color: '#0e4e9e' }}
                    icon="download"
                    size="2x"
                  />
                </CsvDownloader>
              ) : (
                ''
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 ml-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-1 d-flex justify-content-between ">
              <span className="d-sm-inline font-weight-bold mr-3">Date:</span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Program:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Customer Part No:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Vendor Part No:
              </span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Opening Balance:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Closing Balance:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Transaction:
              </span>
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Type:</span>
            </div>
          </MDBRow>
        </MDBContainer>
        {/* LIST TABLE */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : (
            listResult.map((t) => (
              <div key={t.id}>
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className="mr-1 ml-1 mr-md-0 ml-md-0 py-2"
                  >
                    <div className="col-12 col-md-1 d-flex justify-content-between ">
                      <span className="d-sm-inline d-md-none mr-3 d-flex align-items-end">
                        Date:
                      </span>

                      <span>{format(new Date(t.date), 'dd/MM/yyyy')}</span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Program:
                      </span>
                      <span>{t.TB_customer_listing.TB_customer.program}</span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Customer Part No:
                      </span>
                      <span>
                        {t.TB_customer_listing !== null
                          ? `${t.TB_customer_listing.customer_part_num}`
                          : 'Null'}
                      </span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Vendor Part No:
                      </span>
                      <span>
                        {t.TB_customer_listing.TB_product.vendor_part_num
                          ? `${t.TB_customer_listing.TB_product.vendor_part_num}`
                          : 'Null'}
                      </span>
                    </div>

                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Opening Balance:
                      </span>
                      {/* {Number.isInteger(t.qoh)} */}
                      {/* {console.log(Number.isInteger(t.qoh))} */}
                      {/* parseFloat(conta.toFixed(2)); */}
                      <span>{`${t.qoh.toFixed(2)}`}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Closing Balance:
                      </span>
                      <span>{`${t.new_qoh.toFixed(2)}`}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Transaction:
                      </span>
                      <span>{`${t.transaction_qty.toFixed(2)}`}</span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">Type:</span>
                      <span>
                        {t.transaction_type === 'GoodsIn'
                          ? 'Receipt'
                          : t.transaction_type === 'Sales'
                          ? 'Consumption'
                          : t.transaction_type}
                      </span>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          )}

          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
        <br />
        <br />
      </NavBar>
    </>
  );
}
