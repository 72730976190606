import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';


import { format } from 'date-fns';
import Select from 'react-select';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBSpinner,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
  MDBTooltip,
} from 'mdbreact';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import Modal from '../../../components/Modal';
import { getData, getProfile } from '../../../services/auth';
import { Line, Checkbox } from './styles';

export default function OrderHistory(props) {
  const programs = getData().profile.programs;
  const [searchCollapseID, setSearchCollapseID] = useState(false);
  const [searchCollapseID2, setSearchCollapseID2] = useState(false);

  const [listResult, setListResult] = useState([]);

  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);

  const [dadosModal, setDadosModal] = useState({});
  const [createOrder, setCreateOrder] = useState({});

  const [addProgramList, setAddProgramList] = useState([]);

  const [editDadosModal, setEditDadosModal] = useState({});

  const [viewDate, setViewDate] = useState(' ');
  const [viewOrder, setViewOrder] = useState(' ');
  const [viewBranch, setViewBranch] = useState(' ');
  const [viewProgram, setViewProgram] = useState(' ');

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectGroup, setSelectGroup] = useState([]);

  const [responseDownload, setResponseDownload] = useState([]);

  const [selectOrders, setSelectOrders] = useState([]);


  const [programAdSearch,setProgramAdSearch] = useState([])
  const [customerPartNumAdSearch,setCustomerPartNumAdSearch] = useState(null)
  const [vendorPartNumAdSearch,setVendorPartNumAdSearch] = useState(null)
  const [productCategoryAdSearch,setProductCategoryAdSearch] = useState([])
  const [productGroupAdSearch,setProductGroupAdSearch] = useState([])
  const [descriptionAdSearch,setDescriptionAdSearch] = useState(null)
  const [initialDateAdSearch,setinitialDateAdSearch] = useState(null)
  const [finalDateAdSearch,setFinalDateAdSearch] = useState(null)


  const [productGroupAdSearchList,setProductGroupAdSearchList] = useState([])
  const [productCategoryAdSearchList,setProductCategoryAdSearchList] = useState([])

  const searchCollapse = async () => {
    // alter boolean collapse
    setSearchCollapseID(!searchCollapseID);
    setSearchCollapseID2(false);
    // verify search is true to make request
    if (searchCollapseID) {
      return;
    }
  };
  const searchCollapse2 = async () => {
    // alter boolean collapse
    setSearchCollapseID2(!searchCollapseID2);
    setSearchCollapseID(false);
    // verify search is true to make request
    if (searchCollapseID2) {
      return;
    }
  };

  useEffect(() => {
    // list();
    carregaListas();
    loadSelectOrders();
  }, []);

  const loadSelectOrders = () => {
    const select = [{label:'All',value:null}];
    api.get('/select-orders').then((t) => {
      t.data.forEach((element) => {
        select.push({
          label: element,
          value: element,
        });
      });
    });

    setSelectOrders(select);
  };
  // useEffect(()=>{
  //   if(dadosModal.group == ''){
  //     const { group , ...restante} = dadosModal
  //     setDadosModal(restante)

  //   }

  // },[dadosModal])
  const carregaListas = async () => {
    const selectProgram = [];
    programs.forEach((pro) => {
      selectProgram.push({ label: pro.program, value: pro.id });
    });

    setAddProgramList(selectProgram);
    
    // load CUSTOMER COLUMN PROGRAM in select
    // const responseProgram = await api.get(`/customer`, {
    //   params: { p: '99999999' },
    // });
    
    // const listagemProgram = [];
    // responseProgram.data.rows.map((item) => {
    //   listagemProgram.push({ value: item.id, label: item.program });
    // });
    // setAddProgramList(listagemProgram);

    // const responseCategory = await api.get(`/product-category?p=99999999`);
    // const listagemCategory = [];
    // responseCategory.data.rows.map((item) => {
    //   listagemCategory.push({ value: item.id, label: item.category });
    // });
    // setSelectCategory(listagemCategory);

    // const responseGroup = await api.get(`/product-group?p=99999999`);
    // const listagemGroup = [];
    // responseGroup.data.rows.map((item) => {
    //   listagemGroup.push({ value: item.id, label: item.group });
    // });
    // setSelectGroup(listagemGroup);
  };
  const list = async (npage = page) => {
    setLoading(true);
    const dados = {}
    if(searchCollapseID){
     

      if (programAdSearch.length > 0) {
        dados.program = programAdSearch.map(item => item.value);
      }

      if (customerPartNumAdSearch !== null) {
        dados.customer_part_num = customerPartNumAdSearch;
      }

      if (vendorPartNumAdSearch !== null) {
        dados.vendor_part_num = vendorPartNumAdSearch;
      }

      if (productCategoryAdSearch || productCategoryAdSearch.length > 0) {
        dados.product_category = productCategoryAdSearch.map(item => item.value);
      }

      if (productGroupAdSearch && productGroupAdSearch.length > 0) {
        dados.product_group = productGroupAdSearch.map(item => item.value);
      }
      

     
      if (descriptionAdSearch && descriptionAdSearch !== null) {
        dados.description = descriptionAdSearch;
      }

      if (initialDateAdSearch && initialDateAdSearch.length !== null) {
        dados.initial_date = initialDateAdSearch;
      }
      if (finalDateAdSearch && finalDateAdSearch.length !== null) {
        dados.final_date = finalDateAdSearch;
      }
    

      
    }else{
       if(search !== null){
      dados.search = search
      }
    }
   
    const listing = await api.get(`/orders`, {
      params: {
        pag: npage,
        p: quantity,
        order: search,
        ...dados,
        // order: search,
      },
    });

    const downloadOrder = [];
   
    listing.data.rows && listing.data.rows.forEach((lineOrder) => {
      downloadOrder.push({
        program: lineOrder.TB_customer_listing.TB_customer.program,
        date: format(new Date(lineOrder.date), 'dd/MM/yyyy'),
        order: lineOrder.order,
        category:
          lineOrder.TB_customer_listing.TB_product.TB_product_group
            .TB_product_category.category,
        manufacturer:
          lineOrder.TB_customer_listing.TB_product.TB_product_manufacturer
            .manufacturer,
        customer_part_num: lineOrder.TB_customer_listing.customer_part_num,
        vendor_part_num:
          lineOrder.TB_customer_listing.TB_product.vendor_part_num,
        unit_price: lineOrder.TB_customer_listing.unit_price,
        order_qty: lineOrder.order_qty,
      });
    });
    setResponseDownload(downloadOrder);
    setLoading(false);
    setListResult(listing.data.rows);
    setRows(listing.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

    setEditDadosModal({});

    if (listing.data.count > 0) {
      setViewDate(format(new Date(listing.data.rows[0].date), 'dd/MM/yyyy'));
      setViewOrder(listing.data.rows[0].order);
      setViewProgram(
        listing.data.rows[0].TB_customer_listing.TB_customer.program
      );
    } else {
      setViewDate('No date');
      setViewOrder('No Order');
    }
  };

  const selectOrder = async (npage = page, select) => {
    setLoading(true);
    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
    if (search !== '') {
      setDadosModal({});
    }

    const listing = await api.get(`/orders`, {
      params: {
        pag: npage,
        p: quantity,
        order: select,
        // ...dadosModal,
        // order: search,
      },
    });
    const downloadOrder = [];
    listing.data.rows.forEach((lineOrder) => {
      downloadOrder.push({
        program: lineOrder.TB_customer_listing.TB_customer.program,
        date: format(new Date(lineOrder.date), 'dd/MM/yyyy'),
        order: lineOrder.order,
        category:
          lineOrder.TB_customer_listing.TB_product.TB_product_group
            .TB_product_category.category,
        manufacturer:
          lineOrder.TB_customer_listing.TB_product.TB_product_manufacturer
            .manufacturer,
        customer_part_num: lineOrder.TB_customer_listing.customer_part_num,
        vendor_part_num:
          lineOrder.TB_customer_listing.TB_product.vendor_part_num,
        unit_price: lineOrder.TB_customer_listing.unit_price,
        order_qty: lineOrder.order_qty,
      });
    });
    setResponseDownload(downloadOrder);

    setListResult(listing.data.rows);
    setRows(listing.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
    setLoading(false);
    setEditDadosModal({});

    if (listing.data.count > 0) {
      setViewDate(format(new Date(listing.data.rows[0].date), 'dd/MM/yyyy'));
      setViewOrder(listing.data.rows[0].order);
      setViewProgram(
        listing.data.rows[0].TB_customer_listing.TB_customer.program
      );
    } else {
      setViewDate('No date');
      setViewOrder('No Order');
    }
  };

  // const simpleSearch = async (npage = page) => {
  //   setLoading(true);
  //   // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
  //   const response = await api.get(`/orders`, {
  //     params: {
  //       pag: npage,
  //       p: quantity,
  //       order: search,
  //     },
  //   });
  //   setListResult(response.data.rows);

  //   setViewOrder(response.data.rows[0].order);

  //   setViewDate(format(new Date(response.data.rows[0].date), 'dd/MM/yyyy'));
  //   setViewBranch(
  //     response.data.rows[0].TB_customer_listing.TB_customer.branch_name
  //   );
  //   setRows(response.data.count);
  //   setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

  //   setLoading(false);
  // };
  const findSelectedOptionValue = (options, value) => {
    if (!options || !value) return null;
    return options.find((option) => option.value === value) || null;
  };

  const findSelectedOptionLabel = (options, label) => {
    if (!options || !label) return null;
    return options.find((option) => option.label === label) || null;
  };

  const newOrder = async (npage = page) => {
    if (!createOrder.program) {
      toast.error('The field Program is required');
      return;
    }

    const order = await api.post(`/orders`, {
      // ...createOrder,
      // branch_name: dadosModal.branch_name,
      program: createOrder.program,
      // customer_account_number: dadosModal.customer_account_number,
      // user_id: dadosModal.user_id,
      // customer_group_id: dadosModal.customer_group_id,
      // replan_days:dadosModal.replan_days,
    });

    setLoading(true);
    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`

    const listing = await api.get(`/orders`, {
      params: {
        pag: npage,
        p: quantity,
        order: order.data[0].order_number,
      },
    });

    setListResult(listing.data.rows);
    setRows(listing.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
    setLoading(false);
    setEditDadosModal({});

    setViewOrder(listing.data.rows[0].order);

    setViewDate(format(new Date(listing.data.rows[0].date), 'dd/MM/yyyy'));
    setViewBranch(
      listing.data.rows[0].TB_customer_listing.TB_customer.branch_name
    );
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    setPage(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    setPage(nextP);
  };


  const preencheSelects = async () => {
    // if (!Array.isArray(programSelecionado) || programSelecionado.length === 0) return;
   
    const program =
    {
      colname: 'program',
      colid: 'program_id',
    }
    if (programAdSearch.length > 0){
      const programs = []
      programAdSearch.map((e) => programs.push(e.value))
      program.id = programs
    } 
  
    const category = {
      colname: 'category',
      colid: 'category_id',
    }
    if (productCategoryAdSearch && productCategoryAdSearch.length > 0){
      const categorys = []
      productCategoryAdSearch.map((e) => categorys.push(e.value))
      category.id = categorys
    } 

    const group = {
      colname: 'group',
      colid: 'group_id',
    }
    if (productGroupAdSearch && productGroupAdSearch.length > 0){
      const groups = []
      productGroupAdSearch.map((e) => groups.push(e.value))
      group.id = groups
    } 

    if(program.id && group.id && category.id ) return

    try {
      const response = await api.post('/search-filters',
        [program,group,category]
      );
      if(!group.id || group.id.length === 0 || group.id.length === undefined){
        setProductGroupAdSearchList(response.data.group)
      }
      if(!category.id || category.id.length === 0 || category.id.length === undefined){
        setProductCategoryAdSearchList(response.data.category)
      }
   
      
      
      // setSelectCategory(response.data.category)
      // setSelectGroup(response.data.group)
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(()=>{
    preencheSelects()
  },[programAdSearch,productGroupAdSearch,productCategoryAdSearch])

  useEffect(() => {
    list(page);
  }, [page, quantity]);

  return (
    <>
      <NavBar>
        {/* SEARCH E SEARCH ADVANCED */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi text-left">
              Order History
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          {/* SEARCH COMUNS   */}
          <MDBRow className="md-form w-100  d-flex flex-column flex-md-row justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <MDBInput
                //  width='200px'

                className={`col-10  form-control ml-3 flex-1 ${
                  searchCollapseID ? 'text-danger' : ''
                }`}
                disabled={searchCollapseID}
                type="text"
                placeholder={searchCollapseID ? 'Disabled' : 'Order'}
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !searchCollapseID) {
                    list(1);
                  }
                }}
              />
              <MDBIcon
                icon="search"
                size="2x"
                className="text-muted cursor-pointer"
                onClick={() => {
                  // list com a pagina 1 NO BACKEND
                  // simpleSearch(1);
                  list(1);
                  // seta o valor da pagina NO FRONTEND
                  setPage('1');
                }}
              />
            </div>

            <div
              className={`ml-3 btn btn-sm shadow-5  ${
                searchCollapseID
                  ? 'btn-outline-grey grey-text'
                  : 'bg-vmi text-white'
              }  `}
              size="sm"
              onClick={() => searchCollapse()}
            >
              <span className="m-0 font-weight-bold ">
                {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                Advanced Search
                <MDBIcon className="ml-3" icon="filter" />
              </span>
            </div>
            {/* {getProfile('Super Admin', 'Admin', 'Manager', 'Rep') && (
              <div
                className={`ml-3 btn btn-sm shadow-5  ${
                  searchCollapseID2
                    ? 'btn-outline-grey grey-text'
                    : 'bg-vmi text-white'
                }  `}
                size="sm"
                onClick={() => searchCollapse2()}
              >
                <span className="m-0 font-weight-bold ">

                  New Order
                </span>
              </div>
            )} */}

            <MDBCol size="2">
              <Select
                placeholder="Last Orders"
                options={selectOrders}
                
                onChange={(e) => {
                  // setSearch(e.value);
                  selectOrder('1', e.value);
                }}
              />
            </MDBCol>
          </MDBRow>

          {/* ADVANCED SEARCH COLLAPSE */}
          <MDBRow className="mb-3">
            <MDBCollapse
              isOpen={searchCollapseID}
              className="col-12"
              style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            >
              {/* ADVANCED SEARCH */}
              <MDBRow className="h3-responsive mx-3">ADVANCED SEARCH</MDBRow>
              <MDBRow>
                <MDBCol className="col-12 col-md-4">
                  <span>Program</span>
                  <Select
                  isMulti
                    className="w-100"
                    options={addProgramList}
                    isClearable={true}
                    value={programAdSearch}
                    onChange={(e)=>setProgramAdSearch(e || [])}
                    // value={addProgram}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    // label="Customer Number"
                    label="Customer Part No"
                    icon="clipboard"
                    group
                    size="lg"
                    value={customerPartNumAdSearch}
                    onChange={(e) => setCustomerPartNumAdSearch(e.target.value)}
                  />
                </MDBCol>

                <MDBCol className="col-12 col-md-4">
                  <MDBInput
                    // label="Customer Number"
                    label="Vendor Part No"
                    icon="clipboard"
                    group
                    size="lg"
                    value={vendorPartNumAdSearch}
                    onChange={(e) => setVendorPartNumAdSearch(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-12 col-md-3">
                  <span>Product Category</span>
                  <Select
                  isMulti
                    className="w-100"
                    options={productCategoryAdSearchList}
                    isClearable={true}
                    value={productCategoryAdSearch}
                    onChange={(e) => setProductCategoryAdSearch(e || [])}
                  />
                </MDBCol>

                <MDBCol className="col-12 col-md-3">
                  <span>Product Group</span>
                  <Select
                  isMulti
                    className="w-100"
                    options={productGroupAdSearchList}
                    isClearable={true}
                    value={productGroupAdSearch}
                    onChange={(e) => setProductGroupAdSearch(e || [])}
                  />
                </MDBCol>

                <MDBCol col="12" md="3">
                  <MDBInput
                    // label="Customer Number"
                    label="Initial date"
                    group
                    size="lg"
                    type="date"
                    value={initialDateAdSearch}
                    onChange={(e) => setinitialDateAdSearch(e.target.value)}
                  />
                </MDBCol>
                <MDBCol col="12" md="3">
                  <MDBInput
                    // label="Customer Number"
                    label="Final date"
                    group
                    size="lg"
                    type="date"
                    value={finalDateAdSearch}
                    onChange={(e) => setFinalDateAdSearch(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="0" md="4"></MDBCol>
                <MDBCol
                  size="6"
                  md="4"
                  className="d-flex justify-content-center"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => {
                      // list com a pagina 1 NO BACKEND
                      list(1);
                      // seta o valor da pagina NO FRONTEND
                      setPage('1');
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      Search
                    </span>
                  </div>
                </MDBCol>
                <MDBCol size="6" md="4" className="text-right">
                  <div
                    className={`ml-3 btn btn-sm shadow-5 btn-outline-grey grey-text`}
                    size="sm"
                    onClick={() => setDadosModal({})}
                  >
                    <span className="m-0 font-weight-bold ">
                      {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                      clear filter
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBRow>

          {/* CREATE NEW ORDER */}
          <MDBRow className="mb-3">
            <MDBCollapse
              isOpen={searchCollapseID2}
              className="col-12"
              style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            >
              {/* ADVANCED SEARCH */}
              <MDBRow className="h3-responsive mx-3">CREATE NEW ORDER</MDBRow>
              <MDBRow>
                <MDBCol className="col-12 col-md-4">
                  <span>Program</span>
                  <Select
                    className="w-100"
                    options={addProgramList}
                    isClearable={true}
                    onChange={(e) => {
                      if (e === null) {
                        const { program, ...restante } = createOrder;
                        setCreateOrder(restante);
                        return;
                      }
                      setCreateOrder({ ...createOrder, program: e.label });
                      // se estiver vazio , retiro o elemento
                      if (e.label == '') {
                        const { program, ...restante } = createOrder;
                        setCreateOrder(restante);
                      }
                    }}
                    // value={addProgram}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <span>Product Category</span>
                  <Select
                    className="w-100"
                    options={selectCategory}
                    isClearable={true}
                    onChange={(e) => {
                      if (e === null) {
                        const { category, ...restante } = dadosModal;
                        setDadosModal(restante);
                        return;
                      }
                      setDadosModal({ ...dadosModal, category: e.label });
                      // se estiver vazio , retiro o elemento
                      if (e.value == '') {
                        const { category, ...restante } = dadosModal;
                        setDadosModal(restante);
                      }
                    }}
                  />
                </MDBCol>

                <MDBCol className="col-12 col-md-4">
                  <span>Product Group</span>
                  <Select
                    className="w-100"
                    options={selectGroup}
                    isClearable={true}
                    onChange={(e) => {
                      if (e === null) {
                        const { group, ...restante } = dadosModal;
                        setDadosModal(restante);
                        return;
                      }
                      setDadosModal({ ...dadosModal, group: e.label });
                      // se estiver vazio , retiro o elemento
                      if (e.value == '') {
                        const { group, ...restante } = dadosModal;
                        setDadosModal(restante);
                      }
                    }}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol className="col-12 col-md-12 d-flex justify-content-center">
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => {
                      newOrder();
                      // list com a pagina 1 NO BACKEND

                      // seta o valor da pagina NO FRONTEND
                      setPage('1');
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      Run Order
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBRow>
        </MDBContainer>

        {/* HEAD FORM TO MD VISUALIZATION */}
        <MDBContainer fluid>
          <MDBRow
            style={{ color: '#3f5c80', borderRadius: '5px' }}
            className="d-flex justify-content-between mb-2 bg-white"
          >
            <MDBCol
              size="2"
              className="h5-responsive text-gray font-weight-bolder"
            >
              Date: {viewDate}
            </MDBCol>
            <MDBCol
              size="3"
              className="h5-responsive text-gray font-weight-bolder text-center"
            >
              Program: {viewProgram}
            </MDBCol>
            <MDBCol
              size="3"
              className="h5-responsive text-gray font-weight-bolder text-center"
            >
              {/* Order: {viewOrder} */}
            </MDBCol>

            <MDBCol
              size="4"
              className="d-flex justify-content-end align-items-center"
            >
              {/* <Select
                className="w-50"
                // isClearable={true}

                placeholder="Select Download"
                options={[
                  { value: 0, label: 'Export Current Page' },
                  { value: 1, label: 'Export All' },
                ]}
                onChange={async (c) => {
                  if (c.value == 0) {
                    setLoading(true);
                    setResponseDownload([]);
                    const listing = await api.get(`/inventory-supplier`, {
                      params: {
                        pag: page,
                        p: quantity,
                        ...dadosModal,
                      },
                    });

                    setResponseDownload(listing.data.rows);
                    setLoading(false);
                  }
                  if (c.value == 1) {
                    setLoading(true);
                    setResponseDownload([]);
                    const response = await api.get(`/inventory-supplier`, {
                      // params: paramsDownload,
                    });

                    setResponseDownload(response.data.rows);
                    setLoading(false);
                  }
                }}
              /> */}
              {/* {responseDownload.length !== 0 ? ( */}
              <CSVLink
                className="ml-2"
                data={responseDownload}
                // separator={'\t'}
                separator={','}
                filename={'orderHistory.csv'}
              
              >
                <span
                  style={{ color: '#0e4e9e' }}
                  className="mr-2 font-weight-bold"
                >
                  Download
                </span>

                <MDBIcon
                  style={{ color: '#0e4e9e' }}
                  icon="download"
                  size="2x"
                />
              </CSVLink>
              {/* ) : (
                ''
              )}*/}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer fluid>
          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Category:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Manufacturer:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Customer Part No:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Vendor Part No:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Unit Price:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Order qty:
              </span>
            </div>
          </MDBRow>
        </MDBContainer>

        {/* LIST TABLE */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : !listResult ? (
            'No Data'
          ) : (
            listResult.map((t) => (
              <div key={t.id}>
            
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line key={t.id}>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className=" mr-md-0 ml-md-0 p-2"
                  >
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none">Category:</span>
                      <span>
                        Order:{t.order} <br/>
                        Program:{t.TB_customer_listing.TB_customer.program} <br/>
                        Date:{format(new Date(t.date), 'dd/MM/yyyy')} <br/>
                      </span>
                    </div>
                  

      
                    {/* <div className="col-12 col-md-2 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none">Date:</span>
                        <span>{format(new Date(t.date), 'dd/MM/yyyy')}</span>
                      </div> */}
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none">Category:</span>
                      <span>
                        {t.TB_customer_listing.TB_product.TB_product_group
                          .TB_product_category.category
                          ? t.TB_customer_listing.TB_product.TB_product_group
                              .TB_product_category.category
                          : ''}
                      </span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none">
                        Manufacturer:
                      </span>
                      <span>
                        {t.TB_customer_listing.TB_product
                          .TB_product_manufacturer.manufacturer
                          ? t.TB_customer_listing.TB_product
                              .TB_product_manufacturer.manufacturer
                          : ''}
                      </span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Customer Part No:
                      </span>
                      <span>{t.TB_customer_listing.customer_part_num}</span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Vendor Part No:
                      </span>
                      <span>
                        {t.TB_customer_listing.TB_product.vendor_part_num}
                      </span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Unit Price:
                      </span>
                      <span>{t.TB_customer_listing.unit_price}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Order qty:
                      </span>
                      <span>{t.order_qty}</span>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          )}
        </MDBContainer>

        {/* BUTTON NEXT / BACK OF PAGES */}
        <MDBContainer>
          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
      </NavBar>
    </>
  );
}
