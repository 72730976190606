import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
// import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
// import upperCase from 'upper-case';
import Logo from '../../assets/logo.jpg';
import api from '../../services/api';
import { login } from '../../services/auth';

import { Form, Container } from './styles';

const SignIn = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error('Fill in email and password');
    } else {
      try {
        const response = await api.post('/session', { email, password });
        if (response.data.error) {
          toast.error('Access denied!');
          return;
        }
        const dados = {
          // nome: upperCase(response.data.nome),
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          phone: response.data.phone,
          email: response.data.email,
          // email: response.data.email,
          // foto: response.data.arquivo,
        };

        login(response.data.token, dados);

        history.push('/app');
      } catch (error) {
        toast.error('Access denied!');
        return;
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSignIn}>
        <img style={{ borderRadius: '10px' }} src={Logo} alt="logik vmi" />

        {/* <InputMask
          type="text"
          placeholder="CPF"
          onChange={e => this.setState({ cpf: e.target.value })}
          mask="999.999.999-99"
          maskChar=" "
        /> */}

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">SIGN IN</button>
        <hr />
        {/* <Link to="/signup">Criar conta</Link> */}
      </Form>
    </Container>
  );
};

export default withRouter(SignIn);
