import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import api from '../../services/api';

// import { Container } from './styles';

export default function Modal(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const handlerSubmit = async (e) => {
    e.preventDefault();

    const res = await api.put(props.api, props.dados);
    try {
      if (res.data.erro) return toast.error(res.data.erro);

      setModal(false);
      await props.restart();
      toast.success('Successfully Changed');
    } catch (error) {
      toast.error(res.data.erro);
    }
  };

  return (
    <MDBContainer className="text-right">
      <Link
        to="#"
        className="text-black-50 d-flex justify-content-end align-items-center font-weight-bold"
        onClick={toggle}
      >
        <MDBIcon className="mr-1 ml-2" far icon="edit" size="1x" />
        Edit
      </Link>
      <MDBModal
        className="text-left"
        size={props.size ? props.size : 'md'}
        isOpen={modal}
        toggle={toggle}
      >
        <MDBModalHeader toggle={toggle}>{props.title}</MDBModalHeader>
        <MDBModalBody>{props.children}</MDBModalBody>
        <MDBModalFooter>
        <div
                className="btn btn-sm shadow-5 bg-danger d-none d-md-inline"
                size="sm"
                onClick={toggle}
              >
                <span className="m-0 font-weight-bold text-white">
                 Close
                </span>
              </div>
              {props.disabled === false && 
              <div
              className="btn btn-sm shadow-5 bg-vmi  d-none d-md-inline"
              size="sm"
              onClick={handlerSubmit}
            >
              <span className="m-0 font-weight-bold text-white">
              Save changes
              </span>
            </div>
              }
              
         
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
}
