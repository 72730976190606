import styled from "styled-components";

export const Line = styled.div`
background-color:rgba(250,250,250,0.6);

&:hover{
  background-color: rgba(51,181,229,0.1);

}
`;
