import React, { Component } from 'react';
import { MDBToastContainer } from 'mdbreact';
import Routes from './routes';
import GlobalStyle from './styles/global';

import './index.css';

class App extends Component {
  render() {
    return (
      <>
        <Routes />

        <GlobalStyle />
        <MDBToastContainer />
      </>
    );
  }
}

export default App;
