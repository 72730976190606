import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';

import neural from '../../assets/neural.png';

import { getData } from '../../services/auth'
// import customerproducts from '../../assets/customerproducts.jpg';

import ModalButton from '../../components/ModalButton';
import Card from '../../components/card';

// import Api from '../../services/api';



// import { Container } from './styles';

export default function App() {
  const [card, setCard] = useState(null);
  useEffect(() => {
    const fetchedCard = getData() ? getData().card : null;
    setCard(fetchedCard);
  }, []);
  return (
    <>
      <NavBar>
        <MDBContainer>
          {/* espacamento  */}
          {/* <ModalButton title="outro modal"> teste corpo de outro modal </ModalButton> */}
          <MDBRow className="d-flex mt-0 justify-content-center mx-0 mx-md-4">
            {!card ? <p>Carregando cards...</p>
              :

              card.map(card => (
                <MDBCol key={card.id} col="12" md="4" className="p-0 p-md-2 my-3">
                  <ModalButton
                    titleName={card.name}
                    title={card.name}
                    descriptionCard={card.description}
                  >
                    <MDBRow>
                      {card.funcao.map(func => (
                          func.card === false ? '':
                        <MDBCol key={func.id} className="col-12 col-md-6 p-2">
                          {/* old customer group */}
                          <Link to={`${func.url}`}>
                            <Card titleName={func.name}>
                            {func.description}
                            </Card>
                          </Link>
                        </MDBCol>
                      ))}

                     
                    </MDBRow>
                  </ModalButton>
                </MDBCol>
              ))

            }
        
          </MDBRow>
         
          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}
