import { createGlobalStyle } from 'styled-components';

// import 'font-awesome/css/font-awesome.css';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}


body, html {
  /* background-image: linear-gradient(to right, white , silver); */
  /* background-image: radial-gradient(white 5%, silver 15%, silver 60%); */
  background-image: radial-gradient(circle, white 40%, #01346B 300%);
  font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  font-size:.9rem;


}

.text-vmi{
  color:#00346A ; 
}
.bg-vmi{
   background-color:#00346A ; 
}




`;

export default GlobalStyle;
