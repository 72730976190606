import React, { Component } from 'react';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import Card from '../card';

class ModalPage extends Component {
  state = {
    modal: false,
  };

  toggle = () => () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <MDBContainer className="h-100 p-0 m-0" style={{ minHeight: '100px' }}>
        <div className="h-100" onClick={this.toggle()}>
          <Card
            img={this.props.img}
            titleName={this.props.titleName}
            children={this.props.descriptionCard}
          />
        </div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle()} size="lg">
          <MDBModalHeader>{this.props.title}</MDBModalHeader>
          <MDBModalBody>{this.props.children}</MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalPage;
